import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import TabView from "../components/TabView";
import AdvertisementTable from "./component/AdvertisementTable";
import AdvertisementForm from "./component/AdvertisementForm";

export default function AdvertisementListPage() {
  const [newData, setNewData] = useState({
    tabViewData: ["列表", "新增"],
    select: 0,
    dataObject: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  return (
    <Grid>
      <TabView
        paper
        tabs={newData.tabViewData}
        index={newData.select}
        onChange={(e) => {
          if (newData.select === 2 && e !== 2) {
            changeData("dataObject", null);
            newData.tabViewData.splice(2, 1);
            changeData("tabViewData", newData.tabViewData);
            changeData("select", e, true);
          } else {
            changeData("select", e, true);
          }
        }}
      >
        <AdvertisementTable
          onEditSelect={(value) => {
            changeData("dataObject", value);
            newData.tabViewData.push(`廣告:\u2002${value.filename}\u2002資料`);
            changeData("tabViewData", newData.tabViewData);
            changeData("select", 2, true);
          }}
        />
        <AdvertisementForm
          onChangeSelect={() => {
            changeData("dataObject", null);
            changeData("tabViewData", ["列表", "新增"]);
            changeData("select", 0, true);
          }}
        />
        <AdvertisementForm
          id={newData.dataObject && newData.dataObject.id}
          onChangeSelect={() => {
            changeData("dataObject", null);
            changeData("tabViewData", ["列表", "新增"]);
            changeData("select", 0, true);
          }}
        />
      </TabView>
    </Grid>
  );
}

import React, { useState, createElement } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  useMediaQuery,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ProductTitle from "../components/ProductTitle";
import { useHistory } from "react-router-dom";

export default function ChangePasswordPage() {
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    textstyle: {
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },
    SendBtn: {
      padding: "5px 0px !important",
    },
    joinOrReturnBox: {
      textAlign: "center",
      marginTop: isDownTablet ? (isPhone ? "-12em" : "-15em") : "1em",
      marginBottom: isDownTablet ? (isPhone ? "-12em" : "-15em") : "1em",
      fontWeight: "bold",
      transition: "all 1s",
    },
    joinOrReturnBtn: {
      display: "inline-block",
      transition: "all 0.3s",
      cursor: "pointer",
      "&:hover": {
        color: "gray",
      },
    },
    ErrorMessage: {
      color: "red",
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
  });
  const classes = useStyles();

  const [disabledRegister, setDisabledRegister] = useState(false);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [passwordError, setPasswordError] = useState(true);
  const [changePasswordError, setChangePasswordError] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const history = useHistory();

  function _change() {
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;
    const passwordErrorMsg = document.getElementById("passwordErrorMsg");
    const changePasswordErrorMsg = document.getElementById(
      "changePasswordErrorMsg"
    );

    if (!Boolean(password)) {
      setDisabledRegister(false);
      setPasswordError(false);
      passwordErrorMsg.innerHTML = "密碼未填寫";
    } else if (!passwordRegex.test(password)) {
      setDisabledRegister(false);
      setPasswordError(false);
      passwordErrorMsg.innerHTML = "密碼格式錯誤，需八碼且包含英文以及數字！";
    } else {
      setPasswordError(true);
      passwordErrorMsg.innerHTML = "";
    }

    if (!Boolean(changePassword)) {
      setDisabledRegister(false);
      setChangePasswordError(false);
      changePasswordErrorMsg.innerHTML = "密碼未填寫";
    } else if (password !== changePassword) {
      setDisabledRegister(false);
      setChangePasswordError(false);
      changePasswordErrorMsg.innerHTML = "與設定密碼不符，請確認！";
    } else {
      setChangePasswordError(true);
      changePasswordErrorMsg.innerHTML = "";
    }
  }

  // function _register() {
  //   history.push("/member-terms");
  // }

  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "-400px",
        maxWidth: isPhone ? "300px" : "500px",
      }}
    >
      <Box py={3} mb={6} mt={4} borderBottom="1px solid rgba(79,79,79,0.6)">
        <ProductTitle title="忘記密碼" subtitle="Forgot Password" />
      </Box>
      <Grid container spacing={1}>
        <Grid container item>
          <TextField
            label="請輸入新密碼"
            fullWidth
            error={!passwordError}
            className={
              passwordError ? classes.textstyle : classes.texterrorstyle
            }
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setShowPassword((e) => !e)}
                    size="small"
                  >
                    {createElement(
                      showPassword ? VisibilityIcon : VisibilityOffIcon
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className={classes.ErrorMessage}
            variant="body2"
            id="passwordErrorMsg"
          ></Typography>
        </Grid>
        <Grid container item>
          <TextField
            label="再次輸入密碼"
            fullWidth
            error={!changePasswordError}
            className={
              changePasswordError ? classes.textstyle : classes.texterrorstyle
            }
            type={showRePassword ? "text" : "password"}
            onChange={(e) => setChangePassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setShowRePassword((e) => !e)}
                    size="small"
                  >
                    {createElement(
                      showRePassword ? VisibilityIcon : VisibilityOffIcon
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className={classes.ErrorMessage}
            variant="body2"
            id="changePasswordErrorMsg"
          ></Typography>
        </Grid>
        <Grid container item style={{ marginTop: "2em" }}>
          <Box style={{ width: isPhone ? "80%" : "50%" }} mx="auto">
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#212121",
                color: "#fff",
                paddingTop: "5px",
              }}
              className={classes.SendBtn}
              onClick={_change}
            >
              確認修改完成
            </Button>
          </Box>
        </Grid>
        <ContactUs />
      </Grid>
      <Box className={classes.joinOrReturnBox}>
        <Button
          onClick={() => history.push("/member-terms")}
          className={classes.joinOrReturnBtn}
        >
          加入會員
        </Button>
        <span style={{ padding: "0 10px" }}>/</span>
        <Button
          onClick={() => history.push("/")}
          className={classes.joinOrReturnBtn}
        >
          返回首頁
        </Button>
      </Box>
    </Container>
  );
}

function ContactUs() {
  return (
    <Box display="flex" alignItems="center" py={5} mx="auto">
      <Box mx={3}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          聯絡我們
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {/* 連結指向27的 對話框 */}
        <Button href="#">
          <img
            src={require("../images/lineIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
        {/* middle line */}
        <Box width="1.5px" height="20px" bgcolor="#212121" mx={1}></Box>
        <Button href="#">
          <img
            src={require("../images/igIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
      </Box>
    </Box>
  );
}

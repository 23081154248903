import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  FormControl,
  useMediaQuery,
  Grid,
  ButtonBase,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  useForm,
  useWatch,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "./Alert";
import { Select } from "./Form";

const GET_CART = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          images {
            location
          }
          name
          price
        }
        unitPrice
        amount
      }
      totalPrice
    }
  }
`;

const ADD_ITEMTOCART = gql`
  mutation addItemToCart($id: Int!, $cartItemInput: CartItemInput!) {
    addItemToCart(id: $id, cartItemInput: $cartItemInput) {
      success
      message
    }
  }
`;

export default function ProductList({ data, onSearchResult = () => {} }) {
  const productListForm = useForm({
    defaultValues: {
      floatCartPcOpen: false,
    },
  });

  return (
    <Container maxWidth="lg" style={{ minHeight: "calc(100vh - 318.63px)" }}>
      <Box py={2} mx={2}>
        <FormProvider {...productListForm}>
          <Grid
            style={{
              display: "none",
              position: "fixed",
              // top: open ? "184px" : "144px",
              top: false ? "184px" : "144px",
              zIndex: "100",
            }}
            id="floatCart"
          >
            <FloatCartPc />
          </Grid>
          {/* ANCHOR 商品  */}
          <Grid container>
            <SearchList
              onSearch={(v) => {
                onSearchResult(v);
              }}
            />
          </Grid>
          <Grid container spacing={4} style={{ paddingTop: "20px" }}>
            {data.map((item, index) => (
              <Product item={item} />
            ))}
          </Grid>
        </FormProvider>
      </Box>
    </Container>
  );
}

// 下方的promote 、更多資訊商品
function IsPromote(item) {
  const useStyles = makeStyles({
    ProductImage: {
      width: "75%",
      height: "75%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={item.images[0].location} className={classes.ProductImage} />
    </Box>
  );
}

function Product({ item }) {
  const isTable = useMediaQuery("(min-width:769px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const Alert = useAlert();
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles({
    ProductInfo: {
      textAlign: isTable ? "center" : "center",
      paddingTop: isTable ? "1.5em" : "0.3em",
      paddingBottom: "1em",
      cursor: "pointer",
    },
    CartButton: {
      textAlign: "center",
      width: isTable ? "60%" : "80%",
      border: "1px solid black",
      display: "flex",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    goPayButton: {
      textAlign: "center",
      width: isTable ? "60%" : "80%",
      display: "flex",
      justifyContent: "center",
    },
    amount: {
      display: "flex",
      alignItems: "center",
    },
    productName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
    },
  });
  const classes = useStyles();

  const enterRef = useRef(false);
  const { setValue } = useFormContext();

  //加入購物車
  function _addItemToCart() {
    addItemToCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemInput: {
          productId: item.id,
          amount: 1,
        },
      },
    });
  }

  const [addItemToCart] = useMutation(ADD_ITEMTOCART, {
    onCompleted({ addItemToCart }) {
      if (addItemToCart.success) {
        if (enterRef.current) {
          enterRef.current = false;
          return history.push("/cart-page");
        } else {
          clearfloat();
          return setValue("floatCartPcOpen", true);
        }
      } else if (addItemToCart.message) {
        Alert.notify(addItemToCart.message);
      }
    },
  });

  return (
    <Grid item md={3} sm={4} xs={6} style={{ paddingBottom: "50px" }}>
      <Box display="flex" flexDirection="column">
        <ButtonBase
          onClick={() => {
            history.push(`/product-detail/${item.id}`);
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            style={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              paddingTop: "100%",
            }}
          >
            {IsPromote(item)}
          </Box>
        </ButtonBase>
        <Box>
          <Box
            pb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ButtonBase
              style={{
                width: isTable ? "60%" : "100%",
                textAlign: isTable ? "left" : "center",
              }}
              onClick={() => {
                history.push(`/product-detail/${item.id}`);
              }}
            >
              <Box
                // height="140px"
                style={{
                  textAlign: isTable ? "left" : "center",
                }}
              >
                {/*商品品牌 */}
                <Typography
                  variant="p"
                  style={{
                    fontSize: "0.9rem",
                    display: isTable ? "" : "none",
                    letterSpacing: "1.2px",
                    fontFamily: "GENBKBASR",
                    color: "#4f4f4f",
                  }}
                >
                  {item.brand}
                </Typography>
                <br />
                {/* 商品名稱 這邊 */}
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "GENBKBASR",
                    fontWeight: "bold",
                    color: "#4c4c4c",
                    letterSpacing: "1.2px",
                    height: "80px",
                  }}
                  className={classes.productName}
                >
                  {item.name}
                </Typography>
                {/* 商品ml量、價錢 */}
                <Typography
                  variant="p"
                  style={{
                    fontSize: "0.9em",
                    letterSpacing: "1.2px",
                    color: "#4c4c4c",
                    fontFamily: "GENBKBASR",
                  }}
                >
                  {item.capacity}
                  <Typography
                    vatiant="caption"
                    component="span"
                    style={{ margin: "0 0.5em" }}
                  >
                    /
                  </Typography>
                  NT.{item.price}
                </Typography>
              </Box>
            </ButtonBase>
          </Box>
          <Box display="flex" justifyContent="center" mt={1.5}>
            {/* ANCHOR手機 會先判斷是不適手機介面*/}
            <Button
              className={classes.CartButton}
              onClick={() => {
                enterRef.current = false;
                _addItemToCart();
              }}
              disabled={item.stock === 0}
            >
              <ShoppingCartIcon
                id="ShoppingCartIcon"
                className={classes.shoppingCartIcon}
              />
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" mt={1.5}>
            <Button
              color="primary"
              variant="contained"
              className={classes.goPayButton}
              onClick={() => {
                enterRef.current = true;
                _addItemToCart();
              }}
              disabled={item.stock === 0}
            >
              <Typography
                variant={isPhone ? "subtitle1" : "h6"}
                style={{ color: "white" }}
              >
                {item.stock === 0 ? "已售完" : "馬上購買"}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
function SearchList({ onSearch = () => {} }) {
  // const theme = useTheme();
  const { pathname } = useLocation();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const useStyles = makeStyles((theme) => ({
    searchNav: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#D5CFB9",
      justifyContent: isDownTablet ? "flex-start" : "flex-end",
      position: "absolute",
      width: "100%",
      // paddingRight: "4em",
    },
    searchListBox: {
      // position:'relative',
    },
    menuItem: {
      backgroundColor: "#D5CFB9",
    },
    searchMenu: {
      position: "absolute",
      "& .MuiList-padding": {
        padding: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    searchSelect: {
      outline: "none",
      "& .MuiSelect-outlined": {
        outline: "none",
        border: "none",
      },
      "& option": {
        backgroundColor: "blue",
      },
    },
  }));
  const classes = useStyles();

  //找到搜尋對象 來輸出searchTarget TODO
  //  -> 再搜尋框輸入搜尋的目標之後 , 將搜尋的字串 放到 searchTarget中 !  ><!
  const searchWord = new URLSearchParams(useLocation().search);
  const searchLabel = [
    {
      text: "全部商品",
      target: searchWord.get("search") ?? "無",
    },
  ];
  const searchArray = [
    { label: "商品排序", value: "商品排序" },
    { label: "上架時間-由新到舊", value: "上架時間-由新到舊" },
    { label: "上架時間-由舊到新", value: "上架時間-由舊到新" },
    { label: "價格-由高至低", value: "價格-由高至低" },
    { label: "價格-由低至高", value: "價格-由低至高" },
  ];
  const [search, setSearch] = useState(
    Boolean(localStorage.getItem("@ProductsSearch"))
      ? {
          label: localStorage.getItem("@ProductsSearch"),
          value: localStorage.getItem("@ProductsSearch"),
        }
      : {
          label: "商品排序",
          value: "商品排序",
        }
  );
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isDownTablet ? "flex-start" : "flex-end"}
      width="100%"
      mx="auto"
    >
      {pathname.includes("search-page") && (
        <Box>
          {searchLabel.map((item, index) => {
            return (
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {item.text} &ensp; | &ensp; {item.target}
              </Typography>
            );
          })}
        </Box>
      )}
      &ensp; &ensp; &ensp;
      <Grid item sm={2} style={{ paddingTop: "20px" }}>
        <Select
          items={searchArray}
          value={search}
          onChange={(e, v) => {
            if (v) {
              setSearch(v);
              onSearch(v);
              localStorage.setItem("@ProductsSearch", v.value);
            } else {
              setSearch({ label: "商品排序", value: "商品排序" });
              localStorage.setItem("@ProductsSearch", "商品排序");
              onSearch(null);
            }
          }}
          fullWidth
        />
      </Grid>
    </Box>
  );
}

let timer;

function clearfloat() {
  document.getElementById("floatCart").style.display = "block";
  timer = setTimeout(function () {
    if (document.getElementById("floatCart")) {
      document.getElementById("floatCart").style.display = "none";
    }
  }, 3000);
}

function cleartimer() {
  clearTimeout(timer);
}

// floatCart pc PC購物車黑色浮層
function FloatCartPc() {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    floatCartText: {
      color: "#fff",
      letterSpacing: "1.3px",
    },
    floatCartDelText: {
      color: "#fff",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      transition: "all 0.3s",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.grey[300],
      },
    },
    floatCartDelButton: {
      position: "absolute",
      top: "10%",
      right: "3%",
      color: "#fff",
      "&:hover": {
        color: theme.palette.grey[300],
      },
    },
    goPay: {
      borderRadius: "0px",
      backgroundColor: [theme.palette.success.main],
      "&:hover": {
        backgroundColor: [theme.palette.success.light],
      },
    },
    goPayText: {
      padding: "10px 20px",
      color: theme.palette.common.white,
    },
  });
  const isSmPhone = useMediaQuery("(max-width:765px)");
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const documentFloatCart = document.getElementById("floatCart");
    documentFloatCart.addEventListener(
      "mouseover",
      function () {
        cleartimer();
      },
      false
    );
    documentFloatCart.addEventListener(
      "mouseout",
      function () {
        clearfloat();
      },
      false
    );
  });

  const { control, setValue } = useFormContext();
  const floatCartPcOpen = useWatch({
    control,
    name: "floatCartPcOpen",
  });

  const [getCart, { data, loading }] = useLazyQuery(GET_CART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ cart }) {
      if (cart) {
        setValue("floatCartPcOpen", false);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (floatCartPcOpen) {
      const hasCartId = localStorage.getItem("@twenty-seven-webCartId");
      if (Boolean(hasCartId)) {
        getCart({
          variables: { id: JSON.parse(hasCartId) },
        });
      }
    }
  }, [floatCartPcOpen]);

  return (
    <Box>
      {/* 上方黑色浮層外框 */}
      <Box bgcolor="#4f4f4f" style={{ height: "100vh" }}>
        {/* 黑色浮層ul */}
        {loading ? (
          <Box bgcolor="#4f4f4f" style={{ width: "20vw" }}>
            <Grid container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          </Box>
        ) : (
          <>
            {Boolean(data) ? (
              <>
                <Box
                  display="flex"
                  flexWrap="warp"
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    flexDirection: "column",
                  }}
                >
                  {data.cart.items.map((item, index) => {
                    return (
                      <Box
                        py={4}
                        px={2}
                        display="flex"
                        position="relative"
                        style={{
                          border: "1px solid black",
                          minWidth: isSmPhone
                            ? `calc(${document.body.clientWidth}px)`
                            : `calc(${document.body.clientWidth}px/4)`,
                        }}
                      >
                        {/* 商品圖片 */}
                        <Box>
                          <img
                            src={item.product.images[0].location}
                            width="80px"
                            height="80px"
                            style={{
                              objectFit: "contain",
                              backgroundColor: "#fff",
                              padding: "10px",
                            }}
                          />
                        </Box>
                        {/* 商品內容 */}
                        <Box
                          ml={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.floatCartText}>
                            {" "}
                            {item.product.name}{" "}
                          </Typography>
                          <Typography className={classes.floatCartText}>
                            {" "}
                            Nt.{item.product.price}{" "}
                          </Typography>
                          <CountBoxSm amount={item.amount} />
                        </Box>
                        {/* 刪除、數量 */}
                        {/* <Box className={classes.floatCartDelButton}>
                          <button
                            className={classes.floatCartDelText}
                            size="small"
                            // onClick={() => deleteProduct(item.itemName)}
                          >
                            <Typography variant="caption">
                              <CloseIcon style={{ maxWidth: "25px" }} />
                            </Typography>
                          </button>
                        </Box> */}
                      </Box>
                    );
                  })}
                </Box>
                <Grid
                  container
                  justifyContent="center"
                  style={{ paddingTop: "10px" }}
                >
                  <Grid item xs={10}>
                    <Button
                      variant="contained"
                      fullWidth
                      className={classes.goPay}
                      onClick={() => {
                        cleartimer();
                        history.push("/cart-page");
                      }}
                    >
                      <Typography variant="h6" className={classes.goPayText}>
                        前往結帳
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}
//PC黑色浮層購物車的數量設定
function CountBoxSm({ amount }) {
  const useStyles = makeStyles({
    amount: {
      display: "flex",
      alignItems: "center",
    },
    amountMiner: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "20px",
      height: "20px",
      borderRight: "none",
      cursor: "pointer",
      color: "#fff",
    },
    amountPlus: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "20px",
      height: "20px",
      borderLeft: "none",
      cursor: "pointer",
      color: "#fff",
    },
    amountInput: {
      height: "20px",
      textAlign: "center",
      width: "50px",
      border: "0.8px solid gray",
      color: "#fff",
      "&:focus": {
        outline: "none",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.amount}>
        <Typography variant="body2" style={{ color: "#fff" }}>
          數量
        </Typography>
        &ensp;&ensp;
        {/* <button className={classes.amountMiner} onClick={() => count(false)}>
          {" "}
          -{" "}
        </button> */}
        {/* 數量 */}
        <input
          type="text"
          className={classes.amountInput}
          disabled
          value={amount}
        />
        {/* <button className={classes.amountPlus} onClick={() => count(true)}>
          {" "}
          +{" "}
        </button> */}
      </Box>
    </Box>
  );
}

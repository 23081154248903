import { createSlice } from "@reduxjs/toolkit";

// ANCHOR 預設值
const initialState = {
  // "購物車金額異動中"
  cartAmountChanging: false,
};
// ANCHOR 主要組件
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cartAmountStartChanging: (state) => {
      state.cartAmountChanging = true;
    },
    cartAmountEndChanging: (state) => {
      state.cartAmountChanging = false;
    },
  },
});

export const { cartAmountStartChanging, cartAmountEndChanging } =
  cartSlice.actions;

export const cartSliceState = (state) => state.cart;

export default cartSlice.reducer;

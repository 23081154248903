import React, { useState } from "react";
import {
  Card,
  CardActions,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DatePicker } from "antd";
import moment from "moment-timezone";

import { thousandsSeparator } from "../components/utils";

const GET_CHECKOUTORDER = gql`
  query checkoutOrder($startDate: Date, $endDate: Date) {
    checkoutOrder(startDate: $startDate, endDate: $endDate) {
      count
      pastCount
      revenue
      pastRevenue
    }
  }
`;

export default function CheckoutOrderPage() {
  const { RangePicker } = DatePicker;
  const [newData, setNewData] = useState({
    startDate: null,
    endDate: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { data, loading, error } = useQuery(GET_CHECKOUTORDER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: newData.startDate,
      endDate: newData.endDate,
    },
  });

  return (
    <Card>
      <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <RangePicker
              locale="zh-tw"
              format="YYYY-MM-DD"
              value={[
                newData.startDate ? moment(newData.startDate) : null,
                newData.endDate ? moment(newData.endDate) : null,
              ]}
              style={{ height: 40, width: "100%" }}
              onChange={(value) => {
                if (value) {
                  changeData(
                    "startDate",
                    moment(value[0])
                      .tz("Asia/Taipei")
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .toDate()
                  );
                  changeData(
                    "endDate",
                    moment(value[1])
                      .tz("Asia/Taipei")
                      .hour(23)
                      .minute(59)
                      .second(59)
                      .toDate(),
                    true
                  );
                } else {
                  changeData("startDate", null);
                  changeData("endDate", null, true);
                }
              }}
              placeholder={["開始時間", "結束時間"]}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <CardActions style={{ marginTop: 10 }}>
        {loading ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4">無法取得資料</Typography>
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">範圍時間內有效訂單總數</Typography>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                {thousandsSeparator(data?.checkoutOrder.count)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">範圍時間內有效訂單營業額</Typography>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                {thousandsSeparator(data?.checkoutOrder.revenue)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">30天前有效訂單總數</Typography>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                {thousandsSeparator(data?.checkoutOrder.pastCount)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">30天前有效訂單營業額</Typography>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                {thousandsSeparator(data?.checkoutOrder.pastRevenue)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Card>
  );
}

import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  ButtonBase,
  CardActions,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useForm, Controller } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import { checkImageSize } from "../../components/utils";
import LoadingModal from "../../components/LoadingModal";

const GET_ADVERTISEMENT = gql`
  query advertisement($id: Int!) {
    advertisement(id: $id) {
      id
      filename
      mimetype
      encoding
      location
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const SAVE_ADVERTISEMENT = gql`
  mutation saveAdvertisement($advertisementInput: AdvertisementInput!) {
    saveAdvertisement(advertisementInput: $advertisementInput) {
      success
      message
    }
  }
`;

export default function AdvertisementForm({ id, onChangeSelect = () => {} }) {
  const Alert = useAlert();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      image: undefined,
    },
  });

  const [getAdvertisement, { loading }] = useLazyQuery(GET_ADVERTISEMENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ advertisement }) {
      if (advertisement) {
        const { __typename, ...newAdvertisement } = advertisement;
        setValue("image", newAdvertisement);
      }
    },
  });

  useEffect(() => {
    if (id) {
      getAdvertisement({ variables: { id } });
    } else {
      setValue("image", undefined);
    }
  }, [id]);

  const [uploadImage, { loading: uploadImageLoading }] =
    useMutation(UPLOAD_IMAGE);

  const [saveAdvertisement, { loading: saveAdvertisementLoading }] =
    useMutation(SAVE_ADVERTISEMENT, {
      onCompleted({ saveAdvertisement }) {
        if (saveAdvertisement.success) {
          onChangeSelect();
        } else if (saveAdvertisement.message) {
          Alert.notify(saveAdvertisement.message);
        }
      },
    });

  async function _saveAdvertisement(data) {
    const { image } = data;
    let newImage = undefined;
    if (image.name) {
      const {
        data: {
          uploadImage: { __typename, ...newUploadImage },
        },
      } = await uploadImage({ variables: { image } });
      newImage = { ...newUploadImage };
    }
    if (newImage) {
      const advertisementInput = {
        id,
        ...newImage,
      };
      saveAdvertisement({ variables: { advertisementInput } });
    } else {
      const advertisementInput = {
        id,
        ...image,
      };
      saveAdvertisement({ variables: { advertisementInput } });
    }
  }

  return (
    <Card>
      <LoadingModal loading={uploadImageLoading || saveAdvertisementLoading} />
      {loading ? (
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          height="480px"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          <CardContent>
            <Grid container xs={12} sm={12}>
              <Controller
                control={control}
                name="image"
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Box
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                    >
                      圖片尺寸為&nbsp;16:9&nbsp;，格式為&nbsp;jpg、png、gif、svg，容量需小於4.5MB
                    </Typography>
                    <ButtonBase
                      style={{
                        position: "relative",
                        width: "100%",
                        overflow: "hidden",
                        paddingTop: "56.25%",
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        border: Boolean(error) && "1px solid #F77B72",
                        borderRadius: "5px",
                      }}
                      component="label"
                      htmlFor={`uploadImage`}
                    >
                      {Boolean(value) ? (
                        <img
                          src={
                            value.name
                              ? URL.createObjectURL(value)
                              : value.location
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <CloudUploadIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                          }}
                        />
                      )}
                    </ButtonBase>
                    <input
                      id={`uploadImage`}
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const {
                          target: { files },
                        } = event;
                        const hasSelectImage = files.length > 0;

                        if (hasSelectImage) {
                          const selectImage = files[0];
                          if (checkImageSize(selectImage.size))
                            return Alert.notify("圖片限制 4.5 MB 以內");
                          onChange(selectImage);
                        }
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                  </Box>
                )}
              />
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container item justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_saveAdvertisement)}
              >
                儲存
              </Button>
            </Grid>
          </CardActions>
        </>
      )}
    </Card>
  );
}

import React, { useState, createElement } from "react";
import {
  Container,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  useMediaQuery,
  TextField,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../components/Alert";
import ProductTitle from "../components/ProductTitle";
import LoadingFloating from "../components/LoadingFloating";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode($mobile: String!, $needCheck: Boolean) {
    sendVerificationCode(mobile: $mobile, needCheck: $needCheck) {
      success
      message
    }
  }
`;

const RESET_USERPASSWORD = gql`
  mutation resetUserPassword(
    $mobile: String!
    $verificationCode: String!
    $password: String!
  ) {
    resetUserPassword(
      mobile: $mobile
      verificationCode: $verificationCode
      password: $password
    ) {
      success
      message
    }
  }
`;

export default function ForgePasswordPage() {
  const Alert = useAlert();
  const history = useHistory();
  const [disabledForget, setDisabledForget] = useState(false);
  const [email, setEmail] = useState(false);
  const [mobile, setMobile] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [mobileError, setMobileError] = useState(true);
  const [verificationCodeError, setVerificationCodeError] = useState(true);
  const [repasswordError, setReasswordError] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [emailError, setEmailError] = useState(true);

  const [disabledRegister, setDisabledRegister] = useState(false);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [passwordError, setPasswordError] = useState(true);
  const [changePasswordError, setChangePasswordError] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    textstyle: {
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },
    SendBtn: {
      padding: "5px 0px !important",
    },
    ErrorMessage: {
      color: "red",
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    SuccessMessage: {
      color: "green",
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    email: {
      position: "relative",
      width: "100%",
      border: "none",
      background: "transparent",
      borderBottom: "1px solid red",
      letterSpacing: "1px",
      padding: "14px 0",
      outline: "none",
      paddingLeft: "40px",
    },
    mobile: {
      position: "relative",
      width: "100%",
      border: "none",
      background: "transparent",
      borderBottom: "1px solid red",
      letterSpacing: "1px",
      padding: "14px 0",
      outline: "none",
    },
    verificationCode: {
      position: "relative",
      width: "100%",
      border: "none",
      background: "transparent",
      borderBottom: "1px solid red",
      letterSpacing: "1px",
      padding: "14px 0",
      outline: "none",
    },
    password: {
      position: "relative",
      width: "100%",
      border: "none",
      background: "transparent",
      borderBottom: "1px solid red",
      letterSpacing: "1px",
      padding: "14px 0",
      outline: "none",
    },
  });
  const classes = useStyles();
  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATIONCODE,
    {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode.success) {
          return Alert.notify("驗證碼發送成功！", () => setVerifying(true));
        } else if (sendVerificationCode.message) {
          return Alert.notify(sendVerificationCode.message);
        }
      },
    }
  );
  const [resetUserPassword, { loading: resetUserPasswordLoading }] =
    useMutation(RESET_USERPASSWORD, {
      onCompleted({ resetUserPassword }) {
        if (resetUserPassword.success) {
          return Alert.notify("密碼重設成功！", () =>
            history.replace("/loginPage")
          );
        } else if (resetUserPassword.message) {
          return Alert.notify(resetUserPassword.message);
        }
      },
    });

  // function _submit() {
  //   resetUserPassword({
  //     variables: {
  //       mobile: data.mobile,
  //       verificationCode: data.verificationCode,
  //       password: data.password,
  //     },
  //   });
  // }
  // function _forget() {
  //   setDisabledForget(true);
  //   const ErrorMail = document.getElementById("ErrorMail");

  //   if (!Boolean(email)) {
  //     setDisabledForget(false);
  //     setEmailError(false);
  //     ErrorMail.innerHTML = "信箱未填寫";
  //   } else if (!/^[\w\.]+@\w+((\.|-)\w+)+$/.test(email)) {
  //     setDisabledForget(false);
  //     setEmailError(false);
  //     ErrorMail.innerHTML = "信箱格式不正確";
  //   } else {
  //     setEmailError(true);
  //     ErrorMail.innerHTML = "已傳送驗證連結至信箱";
  //   }

  //   if (disabledForget === true) {
  //     history.push("/change-password");
  //   }
  // }
  function _mobile() {
    setDisabledForget(true);
    const ErrorMail = document.getElementById("ErrorMail");

    if (!Boolean(mobile)) {
      setDisabledForget(false);
      setMobileError(false);
      ErrorMail.innerHTML = "手機未填寫";
    } else if (!/^09[0-9]{8}$/.test(mobile)) {
      setDisabledForget(false);
      setMobileError(false);
      ErrorMail.innerHTML = "手機格式不正確";
    } else {
      setMobileError(true);
      sendVerificationCode({
        variables: { mobile, needCheck: true },
      });
    }
  }

  function _change() {
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;
    const passwordErrorMsg = document.getElementById("passwordErrorMsg");
    const changePasswordErrorMsg = document.getElementById(
      "changePasswordErrorMsg"
    );
    const ErrorVerificationCode = document.getElementById(
      "ErrorVerificationCode"
    );
    if (!Boolean(password)) {
      setDisabledRegister(false);
      setPasswordError(false);
      passwordErrorMsg.innerHTML = "密碼未填寫";
    } else if (!passwordRegex.test(password)) {
      setDisabledRegister(false);
      setPasswordError(false);
      passwordErrorMsg.innerHTML = "密碼格式錯誤，需八碼且包含英文以及數字！";
    } else {
      setPasswordError(true);
      passwordErrorMsg.innerHTML = "";
    }

    if (!Boolean(changePassword)) {
      setDisabledRegister(false);
      setChangePasswordError(false);
      changePasswordErrorMsg.innerHTML = "密碼未填寫";
    } else if (password !== changePassword) {
      setDisabledRegister(false);
      setChangePasswordError(false);
      changePasswordErrorMsg.innerHTML = "與設定密碼不符，請確認！";
    } else {
      setChangePasswordError(true);
      changePasswordErrorMsg.innerHTML = "";
    }

    if (!Boolean(verificationCode)) {
      setDisabledForget(false);
      setVerificationCodeError(false);
      ErrorVerificationCode.innerHTML = "驗證碼未填寫";
    }
    resetUserPassword({
      variables: {
        mobile: mobile,
        verificationCode: verificationCode,
        password: password,
      },
    });
  }
  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: isPhone ? "300px" : "500px",
      }}
    >
      <LoadingFloating loading={loading || resetUserPasswordLoading} />
      <Box py={4} mb={6} borderBottom="1px solid rgba(79,79,79,0.6)">
        <ProductTitle title="忘記密碼" subtitle="Forgot Password" />
      </Box>
      {/* <Grid container spacing={1}>
            <Grid container item>
              <Box width="100%" mx="auto" className="ForgetPasswordInputBox" >
                <input 
                  placeHolder="Mail"
                  className={emailError?"ForgetPasswordInput":classes.email}
                  onChange={(e)=> setEmail(e.target.value)}
                  error
                  />
              </Box>
              <Typography className={disabledForget?classes.SuccessMessage:classes.ErrorMessage} variant="body2" id="ErrorMail"></Typography>
            </Grid> 
            <Grid container item style={{marginTop:'2em'}}>
              <Box style={{width:isPhone ? '80%':'50%'}} mx="auto">
                <Button
                  variant="contained"
                  fullWidth
                  style={{backgroundColor:'#212121',color:'#fff',paddingTop:'5px'}}
                  className={classes.SendBtn}
                  onClick={_forget}
                >
                  傳送重設密碼認證至信箱
                </Button>
              </Box>
              
            </Grid>
            <ContactUs/>
          </Grid>  */}
      <Grid container spacing={1}>
        <Grid item xs={10} sm={10} mx="auto" style={{ marginTop: "15px" }}>
          <TextField
            placeholder="手機號碼"
            fullWidth
            error={!passwordError}
            className={mobileError ? "ForgetPasswordInput" : classes.mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
          <Typography
            className={
              disabledForget ? classes.SuccessMessage : classes.ErrorMessage
            }
            variant="body2"
            id="ErrorMail"
          ></Typography>
        </Grid>
        <Grid item xs={2} sm={2} style={{ marginTop: "10px" }}>
          <Box style={{ width: "80%" }} mx="auto">
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#212121",
                color: "#fff",
                paddingTop: "5px",
              }}
              className={classes.SendBtn}
              onClick={_mobile}
            >
              手機驗證
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            placeholder={verifying ? "驗證碼" : "請先按下手機驗證"}
            fullWidth
            error={!passwordError}
            className={
              verificationCodeError
                ? "ForgetPasswordInput"
                : classes.verificationCode
            }
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <Typography
            className={
              disabledForget ? classes.SuccessMessage : classes.ErrorMessage
            }
            variant="body2"
            id="ErrorVerificationCode"
          ></Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            placeholder="請輸入新密碼"
            fullWidth
            error={!passwordError}
            className={
              passwordError ? classes.textstyle : classes.texterrorstyle
            }
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setShowPassword((e) => !e)}
                    size="small"
                  >
                    {createElement(
                      showPassword ? VisibilityIcon : VisibilityOffIcon
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className={classes.ErrorMessage}
            variant="body2"
            id="passwordErrorMsg"
          ></Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            placeholder="再次輸入密碼"
            fullWidth
            error={!changePasswordError}
            className={
              changePasswordError ? classes.textstyle : classes.texterrorstyle
            }
            type={showRePassword ? "text" : "password"}
            onChange={(e) => setChangePassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => setShowRePassword((e) => !e)}
                    size="small"
                  >
                    {createElement(
                      showRePassword ? VisibilityIcon : VisibilityOffIcon
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className={classes.ErrorMessage}
            variant="body2"
            id="changePasswordErrorMsg"
          ></Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#212121",
              color: "#fff",
              paddingTop: "5px",
            }}
            className={classes.SendBtn}
            onClick={_change}
          >
            重設密碼
          </Button>
        </Grid>
        <ContactUs />
      </Grid>
    </Container>
  );
}

function ContactUs() {
  return (
    <Box display="flex" alignItems="center" py={5} mx="auto">
      <Box mx={3}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          聯絡我們
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {/* 連結指向27的 對話框 */}
        <Button href="#">
          <img
            src={require("../images/lineIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
        {/* middle line */}
        <Box width="1.5px" height="20px" bgcolor="#212121" mx={1}></Box>
        <Button href="#">
          <img
            src={require("../images/igIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
      </Box>
    </Box>
  );
}

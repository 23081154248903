import React, {
  useState,
  useContext,
  useLayoutEffect,
  createElement,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "../components/Alert";
import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListSubheader,
  Paper,
  Tabs,
  Tab,
  InputAdornment,
  TextField,
  Badge,
  useTheme,
  IconButton,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

export default function MobileMenuList({ menuListArray, mobileBannerPorp }) {
  const theme = useTheme();
  const { alert, notify } = useAlert();
  const token = localStorage.getItem("@twenty-seven-webToken");

  const logOut = () => {
    alert("", "確定要登出嗎", [
      {
        text: "確定",
        onClick: () => {
          history.replace("/");
          setMenuCollapse(false);
          localStorage.removeItem("@twenty-seven-webToken");
          localStorage.removeItem("@twenty-seven-webMemberId");
          notify("登出成功!");
        },
      },
      {
        text: "取消",
        type: "outlined",
      },
    ]);
  };
  const IconImg = [
    {
      icon: require("../images/messageIcon.png").default,
    },
    {
      icon: require("../images/accountCircle.png").default,
    },
    {
      icon: require("../images/CartIcon.png").default,
    },
  ];

  const { bannerUrl, setBannerUrl, bannerIndex, setBannerIndex } =
    mobileBannerPorp;

  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [menuCollapse, setMenuCollapse] = useState(0);
  const [searchCollapse, setSearchCollapse] = useState(0);

  const [searchContent, setSearchContent] = useState();

  const { pathname, search } = useLocation();
  const [openCollapse, setOpenCollapse] = useState(null);
  const url = pathname + search;
  function sameRoute(value) {
    return url === value;
  }
  function autoOpenMenu() {
    let newIndex = null;
    for (let i = 0, len = menuListArray.length; i < len; i++) {
      const element = menuListArray[i];
      if (element.second[0]) {
        for (let j = 0; j < element.second.length; j++) {
          if (
            element.second.find(
              (item) =>
                `/${element.enName}/${item.enName}-page` ===
                history.location.pathname
            )
          ) {
            newIndex = i;
            break;
          }
        }
      }
    }
    setOpenCollapse(newIndex);
  }
  useLayoutEffect(() => {
    autoOpenMenu();
  }, [url]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "#d4d4d4",
      zIndex: "99999",
    },
    nested: {
      borderBottom: "0.5px solid rgba(128,128,128,0.4)",
    },
    ListSubHeader: {
      backgroundColor: "#b6b6b6",
      textAlign: "left",
      fontSize: "20px",
    },

    SubListTitle: {
      fontSize: "20px",
      display: "flex",
      alignItems: "center",
      padding: "0 200px",
    },
    subListTitleText: {
      padding: "4px 16px",
    },
    ExpandMoreIcon: {
      fontSize: "40px",
      color: "#7c7d75",
      marginRight: "20px",
    },
    SubListTitleListBox: {
      fontSize: "20px",
      backgroundColor: "#e4e3da",
    },
    bannerContentTitle: {
      color: "#fff",
      letterSpacing: "2px",
      fontFamily: "GENBKBASR",
    },
    bannerContentSubTitle: {
      color: "#fff",
      letterSpacing: "1.4px",
      fontFamily: "GENBKBASR",
      fontSize: "0.9em",
    },
    bannerContentLabel: {
      display: "block",
      color: "#fff",
      fontSize: "0.95em",
    },

    // 搜尋 collapse
    textstyle: {
      width: "80%",
      color: "#fff",
      "& .MuiTextField-root::-webkit-input-placeholder": {
        color: "#fff",
      },
      "& label.Mui-focused": {
        color: "#fff",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#fff",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "#fff",
      },
    },
  }));

  const classes = useStyles();

  // 點擊 tabs效果
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setSearchCollapse(false);
      setMenuCollapse((e) => !e);
    } else if (newValue === 1) {
      setMenuCollapse(false);
      setSearchCollapse((e) => !e);
    } else if (newValue === 2) {
      if (token) {
        history.replace("/my-account");
        setSearchCollapse(false);
        setMenuCollapse(false);
      } else {
        history.push("/loginPage");
        setSearchCollapse(false);
        setMenuCollapse(false);
      }
    } else {
      history.push("../cart-page");
      setSearchCollapse(false);
      setMenuCollapse(false);
    }
    setTabValue(newValue);
  };

  //ANCHOR Tab圖案
  function TabIcon(number) {
    return (
      <img
        src={IconImg[number].icon}
        width="24px"
        height="24px"
        style={{ objectFit: "contain" }}
      />
    );
  }

  //ANCHOR 搜尋enter送出
  function keyPress(event) {
    if (event.keyCode === 13) {
      history.push(
        `/search-page?search=${
          searchContent === undefined ? "無" : searchContent
        }`
      );
    }
  }

  if (Boolean(menuListArray[0])) {
    return (
      <Box textAlign="center" position="relative">
        <Paper square className={classes.root}>
          {/* ANCHOR Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="none"
            style={{ backgroundColor: "#dddcd5" }}
          >
            {/* 搜尋增加方法 */}
            <Tab icon={<DehazeIcon />} />
            <Tab icon={<SearchIcon />} />
            <Tab icon={TabIcon(1)} />
            <Tab icon={<Badge color="secondary">{TabIcon(2)}</Badge>} />
          </Tabs>
        </Paper>

        <Collapse in={menuCollapse} timeout="auto" unmountOnExit>
          {/* ANCHOR展開的主菜單 */}
          <List
            component="nav"
            className={classes.root}
            style={{ padding: "0" }}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className={classes.ListSubHeader}
              >
                目錄
              </ListSubheader>
            }
          >
            {/* setOnSubListIndexBox(true) */}
            {/* ANCHOR 各種subList 更改成判斷 */}
            <Box className={classes.SubListIndexBox}>
              <ListItem
                button
                onClick={() => {
                  history.push("../");
                  setMenuCollapse(false);
                }}
                className={classes.SubListTitle}
              >
                <ListItemText
                  primary="首頁"
                  className={classes.subListTitleText}
                />
              </ListItem>
            </Box>

            <Box className={classes.SubListLimitBox}>
              {menuListArray.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    className={classes.SubListTitle}
                    style={
                      (pathname.replace("-page", "").split("/")[1] &&
                        pathname.replace("-page", "").split("/")[1]) ===
                      item.enName
                        ? { backgroundColor: "rgba(0,0,0,0.1)" }
                        : null
                    }
                    button={!sameRoute(item.path)}
                    selected={sameRoute(item.path)}
                    onClick={() => {
                      setBannerUrl("none");
                      setBannerIndex(undefined);
                      if (item.second[0]) {
                        if (typeof openCollapse === "number") {
                          if (openCollapse === index) {
                            return setOpenCollapse(null);
                          } else {
                            return setOpenCollapse(index);
                          }
                        } else {
                          return setOpenCollapse(index);
                        }
                      } else {
                        if (sameRoute(item.path)) {
                          return null;
                        } else {
                          setMenuCollapse(false);
                          if (item.label === "全部商品") {
                            history.push("/all-product");
                          }
                        }
                      }
                    }}
                  >
                    <ListItemText primary={item.label} />
                    {Boolean(item.second[0]) && (
                      <>
                        {createElement(
                          openCollapse === index
                            ? ExpandLessIcon
                            : ExpandMoreIcon
                        )}
                      </>
                    )}
                  </ListItem>
                  {Boolean(item.second[0]) && (
                    <Collapse
                      in={openCollapse === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      {item.second.map((item2, index2) => (
                        <ListItem
                          key={index2}
                          button={!sameRoute(item2.path)}
                          selected={sameRoute(item2.path)}
                          onClick={() => {
                            setBannerUrl(item2.path);
                            setBannerIndex(index);
                            setMenuCollapse(false);
                            history.push(
                              `/${item.enName}/${item2.enName}-page`
                            );
                          }}
                          style={
                            pathname.replace("-page", "").split("/")[
                              pathname.replace("-page", "").split("/").length -
                                1
                            ] === item2.enName
                              ? { backgroundColor: "#A9A9A9" }
                              : null
                          }
                        >
                          <ListItemText primary={`\u2003${item2.label}`} />
                        </ListItem>
                      ))}
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
            </Box>
          </List>

          {/* 帳戶 */}
          {/* NOTE 手機板帳戶  */}
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            style={{ paddingBottom: "0px" }}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className={classes.ListSubHeader}
              >
                帳戶
              </ListSubheader>
            }
            className={classes.root}
          >
            {/* 沒登入 */}
            {!token && (
              <>
                <Box className={classes.SubListLogin}>
                  <ListItem
                    button
                    onClick={() => {
                      history.push("../loginPage");
                      setMenuCollapse(false);
                    }}
                    className={classes.SubListTitle}
                  >
                    <ListItemText
                      primary="會員登入"
                      className={classes.subListTitleText}
                    />
                  </ListItem>
                </Box>

                <Box className={classes.SubListRegist}>
                  <ListItem
                    button
                    onClick={() => {
                      history.push("../member-terms");
                      setMenuCollapse(false);
                    }}
                    className={classes.SubListTitle}
                  >
                    <ListItemText
                      primary="新用戶註冊"
                      className={classes.subListTitleText}
                    />
                  </ListItem>
                </Box>
              </>
            )}

            {/* 登入後 */}
            {token && (
              <Box className={classes.SubListLogin}>
                <ListItem
                  button
                  onClick={logOut}
                  className={classes.SubListTitle}
                >
                  <ListItemText
                    primary="登出"
                    className={classes.subListTitleText}
                  />
                </ListItem>
              </Box>
            )}
          </List>
        </Collapse>

        {/* ANCHOR 子菜單 搜尋 */}
        <Collapse in={searchCollapse} timeout="auto" unmountOnExit>
          <List
            component="nav"
            className={classes.root}
            style={{ padding: "0", backgroundColor: "#E1DCCC" }}
          >
            <Box py={2}>
              <TextField
                className={classes.textstyle}
                id="input-with-icon-textfield"
                onChange={(e) => setSearchContent(e.target.value)}
                onKeyUp={(e) => keyPress(e)}
                placeholder="找商品"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          history.push(
                            `/search-page?search=${
                              searchContent === undefined ? "無" : searchContent
                            }`
                          );
                        }}
                      >
                        <SearchIcon style={{ fill: theme.palette.grey[800] }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </List>
        </Collapse>
      </Box>
    );
  } else {
    return null;
  }
}

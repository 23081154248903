import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, CircularProgress, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

import moment from "moment";
import Table from "../components/Table";
import { useAlert } from "../components/Alert";
import PageItem from "../components/PageItem";
import Button from "../components/Button";
import { CardContent } from "../components/Card";
import LoadingModal from "../components/LoadingModal";
import { Select, TextInput, DateInput } from "../components/Form";

export default function ShipmentListPage() {
  const Alert = useAlert();
  const history = useHistory();
  const datatest = [
    {
      orderId: "a121121",
      name: "Bo",
      phone: "0987532152",
      way: "一般宅配",
      place: "台中市西屯區",
      state: "已出貨",
      buildDate: new Date(),
      upDate: new Date(),
    },
  ];
  const [newData, setNewData] = useState({
    shipmentStateArray: [
      {
        label: "全部",
        value: "全部",
      },
      {
        label: "等待付款",
        value: "等待付款",
      },
      {
        label: "待出貨",
        value: "待出貨",
      },
      {
        label: "已出貨",
        value: "已出貨",
      },
    ],
    shipmentStateObject: {
      label: "全部",
      value: "全部",
    },
    productArray: [
      {
        label: "全部",
        value: "全部",
      },
    ],
    productObject: {
      label: "全部",
      value: "全部",
    },
    deliverArray: [
      {
        label: "全部",
        value: "全部",
      },
      {
        label: "一般宅配",
        value: "一般宅配",
      },
      {
        label: "超商",
        value: "超商",
      },
    ],
    deliverObject: {
      label: "全部",
      value: "全部",
    },
    sortArray: [
      {
        label: "從新到舊",
        value: "從新到舊",
      },
      {
        label: "從舊到新",
        value: "從舊到新",
      },
    ],
    sortObject: {
      label: "從新到舊",
      value: "從新到舊",
    },
    selectPageArray: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
    selectPageObject: {
      label: "10",
      value: "10",
    },
    searchTerm: "",
    startDate: null,
    endDate: null,
    page: 1,
    pageMax: null,
    pageSize: 10,
    pageArray: [],
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  function _gotoOrder(orderId) {
    // return history.push(`/console/order/${orderId}`);
  }

  function _delete(value) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        //onClick: () => hideOrder({ variables: { id: Number(value.id) } }),
      },
      { text: "取消", type: "outlined" },
    ]);
  }
  return (
    <>
      <CardContent>
        <Typography>出貨總數：</Typography>
        <Typography>貨物總數：</Typography>
      </CardContent>
      <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <Select
              label={"出貨狀態"}
              items={newData.shipmentStateArray}
              value={newData.shipmentStateObject}
              onChange={(e, value) => {
                changeData("shipmentStateObject", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"商品"}
              items={newData.productArray}
              value={newData.productObject}
              onChange={(e, value) => {
                changeData("productObject", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"送貨方式"}
              items={newData.deliverArray}
              value={newData.deliverObject}
              onChange={(e, value) => {
                changeData("deliverObject", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextInput
              label={"搜尋"}
              value={newData.searchTerm}
              onChange={(value) => {
                changeData("searchTerm", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"排序"}
              items={newData.sortArray}
              value={newData.sortObject}
              onChange={(e, value) => {
                changeData("sortObject", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"每頁顯示"}
              items={newData.selectPageArray}
              value={newData.selectPageObject}
              onChange={(e, value) => {
                changeData("selectPageObject", value, true);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DateInput
              label={"開始時間"}
              inputVariant="outlined"
              format="yyyy年MM月dd日"
              value={newData.startDate}
              onChange={(v) => changeData("startDate", v, true)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DateInput
              label={"結束時間"}
              inputVariant="outlined"
              format="yyyy年MM月dd日"
              minDate={newData.startDate}
              value={newData.endDate}
              onChange={(v) => changeData("endDate", v, true)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Table
        paper
        data={datatest}
        header={[
          "訂單編號",
          "用戶名稱",
          "手機號碼",
          "送貨方式",
          "收貨地點",
          "出貨狀態",
          "建立日期",
          "更新日期",
          "操作",
        ]}
        tableWidth={2}
        columns={[
          "orderId",
          "name",
          "phone",
          "way",
          "place",
          "state",
          (item) => moment(item.buildDate).format("YYYY/MM/DD"),
          (item) => moment(item.upDate).format("YYYY/MM/DD"),
          (item) => {
            return (
              <Button
                variant="outlined"
                color="primary"
                onPress={(event) => {
                  event.stopPropagation();
                  _delete(item);
                }}
              >
                刪除
              </Button>
            );
          },
        ]}
        onPress={(item) => history.push(`/console/order/test`)}
      />
      <PageItem
        data={newData.pageArray}
        page={newData.page}
        onChangePage={(e) => changeData("page", e, true)}
      />
      <Box display="flex" justifyContent="center" margin="1em">
        <Button
          variant="contained"
          color="primary"
          //disabled={totalMemberCount.current === 0}
          // onPress={() => {
          //   setExcelLoading(true);
          //   getMembers({
          //     variables: {
          //       searchTerm,
          //       nationality,
          //       tier,
          //       latest,
          //       pageSize:
          //         totalMemberCount.current === 0
          //           ? 10
          //           : totalMemberCount.current,
          //       page: 1,
          //     },
          //   });
          // }}
          onPress={() => _gotoOrder(1)}
        >
          下載出貨清單
        </Button>
      </Box>
    </>
  );
}

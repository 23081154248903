import React, { useEffect, useMemo } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";

import emptyArray from "../utils/emptyArray";
import { getCounty, getDistrict } from "../utils/countiesDistricts";
import { shipmentSubTypeEnum } from "../utils/localData";

const GET_ORDER = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      serialNumber
      user {
        id
        fullName
      }
      totalPrice
      shipmentFee
      shipmentAddress {
        id
        isCollection
        recipient
        recipientPhone
        shipmentMethod
        convenienceStore
        district
        subdistrict
        address
      }
      items {
        id
        product {
          id
          name
          brand
          capacity
          price
          freeShipping
        }
        unitPrice
        amount
      }
      payments {
        id
        serialNumber
        success
        tradeNumber
        paymentMethod
      }
    }
  }
`;

export default function ShipmentDetailPage() {
  const Alert = useAlert();
  const { id } = useParams();
  const useStyles = makeStyles((theme) => ({
    formBox: {
      width: "595px",
      maxHeight: "1754px",
      minHeight: "100vh",
      margin: "0 auto",
      //   paddingLeft: "200px",
    },
    shipmentInfo: {
      //   border: "1px solid red",
    },

    // table
    table: {
      width: "100%",
      overflowX: "hidden",
      "& th": {
        padding: "5px 3px",
        border: "1px solid grey",
      },
      "& td": {
        padding: "5px 3px",
        border: "1px solid grey",
      },
    },

    // tableSummary
    tableSummaryBox: {
      width: "200px",
      borderBottom: "1px solid grey",
    },
  }));
  const classes = useStyles();

  function print_action() {
    var restorepage = document.body.innerHTML;
    var printcontent = document.getElementById("printable").innerHTML;
    document.body.innerHTML = printcontent;
    window.print();
    document.body.innerHTML = restorepage;
    window.location.reload();
  }

  const [getOrder, { data, loading }] = useLazyQuery(GET_ORDER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getOrder({ variables: { id: Number(id) } });
    }
  }, [id]);

  const productsTotalPrice = useMemo(() => {
    let productsPrice = 0;
    if (Boolean(data)) {
      data.order.items.forEach((item) => {
        productsPrice += item.amount * item.unitPrice;
      });
    }
    return productsPrice;
  }, [data]);

  const payments = useMemo(() => {
    if (data && emptyArray(data.order.payments).length > 0) {
      return data.order.payments[data.order.payments.length - 1];
    }
  }, [data]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    //table
    return (
      <Box className={classes.formBox}>
        <Button
          color="primary"
          variant="contained"
          className="no-print"
          onClick={print_action}
        >
          列印
        </Button>
        <Box my={4} id="printable">
          {/* Title */}
          <Box mb={4}>
            <Typography
              align="center"
              variant="h6"
              style={{ fontWeight: "bold" }}
            >
              香水人生 &ensp; 出貨單
            </Typography>
          </Box>
          {/* ANCHOR shipmentInfo */}
          <Grid container justifyContent="center" spacing={2}>
            {/* 訂單單號： */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                訂單單號：{data.order.serialNumber}
              </Typography>
            </Grid>
            {/* 付款單號 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                付款單號：{payments?.tradeNumber}
              </Typography>
            </Grid>
            {/* 購買人： */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                購買人：{data.order.user.fullName}
              </Typography>
            </Grid>
            {/* 付款方式 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                付款方式：
                {data.order.shipmentAddress?.isCollection
                  ? "貨到付款"
                  : payments
                  ? payments.paymentMethod === "CREDIT_CARD"
                    ? "信用卡"
                    : "ATM"
                  : ""}
              </Typography>
            </Grid>
            {/* 收件人 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                收件人：{data.order.shipmentAddress.recipient}
              </Typography>
            </Grid>
            {/* 連絡電話 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                收件人電話：{data.order.shipmentAddress.recipientPhone}
              </Typography>
            </Grid>
            {/* 運送方式 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                運送方式：
                {data.order.shipmentAddress.shipmentMethod ===
                "CONVENIENCE_STORE"
                  ? "超商取貨"
                  : "宅配"}
              </Typography>
            </Grid>
            {/* 出貨地址 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                出貨地址：
                {data.order.shipmentAddress?.shipmentMethod ===
                "CONVENIENCE_STORE"
                  ? `${
                      data.order.shipmentAddress?.shipmentSubType
                        ? shipmentSubTypeEnum[
                            data.order.shipmentAddress?.shipmentSubType
                          ]
                        : ""
                    }${data.order.shipmentAddress?.convenienceStore}`
                  : data.order.shipmentAddress?.district
                  ? getCounty(data.order.shipmentAddress?.district) +
                    getDistrict(
                      data.order.shipmentAddress?.district,
                      data.order.shipmentAddress?.subdistrict
                    ) +
                    data.order.shipmentAddress?.address
                  : ""}
              </Typography>
            </Grid>
            {/* 備註 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">備註：{data.order.note}</Typography>
            </Grid>
            {/* 小幫手的備註 */}
            <Grid item xs={5} className={classes.shipmentInfo}>
              <Typography variant="body2">
                {/* 備註：
                <br />
                {fakeData.helper}
                <br />
                {fakeData.helperNote} */}
              </Typography>
            </Grid>
          </Grid>

          {/* ANCHOR shipment Table */}
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>項次</TableCell>
                    {/* <TableCell>訂單編號</TableCell> */}
                    <TableCell>商品名稱</TableCell>
                    <TableCell>品牌</TableCell>
                    <TableCell>容量</TableCell>
                    <TableCell>數量</TableCell>
                    <TableCell>單價</TableCell>
                    {/* <TableCell>折扣</TableCell> */}
                    <TableCell>小計</TableCell>
                    {/* <TableCell>備註</TableCell> */}
                    {/* <TableCell>料號</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.order.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{fakeTableData.serialNumber}</TableCell> */}
                      <TableCell>{item.product.name}</TableCell>
                      <TableCell>{item.product.brand}</TableCell>
                      <TableCell>{item.product.capacity}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{item.unitPrice}</TableCell>
                      {/* <TableCell>{fakeTableData.discount}</TableCell> */}
                      <TableCell>{item.amount * item.unitPrice}</TableCell>
                      {/* <TableCell>{fakeTableData.note}</TableCell> */}
                      {/* <TableCell>{fakeTableData.order}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* 最後總結 */}
            <Grid
              container
              style={{
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {/* 商品總額 */}
              <Grid container item className={classes.tableSummaryBox}>
                {/* 左半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption"> 商品總額：</Typography>
                </Grid>
                {/* 右半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption" component="div" align="right">
                    {productsTotalPrice}
                  </Typography>
                </Grid>
              </Grid>
              {/* ---- */}
              {/* 運費 */}
              <Grid container item className={classes.tableSummaryBox}>
                {/* 左半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption"> 運費：</Typography>
                </Grid>
                {/* 右半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption" component="div" align="right">
                    {data.order.shipmentFee
                      ? data.order.shipmentFee
                      : data.order.shipmentAddress.shipmentMethod ===
                        "CONVENIENCE_STORE"
                      ? 60
                      : 80}
                  </Typography>
                </Grid>
              </Grid>
              {/* ---- */}
              {/* 運費 */}
              <Grid container item className={classes.tableSummaryBox}>
                {/* 左半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption"> 折抵：</Typography>
                </Grid>
                {/* 右半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption" component="div" align="right">
                    {data.order.totalPrice -
                      productsTotalPrice -
                      (data.order.shipmentFee
                        ? data.order.shipmentFee
                        : data.order.shipmentAddress.shipmentMethod ===
                          "CONVENIENCE_STORE"
                        ? 60
                        : 80)}
                  </Typography>
                </Grid>
              </Grid>
              {/* ---- */}
              {/* 總計 */}
              <Grid container item className={classes.tableSummaryBox}>
                {/* 左半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption"> 總計：</Typography>
                </Grid>
                {/* 右半邊 */}
                <Grid item xs={6}>
                  <Typography variant="caption" component="div" align="right">
                    {data.order.totalPrice}
                  </Typography>
                </Grid>
              </Grid>
              {/* ---- */}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}

import React from "react";
import { Grid as MuiGrid } from "@material-ui/core";

export default function Grid({
  children,
  direction,
  container,
  item,
  spacing,
  ...props
}) {
  return (
    <MuiGrid
      direction={direction}
      container={container}
      item={item}
      spacing={spacing}
      {...props}
    >
      {children}
    </MuiGrid>
  );
}

import React, { useState, memo } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Checkbox,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form";

import Table from "../../components/Table";
import { Card, CardContent, CardActions } from "../../components/Card";
import { useAlert } from "../../components/Alert";
import { Select, TextInput } from "../../components/Form";
import LoadingModal from "../../components/LoadingModal";

import {
  openChooseAddUserPointSwitch,
  closeChooseAddUserPointSwitch,
} from "../../redux/console";

const GET_USERS = gql`
  query users(
    $searchTerm: String
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    users(
      searchTerm: $searchTerm
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        username
        mobile
        email
        fullName
        nickname
        dateOfBirth
        admin
        createdAt
      }
    }
  }
`;

const BATCHADD_USERPOINT = gql`
  mutation batchAddUserPoint($userIds: [Int!]!, $point: Int!) {
    batchAddUserPoint(userIds: $userIds, point: $point) {
      success
      message
    }
  }
`;

export default function MemberListPage({ onEditSelect = () => {} }) {
  const Alert = useAlert();

  const [newData, setNewData] = useState({
    latestArray: [
      {
        label: "從新到舊",
        value: true,
      },
      {
        label: "從舊到新",
        value: false,
      },
    ],
    latestObject: {
      label: "從新到舊",
      value: true,
    },
    pageSizeArray: [
      {
        label: "10",
        value: 10,
      },
      {
        label: "50",
        value: 50,
      },
      {
        label: "100",
        value: 100,
      },
    ],
    pageSizeObject: {
      label: "10",
      value: 10,
    },
    searchTerm: "",
    page: 1,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const batchAddUserPointForm = useForm({
    defaultValues: {
      userIds: [],
      point: "",
    },
  });

  const { data, loading } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      searchTerm: newData.searchTerm,
      latest: newData.latestObject.value,
      pageSize: newData.pageSizeObject.value,
      page: newData.page,
    },
    onError() {
      return null;
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <CardContent>
          <Typography>會員人數：{data.users.count}</Typography>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextInput
                label={"搜尋"}
                value={newData.searchTerm}
                onChange={(value) => changeData("searchTerm", value, true)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label={"排序"}
                items={newData.latestArray}
                value={newData.latestObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("latestObject", value, true);
                  } else {
                    changeData(
                      "latestObject",
                      {
                        label: "從新到舊",
                        value: true,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label={"每頁顯示"}
                items={newData.pageSizeArray}
                value={newData.pageSizeObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("pageSizeObject", value, true);
                  } else {
                    changeData(
                      "pageSizeObject",
                      {
                        label: "10",
                        value: 10,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <FormProvider {...batchAddUserPointForm}>
          <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
            <AddUserPointSwitch />
          </Box>
          <CardContent>
            <Table
              data={data.users.contents}
              header={[
                "選取",
                "用戶名稱",
                "帳號",
                "生日",
                "手機號碼",
                "信箱",
                "身分",
                "註冊時間",
              ]}
              tableWidth={2}
              columns={[
                (item) => <SelectComponent id={item.id} />,
                "fullName",
                "username",
                (item) => moment(item.dateOfBirth).format("YYYY-MM-DD"),
                "mobile",
                "email",
                (item) => (item.admin ? "管理員" : "一般會員"),
                (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              ]}
              onPress={(item) => onEditSelect(item)}
            />
          </CardContent>
        </FormProvider>
        <CardActions>
          <Grid container justifyContent="center">
            <Pagination
              count={data.users.pageCount}
              size="large"
              color="primary"
              page={newData.page}
              onChange={(e, n) => changeData("page", n, true)}
            />
          </Grid>
        </CardActions>
      </Card>
    );
  } else {
    return null;
  }
}

const AddUserPointSwitch = memo(function AddUserPointSwitch() {
  const { alert, notify } = useAlert();
  const { control, reset, getValues } = useFormContext();
  const dispatch = useDispatch();
  const chooseAddUserPointSwitch = useSelector(
    (state) => state.console.chooseAddUserPointSwitch
  );

  const [batchAddUserPoint, { loading: batchAddUserPointLoading }] =
    useMutation(BATCHADD_USERPOINT, {
      onCompleted({ batchAddUserPoint }) {
        if (batchAddUserPoint.success) {
          reset();
          dispatch(closeChooseAddUserPointSwitch());
          return notify("贈送成功");
        } else if (batchAddUserPoint.message) {
          return notify(batchAddUserPoint.message);
        }
      },
    });

  return (
    <Grid container spacing={1}>
      <LoadingModal loading={batchAddUserPointLoading} />
      {chooseAddUserPointSwitch ? (
        <>
          <Grid item xs={12} sm={3}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box style={{ display: "flex", flex: 2 }}>
                <Controller
                  control={control}
                  name="point"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label={"點數"}
                      value={value}
                      onChange={onChange}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box style={{ display: "flex", flex: 3, marginLeft: 5 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const userIds = getValues("userIds");
                    const point = getValues("point");
                    if (!userIds[0]) return notify("請選擇會員！");
                    if (!point) return notify("點數不可為空！");
                    if (isNaN(point)) return notify("點數必須為數字！");
                    if (point == 0) return notify("點數不可為0！");
                    alert("", "確定贈送點數？", [
                      {
                        text: "確定",
                        onClick: () =>
                          batchAddUserPoint({
                            variables: { userIds, point: Number(point) },
                          }),
                        type: "contained",
                      },
                      {
                        text: "取消",
                        type: "outlined",
                      },
                    ]);
                  }}
                  fullWidth
                >
                  確定贈點
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                reset();
                dispatch(closeChooseAddUserPointSwitch());
              }}
              fullWidth
            >
              取消選擇
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(openChooseAddUserPointSwitch());
            }}
            fullWidth
          >
            選擇贈送點數會員
          </Button>
        </Grid>
      )}
    </Grid>
  );
});

const SelectComponent = memo(function SelectComponent({ id }) {
  const { control, watch } = useFormContext();
  const userIds = watch("userIds");
  const chooseAddUserPointSwitch = useSelector(
    (state) => state.console.chooseAddUserPointSwitch
  );
  if (chooseAddUserPointSwitch) {
    const has = userIds.find((item) => item === id);
    return (
      <Controller
        control={control}
        name="userIds"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              if (has) {
                onChange(value.filter((e) => e !== id));
              } else {
                onChange([...value, id]);
              }
            }}
            checked={has}
            color="primary"
          />
        )}
      />
    );
  } else {
    return null;
  }
});

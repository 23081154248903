import { createSlice } from "@reduxjs/toolkit";

// ANCHOR 預設值
const initialState = {
  // "後台優先順序點選ID"
  consolePriorityId: undefined,
  // "後台選擇順序selsect浮層"
  consolePriorityOpen: false,
  // "後台調整順序完刷新"
  consolePriorityRefetch: false,
};
// ANCHOR 主要組件
export const consolePrioritySlice = createSlice({
  name: "consolePriority",
  initialState,
  reducers: {
    setConsolePriorityId: (state, action) => {
      const id = action.payload;
      state.consolePriorityId = id;
    },
    cleanConsolePriorityId: (state) => {
      state.consolePriorityId = initialState["consolePriorityId"];
    },
    openConsolePriority: (state) => {
      state.consolePriorityOpen = true;
    },
    closeConsolePriority: (state, action) => {
      const value = action.payload;
      if (value === "mutation") {
        state.consolePriorityOpen = false;
        state.consolePriorityRefetch = true;
      } else {
        state.consolePriorityOpen = false;
      }
    },
    closeConsolePriorityRefetch: (state) => {
      state.consolePriorityRefetch = false;
    },
  },
});

export const {
  setConsolePriorityId,
  cleanConsolePriorityId,
  openConsolePriority,
  closeConsolePriority,
  closeConsolePriorityRefetch,
} = consolePrioritySlice.actions;

export const consolePrioritySliceState = (state) => state.consolePriority;

export default consolePrioritySlice.reducer;

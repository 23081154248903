import { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import { deepmerge } from "@material-ui/utils";

/**
 * @typedef {import('@mui/material').TextFieldProps} TextFieldProps
 */

const NumberTextField = forwardRef(
  /**
   * @param {TextFieldProps} param0
   * @returns {JSX.Element}
   */
  function NumberTextField(
    {
      inputProps,
      onChange,
      onKeyPress,
      allowAZero = false,
      maxCount,
      ...props
    },
    ref
  ) {
    return (
      <TextField
        {...props}
        ref={ref}
        onChange={(e) => {
          if (onChange) {
            // NOTE 起始值如果為0，下個非0數字會取代0
            if (
              e.target.value.length > 1 &&
              e.target.value.search(/^[0]/) === 0
            ) {
              e.target.value = e.target.value.replace("0", "");
              return onChange(e);
            }
            // NOTE 開頭不能為0
            if (allowAZero) {
              return onChange(e);
            } else if (!/^[0]/.test(e.target.value)) {
              return onChange(e);
            }
          }
        }}
        onKeyPress={(event) => {
          if (onKeyPress) {
            onKeyPress(event);
          }
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        inputProps={deepmerge(
          {
            min: 0,
            max: maxCount ? maxCount : 999999,
            type: "number",
            inputMode: "numeric",
            pattern: "[0-9]*",
            sx: {
              "&[type=number]": {
                MozAppearance: "textfield",
              },
              "&::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            },
          },
          inputProps
        )}
      />
    );
  }
);
export default NumberTextField;

import { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// ANCHOR 免運金額
const GET_FREESHIPPINGRULE = gql`
  query freeShippingRule {
    freeShippingRule {
      id
      price
    }
  }
`;

export default function useFreeShippingRule() {
  const { data } = useQuery(GET_FREESHIPPINGRULE);
  const freeShippingRuleData = useMemo(() => {
    if (data) {
      const { freeShippingRule } = data;
      return freeShippingRule.price;
    }
    return 0;
  }, [data]);
  return freeShippingRuleData;
}

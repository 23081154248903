import React, {
  useState,
  createElement,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import {
  AppBar,
  Box,
  IconButton,
  Drawer,
  useTheme,
  makeStyles,
  useMediaQuery,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Fab,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory, useLocation } from "react-router-dom";

import useLayoutSettings from "./useLayoutSettings";

const drawerWidth = 240;
const barHeight = 64;

export default function Layout({ children }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const layoutSettings = useLayoutSettings().drawer.items;
  const firstRender = useRef(true);
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [openMenu, setOpenMenu] = useState(true);
  const useStyles = makeStyles({
    content: {
      flexGrow: 1,
      width: "100%",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: isTableSize ? 0 : -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 0,
    },
    appBar: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${isTableSize ? 0 : drawerWidth}px)`,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  });
  const classes = useStyles();
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setOpenMenu(false);
    }
  }, [isTableSize]);
  function getTitle(items) {
    for (let item of items) {
      if (item.route === pathname) {
        return item.title;
      } else if (Array.isArray(item.subItems)) {
        const temp = getTitle(item.subItems);
        if (temp.length > 0) {
          return temp;
        }
      }
    }
    return "";
  }
  return (
    <Box
      display="flex"
      height="100%"
      minHeight="100vh"
      bgcolor={theme.palette.grey["100"]}
    >
      {createElement(isTableSize ? FloatDrawer : SlideBar, {
        style: { border: "2px solid blue" },
        open: openMenu,
        onClose: () => setOpenMenu(false),
      })}
      <Box
        display="flex"
        flexDirection="column"
        className={clsx(classes.content, {
          [classes.contentShift]: !isTableSize && openMenu,
        })}
      >
        <AppBar
          style={{
            // NOTE 原本initial
            right: "0",
            backgroundColor: theme.palette.common.white,
          }}
          color="default"
          elevation={0}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: openMenu,
          })}
        >
          <Box
            height={barHeight}
            display="flex"
            alignItems="center"
            padding={`0 ${theme.spacing(3)}px`}
          >
            <IconButton edge="start" onClick={() => setOpenMenu((e) => !e)}>
              {createElement(openMenu ? MenuOpenIcon : MenuIcon)}
            </IconButton>
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6">{getTitle(layoutSettings)}</Typography>
            </Box>
            <MoreButtons />
          </Box>
        </AppBar>
        <Box height={barHeight} />
        <Box
          width="100%"
          height={`calc(100vh - ${barHeight}`}
          style={{ padding: `${theme.spacing(1)}px` }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
function SlideBar({ open, onClose = () => {} }) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: "relative",
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      borderWidth: 0,
    },
  }));
  const classes = useStyles();
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <DrawerItem onClose={onClose} type="slide" />
    </Drawer>
  );
}
function FloatDrawer({ open, onClose = () => {} }) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: "relative",
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      borderWidth: 0,
    },
    closeButton: {
      position: "fixed",
      left: drawerWidth + theme.spacing(1),
      top: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  return (
    <Drawer
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <DrawerItem onClose={onClose} type="float" />
      <Fab color="secondary" className={classes.closeButton} onClick={onClose}>
        <MenuOpenIcon />
      </Fab>
    </Drawer>
  );
}
function DrawerItem({ onClose = () => {}, type }) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const firstRender = useRef(true);
  const [openCollapse, setOpenCollapse] = useState(null);
  const url = pathname + search;
  function sameRoute(value) {
    return url === value;
  }
  const layoutSettings = useLayoutSettings().drawer;
  const useStyles = makeStyles((theme) => ({
    list: {
      color: theme.palette.common.white,
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
    listItem: {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(1)}px)`,
      "&.Mui-selected": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  }));
  const classes = useStyles();
  function autoOpenMenu() {
    let newIndex = null;
    const layoutSettingsItems = layoutSettings.items;
    for (let i = 0, len = layoutSettingsItems.length; i < len; i++) {
      const element = layoutSettingsItems[i];
      if (element.subItems) {
        if (element.subItems.find((item) => item.route === url)) {
          newIndex = i;
          break;
        }
      }
    }
    setOpenCollapse(newIndex);
  }
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (type === "float") {
        onClose();
      }
    }
    autoOpenMenu();
  }, [url]);
  return (
    <Box width="100%" height="100%">
      <Box
        display="flex"
        alignItems="center"
        height={64}
        style={{ backgroundColor: "#fff" }}
      >
        <img
          src={layoutSettings.logo}
          style={{ height: "95%", width: "100%", objectFit: "contain" }}
        />
      </Box>
      <List className={classes.list}>
        {layoutSettings.items.map((item, index) => (
          <React.Fragment key={item.title + item.route}>
            <ListItem
              button={!sameRoute(item.route)}
              selected={sameRoute(item.route)}
              classes={{ root: classes.listItem }}
              onClick={() => {
                if (item.subItems) {
                  if (typeof openCollapse === "number") {
                    if (openCollapse === index) {
                      return setOpenCollapse(null);
                    } else {
                      return setOpenCollapse(index);
                    }
                  } else {
                    return setOpenCollapse(index);
                  }
                } else {
                  if (sameRoute(item.route)) {
                    return null;
                  } else {
                    return history.push(item.route);
                  }
                }
              }}
            >
              <ListItemText primary={item.title} />
              {Boolean(item.subItems) && (
                <>
                  {createElement(
                    openCollapse === index ? ExpandLessIcon : ExpandMoreIcon
                  )}
                </>
              )}
            </ListItem>
            {Boolean(item.subItems) && (
              <Collapse
                in={openCollapse === index}
                timeout="auto"
                unmountOnExit
              >
                {item.subItems.map((item2) => (
                  <ListItem
                    key={item2.title + item2.route}
                    button={!sameRoute(item2.route)}
                    selected={sameRoute(item2.route)}
                    onClick={() => history.push(item2.route)}
                    classes={{ root: classes.listItem }}
                  >
                    <ListItemText primary={`\u2003${item2.title}`} />
                  </ListItem>
                ))}
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}
function MoreButtons() {
  const layoutSettings = useLayoutSettings().navbar.buttons;
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(null);
  const showButton = layoutSettings.length > 0;
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    icon: { width: 24, height: 24 },
  });
  const classes = useStyles();
  return (
    <Box
      minWidth={48 * (isTableSize ? 1 : 3)}
      display="flex"
      justifyContent="flex-end"
    >
      {isTableSize ? (
        <>
          {showButton ? (
            <>
              <Box display="flex">
                <IconButton onClick={(e) => setOpenMenu(e.currentTarget)}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Menu
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={() => setOpenMenu(null)}
              >
                {layoutSettings.map((item) => (
                  <MenuItem
                    key={item.title}
                    onClick={() => {
                      setOpenMenu(null);
                      item.onPress();
                    }}
                  >
                    <ListItemIcon>
                      {createElement(item.icon, {
                        className: classes.icon,
                      })}
                    </ListItemIcon>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Box display="flex">
            {showButton
              ? layoutSettings.map((item, index) => (
                  <IconButton onClick={item.onPress} key={item.title + index}>
                    {item.icon ? (
                      createElement(item.icon, {
                        className: classes.icon,
                      })
                    ) : (
                      <Box width={24} height={24} />
                    )}
                  </IconButton>
                ))
              : null}
          </Box>
        </>
      )}
    </Box>
  );
}

import React from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";

import Table from "../../components/Table";

const GET_POINTRECORDS = gql`
  query pointRecords($userId: Int) {
    pointRecords(userId: $userId) {
      count
      contents {
        order {
          id
          serialNumber
        }
        reason
        point
        createdAt
      }
    }
  }
`;

export default function MemberPointRecords({ id }) {
  const { data, loading } = useQuery(GET_POINTRECORDS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: id,
    },
  });
  return (
    <Card>
      <CardContent>
        <Typography>點數紀錄數量:{data?.pointRecords.count}</Typography>
      </CardContent>
      <CardContent>
        {loading ? (
          <Box
            style={{
              display: "flex",
              height: "450px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Table
            data={data ? data.pointRecords.contents : []}
            header={["點數", "訂單編號", "點數原由", "建立時間"]}
            tableWidth={2}
            columns={[
              "point",
              (item) => item.order?.serialNumber,
              "reason",
              (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
            ]}
          />
        )}
      </CardContent>
    </Card>
  );
}

import { createSlice } from "@reduxjs/toolkit";

// ANCHOR 預設值
const initialState = {
  // "廣告資料異動中"
  advertisementChanging: true,
};
// ANCHOR 主要組件
export const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    setStartAdvertisementChanging: (state) => {
      state.advertisementChanging = true;
    },
    setEndAdvertisementChanging: (state) => {
      state.advertisementChanging = false;
    },
  },
});

export const { setStartAdvertisementChanging, setEndAdvertisementChanging } =
  advertisementSlice.actions;

export const advertisementSliceState = (state) => state.advertisement;

export default advertisementSlice.reducer;

import React from "react";
import {
  makeStyles,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function SelectComponent({
  label,
  error,
  helperText,
  listArray = [],
  emptyLabel,
  ...props
}) {
  const useStyles = makeStyles({
    selectMenuItemSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <label style={{ fontSize: "0.8em" }}>{label}</label>
      <br />
      <FormControl fullWidth error={typeof error === "boolean" ? error : false}>
        <Select
          {...props}
          className={classes.selectMenuItemSelectBox}
          displayEmpty
        >
          {emptyLabel && (
            <MenuItem
              className={classes.selectMenuItemSelectBox}
              value=""
              disabled
            >
              {emptyLabel}
            </MenuItem>
          )}
          {listArray.map((item) => (
            <MenuItem
              className={classes.selectMenuItemSelectBox}
              value={item.value}
              key={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useForm, Controller, useWatch } from "react-hook-form";
import gql from "graphql-tag";
import moment from "moment-timezone";

import { useAlert } from "../../components/Alert";
import { Card, CardContent, CardActions } from "../../components/Card";
import LoadingModal from "../../components/LoadingModal";
import { Select as AutocompleteSelect } from "../../components/Form";

const GET_EVENT = gql`
  query event($id: Int!) {
    event(id: $id) {
      id
      name
      content
      startedAt
      endedAt
      point
      price
      items {
        id
        product {
          id
          name
        }
      }
    }
  }
`;

const GET_PRODUCTS = gql`
  query products {
    products {
      contents {
        id
        name
      }
    }
  }
`;

const SAVE_EVENT = gql`
  mutation saveEvent($eventInput: EventInput!) {
    saveEvent(eventInput: $eventInput) {
      success
      message
    }
  }
`;

const InputLabel = ({ label }) => {
  const useStyles = makeStyles({
    inputLabel: {
      fontSize: "0.8em",
    },
  });
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.inputLabel}>
      {label}
    </Typography>
  );
};

const enabledArray = [
  {
    label: "啟用",
    value: true,
  },
  {
    label: "未啟用",
    value: false,
  },
];

export default function EventForm({ id }) {
  const { notify } = useAlert();
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      content: "",
      startedAt: null,
      endedAt: null,
      productId: [],
      price: "",
      point: "",
      enableObject: {
        label: "未啟用",
        value: false,
      },
    },
  });
  const [productsArray, setProductsArray] = useState([]);
  const startedAt = useWatch({ control, name: "startedAt" });

  const { loading: productsLoading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ products }) {
      if (products) {
        const newProductsArray = [];
        products.contents.forEach((item) => {
          newProductsArray.push({
            label: item.name,
            value: item.id,
          });
        });
        setProductsArray(newProductsArray);
      }
    },
    onError() {
      return null;
    },
  });

  const [getEvent, { loading }] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        const { name, content, startedAt, endedAt, items, price, point } =
          event;
        setValue("name", name);
        setValue("content", content);
        setValue("startedAt", startedAt);
        setValue("endedAt", endedAt);
        setValue(
          "productId",
          items.map((item) => ({
            label: item.product.name,
            value: item.product.id,
          }))
        );
        setValue("price", price);
        setValue("point", point);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id } });
    } else {
      setValue("name", "");
      setValue("content", "");
      setValue("startedAt", null);
      setValue("endedAt", null);
      setValue("productId", []);
      setValue("price", "");
      setValue("point", "");
      setValue("enableObject", {
        label: "未啟用",
        value: false,
      });
    }
  }, [id]);

  function _submit({
    name,
    content,
    startedAt,
    endedAt,
    productId,
    price,
    point,
    enableObject,
  }) {
    const eventInput = {
      id: id ? Number(id) : undefined,
      name,
      content,
      startedAt: moment(startedAt).tz("Asia/Taipei").second(0),
      endedAt: moment(endedAt).tz("Asia/Taipei").second(0),
      productId:
        productId && productId[0] ? productId.map((item) => item.value) : [],
      price: parseFloat(price),
      point: parseInt(point, 10),
      enable: enableObject.value,
    };
    saveEvent({ variables: { eventInput } });
  }

  const [saveEvent, { loading: saveEventLoading }] = useMutation(SAVE_EVENT, {
    onCompleted({ saveEvent }) {
      if (saveEvent.success) {
        getEvent({ variables: { id } });
        notify(`${id ? "儲存" : "更改"}成功`);
      } else if (saveEvent.message) {
        notify(saveEvent.message);
      }
    },
  });

  return (
    <Card>
      <LoadingModal loading={saveEventLoading} />
      <CardContent>
        <Typography>購物活動資料</Typography>
      </CardContent>
      {loading || productsLoading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: "活動名稱為必填選項",
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel label="活動名稱" />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="content"
                rules={{
                  required: "活動內容為必填選項",
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel label="活動內容" />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="productId"
                render={({ field: { value, onChange, ...otherField } }) => (
                  <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel label="選擇商品" />
                    <Select
                      {...otherField}
                      value={Array.isArray(value) ? value : []}
                      multiple
                      onChange={(e) => {
                        const hasALL = e.target.value.find(
                          (item) => item == "ALL"
                        );
                        if (Boolean(hasALL)) {
                          if (value && value.length == productsArray.length) {
                            onChange(null);
                          } else {
                            const newValue = Array.isArray(value)
                              ? [...value]
                              : [];
                            productsArray.forEach((item) =>
                              newValue.push(item)
                            );
                            const newNewValue = [...new Set(newValue)];
                            onChange(newNewValue);
                          }
                        } else {
                          if (e.target.value.length === 0) {
                            onChange(null);
                          } else {
                            onChange(e.target.value);
                          }
                        }
                      }}
                      renderValue={(selected) => {
                        return selected.length === productsArray.length
                          ? "全選"
                          : selected.map((item2) => item2.label).join(",") ||
                              null;
                      }}
                    >
                      <MenuItem disabled value={null}>
                        <ListItemText primary={"選擇商品"} />
                      </MenuItem>
                      <MenuItem value={"ALL"}>
                        <Checkbox
                          checked={
                            Array.isArray(value) &&
                            value.length === productsArray.length
                          }
                        />
                        <ListItemText primary={"全選"} />
                      </MenuItem>
                      {productsArray.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            checked={
                              Array.isArray(value) && value.indexOf(item) > -1
                            }
                          />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="startedAt"
                control={control}
                rules={{ required: "開始時間為必填" }}
                render={({
                  field: { ref, ...other },
                  fieldState: { error },
                }) => (
                  <>
                    <InputLabel label="開始時間" />
                    <DateTimePicker
                      {...other}
                      format="yyyy/MM/dd"
                      openTo="year"
                      error={error}
                      disablePast
                      helperText={error && error.message}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="endedAt"
                control={control}
                rules={{ required: "結束時間為必填" }}
                render={({
                  field: { ref, ...other },
                  fieldState: { error },
                }) => (
                  <>
                    <InputLabel label="結束時間" />
                    <DateTimePicker
                      {...other}
                      format="yyyy/MM/dd"
                      openTo="year"
                      error={error}
                      disabled={!startedAt}
                      helperText={error && error.message}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="price"
                control={control}
                rules={{ required: "消費金額為必填" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel label="消費金額" />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="point"
                control={control}
                rules={{ required: "贈送點數為必填" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel label="贈送點數" />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="enableObject"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <InputLabel label="狀態" />
                    <AutocompleteSelect
                      items={enabledArray}
                      value={value}
                      onChange={(e, value) => {
                        if (value) {
                          onChange(value);
                        } else {
                          onChange({
                            label: "未啟用",
                            value: false,
                          });
                        }
                      }}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}
      <CardActions>
        {!loading && !productsLoading && (
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(_submit)}
            >
              儲存
            </Button>
          </Grid>
        )}
      </CardActions>
    </Card>
  );
}

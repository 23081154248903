import React, { useState, useRef, useReducer, useEffect } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Pagination } from "@material-ui/lab";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { DatePicker } from "antd";
import moment from "moment-timezone";
import "moment/locale/zh-tw";

import Table from "../../components/Table";
import { Card, CardContent, CardActions } from "../../components/Card";
import { useAlert } from "../../components/Alert";
import { Select, TextInput } from "../../components/Form";
import {
  thousandsSeparator,
  halfShapeAndFullForm,
} from "../../components/utils";
import { excelDownload } from "../../components/ExcelDownload";
import LoadingFloating from "../../components/LoadingFloating";
import LoadingModal from "../../components/LoadingModal";
import TextFieldComponent from "../../components/TextFieldComponent";

import {
  orderStatusEnum,
  shipmentSubTypeEnum,
  paymentMethodEnum,
} from "../../utils/localData";
import {
  checkSpecialSymbolsAndNumber,
  checkMobile,
} from "../../utils/checkRegExp";
import { getCounty, getDistrict } from "../../utils/countiesDistricts";
import emptyArray from "../../utils/emptyArray";

// ANCHOR 訂單列表
const GET_ORDERS = gql`
  query orders(
    $status: OrderStatus
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    orders(
      status: $status
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        serialNumber
        user {
          id
          fullName
          mobile
        }
        totalPrice
        status
        note
        createdAt
        updatedAt
      }
    }
  }
`;

// ANCHOR EXCEL訂單列表
const GET_EXCEL_ORDERS = gql`
  query orders(
    $status: OrderStatus
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    orders(
      status: $status
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        serialNumber
        user {
          id
          fullName
          mobile
        }
        items {
          id
          product {
            id
            name
          }
          unitPrice
          amount
        }
        totalPrice
        shipmentFee
        usePoint
        usedCoupon {
          id
          discount
        }
        status
        note
        shipmentAddress {
          id
          isCollection
          shipmentMethod
          shipmentSubType
          convenienceStore
          district
          subdistrict
          address
        }
        payments {
          id
          paymentMethod
        }
        createdAt
        updatedAt
      }
    }
  }
`;

// ANCHOR 刪除訂單
const DELETE_ORDER = gql`
  mutation hideOrder($id: Int!) {
    hideOrder(id: $id) {
      success
      message
    }
  }
`;

// ANCHOR 寄件人資訊
const GET_LOGISTICSINFO = gql`
  query logisticsInfo {
    logisticsInfo {
      id
      senderName
      senderPhone
      senderCellPhone
    }
  }
`;

// ANCHOR 儲存寄件人資訊
const SAVE_LOGISTICSINFO = gql`
  mutation saveLogisticsInfo($logisticsInfoInput: LogisticsInfoInput!) {
    saveLogisticsInfo(logisticsInfoInput: $logisticsInfoInput) {
      success
      message
    }
  }
`;

// NOTE 關閉浮層
function _reducerDispatch(state, action) {
  switch (action.type) {
    case "open":
      return action.data;
    case "close":
      return null;
    default:
      return state;
  }
}
export default function OrderTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();
  const { RangePicker } = DatePicker;
  const history = useHistory();

  const [newData, setNewData] = useState({
    orderStateArray: [
      {
        label: "全部",
        value: null,
      },
      {
        label: "等待付款",
        value: "WAITING_FOR_PAYMENT",
      },
      {
        label: "待出貨",
        value: "READY_FOR_SHIPMENT",
      },
      {
        label: "已出貨",
        value: "SHIPPED",
      },
      {
        label: "已取消",
        value: "CANCELLED",
      },
    ],
    orderStateObject: {
      label: "全部",
      value: null,
    },
    productObject: {
      label: "全部",
      value: null,
    },
    deliverArray: [
      {
        label: "全部",
        value: null,
      },
      {
        label: "一般宅配",
        value: "HOME_DELIVERY",
      },
      {
        label: "超商取貨",
        value: "CONVENIENCE_STORE",
      },
    ],
    deliverObject: {
      label: "全部",
      value: null,
    },
    sortArray: [
      {
        label: "從新到舊",
        value: true,
      },
      {
        label: "從舊到新",
        value: false,
      },
    ],
    sortObject: {
      label: "從新到舊",
      value: true,
    },
    selectPageArray: [
      {
        label: "10",
        value: 10,
      },
      {
        label: "20",
        value: 20,
      },
      {
        label: "25",
        value: 25,
      },
      {
        label: "50",
        value: 50,
      },
      {
        label: "100",
        value: 100,
      },
    ],
    selectPageObject: {
      label: "10",
      value: 10,
    },
    searchTerm: "",
    startTime: null,
    endTime: null,
    page: 1,
  });
  const totalOrderCount = useRef(0);

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const [logisticsInfoDialog, logisticsInfoDialogDispatch] = useReducer(
    _reducerDispatch,
    null
  );
  function _logisticsInfoDialogClose() {
    logisticsInfoDialogDispatch({ type: "close" });
  }

  const { data, refetch, loading } = useQuery(GET_ORDERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      status: newData.orderStateObject && newData.orderStateObject.value,
      searchTerm: newData.searchTerm,
      startTime: newData.startTime,
      endTime: newData.endTime,
      latest: newData.sortObject && newData.sortObject.value,
      pageSize: newData.selectPageObject && newData.selectPageObject.value,
      page: newData.page,
    },
    onCompleted({ orders }) {
      if (orders) {
        totalOrderCount.current = orders.count;
        const twentySevenConsoleOrderSelectStoreGetValues =
          localStorage.getItem("@twenty-sevenConsoleOrderSelectStoreGetValues");
        if (twentySevenConsoleOrderSelectStoreGetValues) {
          const { fullName, orderId } = JSON.parse(
            twentySevenConsoleOrderSelectStoreGetValues
          );
          onEditSelect({ id: orderId, user: { fullName: fullName } });
        }
      }
    },
    onError() {
      return null;
    },
  });

  const [getOrders, { loading: ordersExcelLoading }] = useLazyQuery(
    GET_EXCEL_ORDERS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: async ({ orders }) => {
        if (orders) {
          const excelData = [];
          const entozh = {
            serialNumber: "訂單編號",
            fullName: "用戶名稱",
            mobile: "手機號碼",
            address: "收貨地址",
            paymentMethod: "付款方式",
            status: "狀態",
            productDetails: "商品名稱",
            unitPrice: "商品單價",
            amount: "數量",
            productPrice: "商品總額",
            shipmentFee: "運費",
            discount: "折扣",
            totalPrice: "總計",
            note: "備註",
            createdAt: "建立日期",
          };
          orders.contents.forEach((item) => {
            if (item.items.length === 1) {
              excelData.push({
                serialNumber: item.serialNumber,
                fullName: item.user.fullName,
                mobile: item.user.mobile,
                address:
                  item.shipmentAddress?.shipmentMethod === "CONVENIENCE_STORE"
                    ? `${
                        item.shipmentAddress?.shipmentSubType
                          ? shipmentSubTypeEnum[
                              item.shipmentAddress?.shipmentSubType
                            ]
                          : ""
                      }${item.shipmentAddress?.convenienceStore}`
                    : item.shipmentAddress?.district
                    ? getCounty(item.shipmentAddress?.district) +
                      getDistrict(
                        item.shipmentAddress?.district,
                        item.shipmentAddress?.subdistrict
                      ) +
                      item.shipmentAddress?.address
                    : "",
                paymentMethod: item.shipmentAddress.isCollection
                  ? "貨到付款"
                  : emptyArray(item.payments).length > 0
                  ? paymentMethodEnum[
                      item.payments[item.payments.length - 1].paymentMethod
                    ]
                  : "",
                status: orderStatusEnum[item.status],
                productDetails: item.items[0].product.name,
                unitPrice: item.items[0].unitPrice,
                amount: item.items[0].amount,
                productPrice: thousandsSeparator(
                  item.totalPrice +
                    (Math.floor(item.usePoint / 50) +
                      (item.usedCoupon ? item.usedCoupon.discount : 0))
                ),
                shipmentFee: thousandsSeparator(item.shipmentFee),
                discount: thousandsSeparator(
                  Math.floor(item.usePoint / 50) +
                    (item.usedCoupon ? item.usedCoupon.discount : 0)
                ),
                totalPrice: thousandsSeparator(
                  item.totalPrice + item.shipmentFee
                ),
                note: item.note,
                createdAt: moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              });
            } else if (item.items.length > 1) {
              item.items.forEach((item2, index2) => {
                if (index2 === 0) {
                  excelData.push({
                    serialNumber: item.serialNumber,
                    fullName: item.user.fullName,
                    mobile: item.user.mobile,
                    address:
                      item.shipmentAddress?.shipmentMethod ===
                      "CONVENIENCE_STORE"
                        ? `${
                            item.shipmentAddress?.shipmentSubType
                              ? shipmentSubTypeEnum[
                                  item.shipmentAddress?.shipmentSubType
                                ]
                              : ""
                          }${item.shipmentAddress?.convenienceStore}`
                        : item.shipmentAddress?.district
                        ? getCounty(item.shipmentAddress?.district) +
                          getDistrict(
                            item.shipmentAddress?.district,
                            item.shipmentAddress?.subdistrict
                          ) +
                          item.shipmentAddress?.address
                        : "",
                    paymentMethod: item.shipmentAddress.isCollection
                      ? "貨到付款"
                      : emptyArray(item.payments).length > 0
                      ? paymentMethodEnum[
                          item.payments[item.payments.length - 1].paymentMethod
                        ]
                      : "",
                    status: orderStatusEnum[item.status],
                    productDetails: item2.product.name,
                    unitPrice: item2.unitPrice,
                    amount: item2.amount,
                    productPrice: thousandsSeparator(
                      item.totalPrice +
                        (Math.floor(item.usePoint / 50) +
                          (item.usedCoupon ? item.usedCoupon.discount : 0))
                    ),
                    shipmentFee: thousandsSeparator(item.shipmentFee),
                    discount: thousandsSeparator(
                      Math.floor(item.usePoint / 50) +
                        (item.usedCoupon ? item.usedCoupon.discount : 0)
                    ),
                    totalPrice: thousandsSeparator(
                      item.totalPrice + item.shipmentFee
                    ),
                    note: item.note,
                    createdAt: moment(item.createdAt).format(
                      "YYYY/MM/DD HH:mm"
                    ),
                  });
                } else {
                  excelData.push({
                    serialNumber: "",
                    fullName: "",
                    mobile: "",
                    address: "",
                    paymentMethod: "",
                    status: "",
                    productDetails: item2.product.name,
                    unitPrice: item2.unitPrice,
                    amount: item2.amount,
                    productPrice: "",
                    shipmentFee: "",
                    discount: "",
                    totalPrice: "",
                    note: "",
                    createdAt: "",
                  });
                }
              });
            }
          });
          await excelDownload(
            entozh,
            excelData,
            `${moment().format("YYYY-MM-DD")} 貳柒香水訂單清單`
          );
        }
      },
      onError() {
        return null;
      },
    }
  );

  const [hideOrder, { loading: hideOrderLoading }] = useMutation(DELETE_ORDER, {
    onCompleted({ hideOrder }) {
      if (hideOrder.success) {
        refetch();
        Alert.notify("刪除成功");
      } else if (hideOrder.message) {
        Alert.notify(hideOrder.message);
      }
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <LoadingFloating loading={ordersExcelLoading || hideOrderLoading} />
        <LogisticsInfoDialog
          clickData={logisticsInfoDialog}
          onClose={_logisticsInfoDialogClose}
        />
        <CardContent>
          <Typography>訂單數量：{data.orders.count}</Typography>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <Select
                label={"訂單狀態"}
                items={newData.orderStateArray}
                value={newData.orderStateObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("orderStateObject", value, true);
                  } else {
                    changeData(
                      "orderStateObject",
                      {
                        label: "全部",
                        value: null,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Select
                label={"運送方式"}
                items={newData.deliverArray}
                value={newData.deliverObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("deliverObject", value, true);
                  } else {
                    changeData(
                      "deliverObject",
                      {
                        label: "全部",
                        value: null,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <TextInput
                label={"搜尋"}
                value={newData.searchTerm}
                onChange={(value) => {
                  changeData("searchTerm", value, true);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label={"排序"}
                items={newData.sortArray}
                value={newData.sortObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("sortObject", value, true);
                  } else {
                    changeData(
                      "sortObject",
                      {
                        label: "從新到舊",
                        value: true,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label={"每頁顯示"}
                items={newData.selectPageArray}
                value={newData.selectPageObject}
                onChange={(e, value) => {
                  if (value) {
                    changeData("selectPageObject", value, true);
                  } else {
                    changeData(
                      "selectPageObject",
                      {
                        label: "10",
                        value: 10,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RangePicker
                locale="zh-tw"
                format="YYYY-MM-DD"
                value={[
                  newData.startTime ? moment(newData.startTime) : null,
                  newData.endTime ? moment(newData.endTime) : null,
                ]}
                style={{ height: 40, width: "100%" }}
                onChange={(value) => {
                  if (value) {
                    changeData(
                      "startTime",
                      moment(value[0])
                        .tz("Asia/Taipei")
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .toDate()
                    );
                    changeData(
                      "endTime",
                      moment(value[1])
                        .tz("Asia/Taipei")
                        .hour(23)
                        .minute(59)
                        .second(59)
                        .toDate(),
                      true
                    );
                  } else {
                    changeData("startTime", null);
                    changeData("endTime", null, true);
                  }
                }}
                placeholder={["開始時間", "結束時間"]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  logisticsInfoDialogDispatch({
                    type: "open",
                    data: { id: null },
                  });
                }}
                fullWidth
              >
                寄件人資訊設定
              </Button>
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Table
            paper
            data={data.orders.contents}
            header={[
              "訂單編號",
              "用戶名稱",
              "手機號碼",
              "總金額(含運費)",
              "狀態",
              "建立日期",
              "更新日期",
              "出貨單",
              "刪除",
            ]}
            tableWidth={3}
            columns={[
              "serialNumber",
              (item) => item.user.fullName,
              (item) => item.user.mobile,
              (item) => thousandsSeparator(item.totalPrice),
              (item) => orderStatusEnum[item.status],
              (item) => moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
              (item) => moment(item.updatedAt).format("YYYY-MM-DD HH:mm"),
              (item) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/console/shipment-detail/${item.id}`);
                  }}
                  disabled={item.status === "WAITING_FOR_PAYMENT"}
                >
                  匯出
                </Button>
              ),
              (item) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    Alert.alert("", "確定要刪除嗎？", [
                      {
                        text: "確認",
                        onClick: () =>
                          hideOrder({
                            variables: { id: Number(item.id) },
                          }),
                      },
                      { text: "取消", type: "outlined" },
                    ]);
                  }}
                >
                  刪除
                </Button>
              ),
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        </CardContent>
        <CardActions>
          <Grid container justifyContent="center">
            <Pagination
              count={data.orders.pageCount}
              size="large"
              color="primary"
              page={newData.page}
              onChange={(e, n) => changeData("page", n, true)}
            />
          </Grid>
        </CardActions>
        <Grid container item justify="center" style={{ margin: "1em" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              getOrders({
                variables: {
                  status:
                    newData.orderStateObject && newData.orderStateObject.value,
                  searchTerm: newData.searchTerm,
                  startTime: newData.startTime,
                  endTime: newData.endTime,
                  latest: newData.sortObject && newData.sortObject.value,
                  pageSize:
                    totalOrderCount.current === 0
                      ? 10
                      : totalOrderCount.current,
                  page: 1,
                },
              });
            }}
          >
            下載訂單清單
          </Button>
        </Grid>
      </Card>
    );
  } else {
    return null;
  }
}

function LogisticsInfoDialog({ clickData, onClose = () => {} }) {
  const { notify } = useAlert();
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      senderName: "",
      senderPhone: "",
      senderCellPhone: "",
    },
  });
  const [getLogisticsInfo, { loading: logisticsInfoLoading }] = useLazyQuery(
    GET_LOGISTICSINFO,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ logisticsInfo }) {
        if (logisticsInfo) {
          const { senderName, senderPhone, senderCellPhone } = logisticsInfo;
          setValue("senderName", senderName);
          setValue("senderPhone", senderPhone);
          setValue("senderCellPhone", senderCellPhone);
        }
      },
    }
  );
  useEffect(() => {
    if (Boolean(clickData)) {
      getLogisticsInfo();
    }
  }, [clickData]);
  const [saveLogisticsInfo, { loading: saveLogisticsInfoLoading }] =
    useMutation(SAVE_LOGISTICSINFO, {
      onCompleted({ saveLogisticsInfo }) {
        if (saveLogisticsInfo.success) {
          reset();
          onClose();
        } else if (saveLogisticsInfo.message) {
          notify(saveLogisticsInfo.message);
        }
      },
    });
  function _submit({ senderName, senderPhone, senderCellPhone }) {
    saveLogisticsInfo({
      variables: {
        logisticsInfoInput: {
          senderName,
          senderPhone,
          senderCellPhone,
        },
      },
    });
  }
  function _onClose() {
    if (!saveLogisticsInfoLoading) {
      reset();
      onClose();
    }
  }
  return (
    <Dialog
      open={Boolean(clickData)}
      onClose={_onClose}
      fullWidth
      maxWidth="xs"
    >
      <LoadingModal loading={false} />
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        寄件人資訊設定
        <IconButton
          onClick={_onClose}
          size="small"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      {logisticsInfoLoading ? (
        <DialogContent>
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Controller
              control={control}
              name="senderName"
              rules={{
                required: "請輸入寄件人姓名",
                validate: (e) => {
                  if (
                    e &&
                    (checkSpecialSymbolsAndNumber.test(e) ||
                      halfShapeAndFullForm(e) < 4 ||
                      halfShapeAndFullForm(e) > 10)
                  ) {
                    return "寄件人姓名請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldComponent
                  {...field}
                  label="寄件人姓名"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              control={control}
              name="senderPhone"
              rules={{
                required: "請輸入寄件人電話",
                validate: (e) => {
                  if (e && !checkMobile.test(e)) {
                    return "手機格式錯誤";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldComponent
                  {...field}
                  label="寄件人電話(建議填寫手機號碼)"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              control={control}
              name="senderCellPhone"
              rules={{
                required: "請輸入寄件人手機",
                validate: (e) => {
                  if (e && !checkMobile.test(e)) {
                    return "手機格式錯誤";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldComponent
                  {...field}
                  label="寄件人手機"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit(_submit)}
                  disabled={saveLogisticsInfoLoading}
                >
                  {saveLogisticsInfoLoading ? "處理中..." : "確定"}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={_onClose}>
                  關閉
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

import React, { useState } from "react";

import { useAlert } from "../components/Alert";
import Grid from "../components/Grid";
import TabView from "../components/TabView";
import CarouselsListTable from "./component/CarouselsListTable";
import CarouselsListForm from "./component/CarouselsListForm";

export default function CarouselsListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["列表"],
    select: 0,
    dataObject: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  return (
    <>
      <Grid>
        <TabView
          paper
          tabs={newData.tabViewData}
          index={newData.select}
          onChange={(e) => {
            if (newData.select === 1 && e !== 1) {
              Alert.alert("通知", "確定要離開編輯？", [
                {
                  text: "確定",
                  onClick: () => {
                    changeData("dataObject", null);
                    newData.tabViewData.splice(1, 1);
                    changeData("tabViewData", newData.tabViewData);
                    changeData("select", e, true);
                  },
                },
                {
                  text: "取消",
                  type: "outlined",
                },
              ]);
            } else {
              changeData("select", e, true);
            }
          }}
        >
          <CarouselsListTable
            onEditSelect={(value) => {
              changeData("dataObject", value);
              newData.tabViewData.push("編輯");
              changeData("tabViewData", newData.tabViewData);
              changeData("select", 1, true);
            }}
          />
          <CarouselsListForm
            id={newData.dataObject && newData.dataObject.id}
            onChangeSelect={() => {
              changeData("dataObject", null);
              newData.tabViewData.splice(1, 1);
              changeData("tabViewData", newData.tabViewData);
              changeData("select", 0, true);
            }}
          />
        </TabView>
      </Grid>
    </>
  );
}

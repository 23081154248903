import React, { useEffect, Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  ButtonBase,
  Box,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import Button from "../../components/Button";
import Grid from "../../components/Grid";
import LoadingFloating from "../../components/LoadingFloating";
import { checkImageSize } from "../../components/utils";

const GET_PAGECONTENT = gql`
  query pageContent($id: Int, $area: PageContentArea) {
    pageContent(id: $id, area: $area) {
      id
      imageUrl
      area
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const SAVE_PAGECONTENT = gql`
  mutation savePageContent($imageUrl: String!, $area: PageContentArea!) {
    savePageContent(imageUrl: $imageUrl, area: $area) {
      success
      message
    }
  }
`;

export default function PictureFloatingLayer({
  id,
  open,
  onFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    imageUrl: "",
    area: "",
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  function initialvalue(value) {
    changeData("area", "");
    changeData("imageUrl", "", true);
    onFloatingwindowClose(value);
  }

  const [getPageContent, { loading }] = useLazyQuery(GET_PAGECONTENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ pageContent }) {
      if (pageContent) {
        changeData("imageUrl", pageContent.imageUrl);
        changeData("area", pageContent.area, true);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getPageContent({ variables: { id: Number(id) } });
    }
  }, [id]);

  const [savePageContent, { loading: saveCategoryLoading }] = useMutation(
    SAVE_PAGECONTENT,
    {
      onCompleted({ savePageContent }) {
        if (savePageContent.success) {
          return Alert.notify(`儲存成功！`, initialvalue("refetch"));
        } else if (savePageContent.message) {
          return Alert.notify(savePageContent.message);
        }
      },
    }
  );

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted({ uploadImage }) {
        delete uploadImage.__typename;

        changeData("imageUrl", uploadImage.location, true);
      },
      onError() {
        return null;
      },
    }
  );

  return (
    <Dialog open={open} onClose={initialvalue} fullWidth maxWidth="md">
      <LoadingFloating loading={saveCategoryLoading || uploadImageLoading} />
      <DialogTitle align="center">{Boolean(id) ? "編輯" : "建立"}</DialogTitle>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Fragment>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="primary">圖片</Typography>
                <Box
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                  >
                    圖片尺寸為&nbsp;16:9&nbsp;，格式為&nbsp;jpg、png、gif、svg，容量需小於4.5MB
                  </Typography>
                </Box>
                <ButtonBase
                  style={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    paddingTop: "56.25%",
                  }}
                  component="label"
                  htmlFor={`uploadImage`}
                >
                  {Boolean(newData.imageUrl) ? (
                    <img
                      src={newData.imageUrl}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <CloudUploadIcon />
                  )}
                </ButtonBase>
                <input
                  id={`uploadImage`}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    const {
                      target: { validity, files },
                    } = event;

                    if (validity.valid) {
                      for (let file of files) {
                        if (checkImageSize(file.size))
                          return Alert.notify("圖片限制 4.5 MB 以內");

                        uploadImage({ variables: { image: file } });
                      }
                    }
                  }}
                  style={{
                    display: "none",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    savePageContent({
                      variables: {
                        imageUrl: newData.imageUrl,
                        area: newData.area,
                      },
                    });
                  }}
                >
                  {Boolean(id) ? "儲存" : "建立"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={initialvalue}
                >
                  取消
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

import React, { useState } from "react";
import { Tabs, Tab, Box, Paper, Grid } from "@material-ui/core";

export default function TabView({
  paper = false,
  tabs = [],
  children,
  index,
  onChange = () => {},
}) {
  const [selectIndex, setSelectIndex] = useState(index || 0);
  const newNumber = typeof index === "number" ? index : selectIndex;
  function switchView() {
    if (Array.isArray(children)) {
      return children[newNumber];
    } else {
      return children;
    }
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid container item>
          <Tabs
            component={paper ? Paper : "div"}
            value={newNumber}
            onChange={(e, n) => {
              setSelectIndex(n);
              onChange(n);
            }}
          >
            {tabs.map((item) => (
              <Tab label={item} key={item} />
            ))}
          </Tabs>
        </Grid>
        <Grid container item>
          <Box width="100%">{switchView()}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

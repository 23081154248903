import React, { memo, useEffect, useMemo } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "../../components/Alert";
import { Select } from "../../components/Form";
import LoadingFloating from "../../components/LoadingFloating";

import {
  cleanConsolePriorityId,
  closeConsolePriority,
} from "../../redux/consolePriority";

const GET_PRODUCTS = gql`
  query products($enabled: Boolean, $priority: Boolean) {
    products(enabled: $enabled, priority: $priority) {
      contents {
        id
        name
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

const MOVE_PRODUCTPRIORITY = gql`
  mutation moveProductPriority($sourceProductId: Int!, $targetProductId: Int) {
    moveProductPriority(
      sourceProductId: $sourceProductId
      targetProductId: $targetProductId
    ) {
      success
      message
    }
  }
`;

const MOVE_CATEGORYPRIORITY = gql`
  mutation moveCategoryPriority(
    $sourceCategoryId: Int!
    $targetCategoryId: Int
  ) {
    moveCategoryPriority(
      sourceCategoryId: $sourceCategoryId
      targetCategoryId: $targetCategoryId
    ) {
      success
      message
    }
  }
`;

export default function PriorityFloatingLayer({ product = false }) {
  const Alert = useAlert();
  const dispatch = useDispatch();
  const consolePriorityId = useSelector(
    (state) => state.consolePriority.consolePriorityId
  );
  const consolePriorityOpen = useSelector(
    (state) => state.consolePriority.consolePriorityOpen
  );
  const selectForm = useForm({
    defaultValues: {
      sourceProductObject: null,
      targetProductObject: null,
      sourceCategoryObject: null,
      targetCategoryObject: null,
      selectData: [],
    },
  });
  const { setValue, reset, handleSubmit } = selectForm;

  const [getProducts, { loading: productsLoading }] = useLazyQuery(
    GET_PRODUCTS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ products }) {
        if (products) {
          const newProducts = [];
          products.contents.forEach((item) => {
            newProducts.push({
              label: item.name,
              value: item.id,
            });
          });
          const hasObject = newProducts.find(
            (item) => item.value === consolePriorityId
          );
          if (hasObject) {
            setValue("sourceProductObject", hasObject);
          }
          setValue("selectData", newProducts);
        }
      },
    }
  );

  const [getCategories, { loading: categoriesLoading }] = useLazyQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ categories }) {
        if (categories) {
          const newCategories = [];
          categories.forEach((item) => {
            newCategories.push({
              label: item.name,
              value: item.id,
            });
          });
          const hasObject = newCategories.find(
            (item) => item.value === consolePriorityId
          );
          if (hasObject) {
            setValue("sourceCategoryObject", hasObject);
          }
          setValue("selectData", newCategories);
        }
      },
    }
  );

  useEffect(() => {
    if (product) {
      getProducts({ variables: { enabled: true, priority: true } });
    } else {
      getCategories();
    }
  }, [consolePriorityId, product]);

  function _onClose(value) {
    if (value === "mutation") {
      dispatch(closeConsolePriority("mutation"));
    } else {
      dispatch(closeConsolePriority());
    }
    dispatch(cleanConsolePriorityId());
    reset();
  }

  const [moveProductPriority, { loading: moveProductPriorityLoading }] =
    useMutation(MOVE_PRODUCTPRIORITY, {
      onCompleted({ moveProductPriority }) {
        if (moveProductPriority.success) {
          Alert.notify("移動成功！");
          _onClose("mutation");
        } else if (moveProductPriority.message) {
          Alert.notify(moveProductPriority.message);
        }
      },
    });

  const [moveCategoryPriority, { loading: moveCategoryPriorityLoading }] =
    useMutation(MOVE_CATEGORYPRIORITY, {
      onCompleted({ moveCategoryPriority }) {
        if (moveCategoryPriority.success) {
          Alert.notify("移動成功！");
          _onClose("mutation");
        } else if (moveCategoryPriority.message) {
          Alert.notify(moveCategoryPriority.message);
        }
      },
    });

  function _moveProductPriority(data) {
    const {
      sourceProductObject,
      targetProductObject,
      sourceCategoryObject,
      targetCategoryObject,
    } = data;
    if (product) {
      moveProductPriority({
        variables: {
          sourceProductId: sourceProductObject.value,
          targetProductId: targetProductObject.value,
        },
      });
    } else {
      moveCategoryPriority({
        variables: {
          sourceCategoryId: sourceCategoryObject.value,
          targetCategoryId: targetCategoryObject.value,
        },
      });
    }
  }

  return (
    <Dialog
      open={
        typeof consolePriorityOpen === "boolean" ? consolePriorityOpen : false
      }
      onClose={_onClose}
      fullWidth
      maxWidth="sm"
    >
      <LoadingFloating
        loading={moveProductPriorityLoading || moveCategoryPriorityLoading}
      />
      <DialogTitle align="center">
        {product ? "商品" : "分類"}調整順序
      </DialogTitle>
      <FormProvider {...selectForm}>
        <DialogContent
          style={{
            height: "180px",
            display: "flex",
            justifyContent: "center",
            overflowY: 0,
          }}
        >
          {productsLoading || categoriesLoading ? (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Grid container spacing={1}>
              <ClickSelectComponent product={product} />
              <OptionalSelectComponent product={product} />
            </Grid>
          )}
        </DialogContent>
      </FormProvider>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          {!productsLoading && !categoriesLoading && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_moveProductPriority)}
              >
                儲存
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" color="primary" onClick={_onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

const ClickSelectComponent = memo(function ClickSelectComponent({ product }) {
  const { control } = useFormContext();
  const selectData = useWatch({ control, name: "selectData" });
  return (
    <Controller
      control={control}
      name={product ? "sourceProductObject" : "sourceCategoryObject"}
      rules={{
        required: true,
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Grid item xs={12}>
          <Typography color="primary">
            點選的{product ? "商品" : "分類"}
          </Typography>
          <Select
            items={selectData}
            value={value}
            onChange={(e, value) => {
              onChange(value);
            }}
            disabled
            error={error}
            fullWidth
          />
        </Grid>
      )}
    />
  );
});

const OptionalSelectComponent = memo(function OptionalSelectComponent({
  product,
}) {
  const { control } = useFormContext();
  const selectData = useWatch({ control, name: "selectData" });
  const consolePriorityId = useSelector(
    (state) => state.consolePriority.consolePriorityId
  );

  const disabledSelect = useMemo(() => {
    let newValue = 0;
    if (selectData[0] && consolePriorityId) {
      const hasObject = selectData.findIndex(
        (item) => item.value === consolePriorityId
      );
      if (hasObject > -1) {
        newValue = hasObject;
      }
    }
    return newValue;
  }, [selectData, consolePriorityId]);

  return (
    <Controller
      control={control}
      name={product ? "targetProductObject" : "targetCategoryObject"}
      rules={{
        required: true,
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Grid item xs={12}>
          <Typography color="primary">
            更換的目標{product ? "商品" : "分類"}
          </Typography>
          <Select
            items={selectData}
            value={value}
            onChange={(e, value) => {
              onChange(value);
            }}
            getOptionDisabled={(option) =>
              option === selectData[disabledSelect] ||
              option.label === "限定商品" ||
              option.label === "會員專區"
            }
            error={error}
            fullWidth
          />
        </Grid>
      )}
    />
  );
});

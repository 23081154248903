import React, { useState } from "react";
import {
  CardContent,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  ButtonGroup,
  Box,
  Typography,
  useMediaQuery,
  makeStyles,
  useTheme,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import { ReactComponent as LineIcon } from "../images/svg/lineIcon.svg";
import { ReactComponent as QuestionIcon } from "../images/svg/questionIcon.svg";
import LoadingFloating from "../components/LoadingFloating";

const MUTATION_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      message
      user {
        id
        token
      }
    }
  }
`;

export default function LoginPage() {
  return (
    <Box>
      <LoginPageContent />
    </Box>
  );
}

function LoginPageContent() {
  const Alert = useAlert();
  const theme = useTheme();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    textstyle: {
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },
    LoginContentBox: {
      padding: "4em 0 0 0",
      minHeight: "calc(100vh - 211px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    LoginContentGridBox: {
      width: isDownTablet ? (isPhone ? "100%" : "60%") : "40%",
    },
    LoginBtn: {
      padding: "2px 7px 2px 10px !important",
      backgroundColor: "rgba(79,79,79)",
      color: "#fff",
      letterSpacing: "5px",
    },
    //ANCHOR signSocial

    loginByFaceBook: {
      backgroundColor: "#465992",
      "&:hover": {
        backgroundColor: "#466999",
      },
    },
    signByLine: {
      display: "inline-block",
      border: "none",
      color: "#fff",
      letterSpacing: "3px",
      padding: "2px 0",
      width: "100%",
    },
    loginSocialSvg: {
      width: "25px",
      height: "25px",
      margin: "0 10px 0 0",
      fill: "#fff",
    },

    joinOrReturnBox: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      transition: "all 1s",
      fontSize: "0.9em",
      padding: "2em 0",
    },
    joinOrReturnBtn: {
      display: "inline-block",
      transition: "all 0.3s",
      cursor: "pointer",
      "&:hover": {
        color: "gray",
      },
    },
    loginBtn: {
      backgroundColor: [theme.palette.success.main],
      "&:hover": {
        backgroundColor: [theme.palette.success.light],
      },
    },
    loginText: {
      padding: "10px 20px",
      color: theme.palette.common.white,
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [selectIndex, setSelectIndex] = useState(0);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  function _login(data) {
    login({ variables: { ...data } });
  }

  const [login, { loading }] = useMutation(MUTATION_LOGIN, {
    onCompleted({ login }) {
      if (login.success) {
        localStorage.setItem("@twenty-seven-webMemberId", login.user.id);
        localStorage.setItem("@twenty-seven-webToken", login.user.token);
        return Alert.notify("登入成功", () => history.replace("/"));
      } else if (login.message) {
        return Alert.notify(login.message);
      }
    },
  });

  function _register() {
    history.push("/member-terms");
  }
  function _forgePassword() {
    history.push("/forget-password");
  }

  return (
    <Box className={classes.LoginContentBox}>
      <LoadingFloating loading={loading} />
      <Box
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: isDownTablet ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        {/* ANCHOR會員登入 */}
        <Grid container item justifyContent="center" xs={isPhone ? 11 : 5}>
          <Tabs
            value={selectIndex}
            component={Paper}
            variant="fullWidth"
            onChange={(e, n) => setSelectIndex(n)}
            indicatorColor="primary"
            style={{ width: "100%" }}
          >
            <Tab className={classes.tab} key="一般登入" label="一般登入" />
          </Tabs>
          {selectIndex === 0 ? (
            <Box>
              <CardContent>
                <Box>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    會員登入
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{
                      color: "gray",
                      marginLeft: "2.5px",
                      transform: "translateY(-7px)",
                      display: "inline-block",
                    }}
                  >
                    Member&ensp;login
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid container item>
                    <Controller
                      name="username"
                      control={control}
                      rules={{
                        required: "請填寫帳號／手機／電子信箱",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="帳號／手機／電子信箱"
                          {...field}
                          error={error}
                          helperText={error && error.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "密碼為必填" }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          type={showPassword ? "text" : "password"}
                          label="密碼"
                          {...field}
                          error={error}
                          helperText={error && error.message}
                          fullWidth
                          className={!error ? classes.textstyle : ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  size="small"
                                  onClick={() => setShowPassword((e) => !e)}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item>
                    <ButtonGroup
                      size="small"
                      variant="text"
                      color="black"
                      style={{ marginLeft: "auto" }}
                    >
                      <Button
                        onClick={_forgePassword}
                        style={{ fontWeight: "bold" }}
                      >
                        <QuestionIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "3px",
                          }}
                        />
                        忘記密碼
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        className={classes.loginBtn}
                        onClick={handleSubmit(_login)}
                      >
                        <Typography className={classes.loginText}>
                          登入
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        className={classes.loginBtn}
                        onClick={() => _register()}
                      >
                        <Typography className={classes.loginText}>
                          註冊
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          ) : (
            <SignUpMobile />
          )}
        </Grid>
      </Box>

      {/* ANCHOR加入會員/返回首頁 */}

      <Box className={classes.joinOrReturnBox}>
        <Button
          onClick={() => history.push("/")}
          className={classes.joinOrReturnBtn}
        >
          返回首頁
        </Button>
      </Box>
    </Box>
  );
}

function SignUpMobile() {
  const theme = useTheme();
  const useStyles = makeStyles({
    textstyle: {
      fontSize: "1em",
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      fontSize: "1em",
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },

    phoneLoginBtn: {
      backgroundColor: [theme.palette.success.main],
      "&:hover": {
        backgroundColor: [theme.palette.success.light],
      },
    },
    phoneLoginText: {
      padding: "10px 20px",
      color: theme.palette.common.white,
    },
    sendBtn: {
      height: "40px",
      backgroundColor: theme.palette.grey[700],
      "&:hover": {
        backgroundColor: theme.palette.grey[500],
      },
    },
    sendText: {
      padding: "0px 10px",
      color: theme.palette.common.white,
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const mobilePattern = /^[0-9]{10}$/;
  const verifyPattern = /^[0-9]{6}$/;

  const { handleSubmit, control } = useForm({
    defaultValues: {
      mobile: "",
      verificationCode: "",
    },
  });
  const [verifying, setVerifying] = useState(false);
  function _sendVerification() {
    setVerifying(true);
  }

  function register_submit(data) {
    history.push(`/member-terms?register=phoneRegister`);
  }

  function _submit(data) {
    // localStorage.setItem("token", "1234567890");
    // history.replace("/");
    // history.goBack();
  }

  return (
    <Box width="100%" px={2} height="257px">
      <Box mb={2} mt={2}>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          您的手機號碼就是登入帳號:
        </Typography>
      </Box>
      <Grid container item>
        <Controller
          name="mobile"
          control={control}
          rules={{
            required: "電話為必填",
            pattern: {
              value: mobilePattern,
              message: "手機格式不正確！",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <Grid container justifyContent="space-between">
                <Grid xs={7} item>
                  <TextField
                    label="手機號碼"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    className={
                      error ? classes.texterrorstyle : classes.textstyle
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  {/* ANCHOR需要判斷手機是否輸入正確 */}
                  <Button
                    variant="contained"
                    onClick={_sendVerification}
                    disabled={error}
                    className={classes.sendBtn}
                  >
                    <Typography variant="body2" className={classes.sendText}>
                      傳送驗證碼
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
      <Grid container item>
        <Controller
          name="verificationCode"
          control={control}
          rules={{
            required: "驗證碼未輸入",
            pattern: {
              value: verifyPattern,
              message: "驗證碼格式不正確！",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={verifying ? "驗證碼" : "請先按下電話驗證"}
              {...field}
              error={error}
              helperText={error && error.message}
              className={error ? classes.texterrorstyle : classes.textstyle}
              disabled={!verifying}
              style={{ marginTop: "0.4em" }}
            />
          )}
        />
      </Grid>

      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              className={classes.phoneLoginBtn}
              onClick={handleSubmit(_submit)}
            >
              <Typography className={classes.phoneLoginText}>
                手機登入
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              className={classes.phoneLoginBtn}
              // onClick={()=>history.push(`/member-terms?register=phoneRegister`)}
              onClick={handleSubmit(register_submit)}
            >
              <Typography className={classes.phoneLoginText}>註冊</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

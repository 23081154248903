import { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

// ANCHOR 運費金額
const GET_SHIPMENTFEERULE = gql`
  query shipmentFeeRule {
    shipmentFeeRule {
      id
      convenienceStore
      homeDelivery
    }
  }
`;

export default function useShipmentFeeRule() {
  const { data } = useQuery(GET_SHIPMENTFEERULE);
  const shipmentFeeRuleData = useMemo(() => {
    if (data) {
      const { shipmentFeeRule } = data;
      return { ...shipmentFeeRule };
    }
    return {};
  }, [data]);
  return shipmentFeeRuleData;
}

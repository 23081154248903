import React, { useEffect, memo } from "react";
import {
  Tabs,
  Tab,
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  Paper,
  CardMedia,
  Typography,
  TextField,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
  Controller,
  useWatch,
} from "react-hook-form";

import { Card, CardActions } from "../../components/Card";
import { Select } from "../../components/Form";
import ContentEditor from "./ContentEditor";
import Grid from "../../components/Grid";
import Button from "../../components/Button";
import { useAlert } from "../../components/Alert";
import LoadingModal from "../../components/LoadingModal";
import { checkImageSize } from "../../components/utils";

const GET_PRODUCT = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      images {
        filename
        mimetype
        encoding
        location
      }
      categoryId
      name
      brand
      usage
      instruction
      feature
      note
      capacity
      price
      freeShipping
      stock
      enable
      contents {
        type
        body
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($productInput: ProductInput!) {
    createProduct(productInput: $productInput) {
      success
      message
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $productInput: ProductInput!) {
    updateProduct(id: $id, productInput: $productInput) {
      success
      message
    }
  }
`;

const shipmentFeeSelect = [
  {
    label: "是",
    value: true,
  },
  {
    label: "否",
    value: false,
  },
];

const enabledSelect = [
  {
    label: "是",
    value: true,
  },
  {
    label: "否",
    value: false,
  },
];

export default function ConsoleProductForm({
  subCategories,
  copy,
  id,
  onChangeSelect = () => {},
}) {
  const Alert = useAlert();
  const productForm = useForm({
    defaultValues: {
      select: 0,
      images: [{ image: "" }],
      name: "",
      categoryObject: undefined,
      brand: "",
      usage: "",
      instruction: "",
      feature: "",
      note: "",
      capacity: "",
      stock: "",
      price: "",
      contents: {
        details: [
          {
            type: "HEADING",
            body: "",
          },
        ],
      },
      enabledObject: {
        label: "是",
        value: true,
      },
      shipmentFeeObject: {
        label: "否",
        value: false,
      },
    },
  });
  const { control, setValue, handleSubmit } = productForm;

  const [getProduct, { loading }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ product }) {
      if (product) {
        const newImages = [];
        product.images.forEach((item) => {
          delete item.__typename;
          newImages.push({ image: { ...item } });
        });
        setValue("images", newImages);
        setValue("name", product.name);
        const hasCategory = subCategories.find(
          (item) => item.value === product.categoryId
        );
        if (hasCategory) {
          setValue("categoryObject", hasCategory);
        }
        setValue("brand", product.brand);
        setValue("usage", product.usage ? product.usage : "");
        setValue("instruction", product.instruction ? product.instruction : "");
        setValue("feature", product.feature ?? "");
        setValue("note", product.note ?? "");
        setValue("capacity", product.capacity);
        setValue("stock", product.stock);
        setValue("price", product.price);
        const hasShipmentFee = shipmentFeeSelect.find(
          (item) => item.value === product.freeShipping
        );
        if (hasShipmentFee) {
          setValue("shipmentFeeObject", hasShipmentFee);
        }
        const hasEnabled = enabledSelect.find(
          (item) => item.value === product.enable
        );
        if (hasEnabled) {
          setValue("enabledObject", hasEnabled);
        }
        const newContents = [];
        product.contents.forEach((item) => {
          delete item.__typename;
          newContents.push({ ...item });
        });
        setValue("contents", { details: newContents }, true);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getProduct({ variables: { id: Number(id) } });
    } else {
      setValue("images", [{ image: "" }]);
      setValue("name", "");
      setValue("categoryObject", undefined);
      setValue("brand", "");
      setValue("usage", "");
      setValue("instruction", "");
      setValue("feature", "");
      setValue("note", "");
      setValue("capacity", "");
      setValue("stock", "");
      setValue("price", "");
      setValue("contents", { details: [] });
      setValue("shipmentFeeObject", {
        label: "否",
        value: false,
      });
      setValue(
        "enabledObject",
        {
          label: "是",
          value: true,
        },
        true
      );
    }
  }, [id]);

  const [uploadImage, { loading: uploadImageLoading }] =
    useMutation(UPLOAD_IMAGE);

  async function save(sendValue) {
    const {
      images,
      name,
      categoryObject,
      brand,
      usage,
      instruction,
      feature,
      note,
      capacity,
      stock,
      price,
      contents,
      enabledObject,
      shipmentFeeObject,
    } = sendValue;

    const oldImages = [...images];
    const dataToUpload = [];
    oldImages.forEach((item, index) => {
      if (item.image.name) {
        dataToUpload.push({
          index,
          image: item.image,
        });
      }
    });
    for (let i = 0; i < dataToUpload.length; i++) {
      const element = dataToUpload[i];
      const {
        data: {
          uploadImage: { __typename, ...newImage },
        },
      } = await uploadImage({ variables: { image: element.image } });
      oldImages.fill({ image: newImage }, element.index, element.index + 1);
    }

    const detailsNull = contents.details.find(
      (item) =>
        (item.type == "HEADING" && item.body == "") ||
        (item.type == "PARAGRAPH" && item.body == "") ||
        (item.type == "IMAGE" && item.body == "") ||
        (item.type == "VIDEO" && item.body == "")
    );

    if (detailsNull) {
      return Alert.notify("商品介紹未填寫完！");
    }

    const productInput = {
      categoryId: categoryObject && categoryObject.value,
      images: oldImages.map((item) => ({ ...item.image })),
      name: name,
      brand: brand,
      usage: usage,
      instruction: instruction,
      capacity: capacity,
      price: parseFloat(price),
      freeShipping: shipmentFeeObject.value,
      stock: Number(stock),
      enable: enabledObject.value,
      contents: contents.details,
    };

    if (feature) {
      productInput.feature = feature;
    }

    if (note) {
      productInput.note = note;
    }

    if (Boolean(id)) {
      if (copy) {
        createProduct({ variables: { productInput } });
      } else {
        updateProduct({ variables: { id, productInput } });
      }
    } else {
      createProduct({ variables: { productInput } });
    }
  }

  const [createProduct, { loading: createProductLoading }] = useMutation(
    CREATE_PRODUCT,
    {
      onCompleted({ createProduct }) {
        if (createProduct.success) {
          Alert.notify("新增成功", () => onChangeSelect());
        } else if (createProduct.message) {
          Alert.notify(createProduct.message);
        }
      },
    }
  );

  const [updateProduct, { loading: updateProductLoading }] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted({ updateProduct }) {
        if (updateProduct.success) {
          Alert.notify("編輯成功", () => onChangeSelect());
        } else if (updateProduct.message) {
          Alert.notify(updateProduct.message);
        }
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={1}>
        <LoadingModal
          loading={
            createProductLoading || updateProductLoading || uploadImageLoading
          }
        />
        <FormProvider {...productForm}>
          <Grid container item>
            <Card style={{ flex: 1, padding: 15 }}>
              <CardActions>
                <Grid container spacing={3}>
                  <Grid container item>
                    <Controller
                      control={control}
                      name="select"
                      render={({ field: { value, onChange } }) => (
                        <Tabs value={value} onChange={(e, n) => onChange(n)}>
                          <Tab label="商品資訊" />
                          <Tab label="商品介紹" />
                        </Tabs>
                      )}
                    />
                  </Grid>
                  <SelectComponent subCategories={subCategories} />
                  <Grid container item justify="flex-end">
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={handleSubmit(save)}
                      >
                        儲存
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </FormProvider>
      </Grid>
    );
  }
}

const ProductCarousel = memo(function ProductCarousel() {
  const { alert, notify } = useAlert();
  const { control } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "images",
  });

  const removeBanner = (index) => {
    alert("", "確定要移除嗎？", [
      {
        text: "確定",
        onClick: () => remove(index),
      },
      {
        text: "取消",
        type: "outlined",
      },
    ]);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((item, index) => (
          <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Paper}
              p={2}
              pt={4}
              width="100%"
              position="relative"
              display="flex"
              flexDirection="column"
            >
              {/* removeButton */}
              {index !== 0 && (
                <IconButton
                  size="small"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 2,
                  }}
                  onClick={() => removeBanner(index)}
                >
                  <Close />
                </IconButton>
              )}
              <Controller
                control={control}
                name={`images.${index}.image`}
                rules={{
                  required: "未選擇",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <ButtonBase
                      style={{
                        width: "100%",
                        position: "relative",
                        overflow: "hidden",
                        paddingTop: "100%",
                      }}
                      onClick={() => {
                        const imageIndex = document.getElementById(
                          `image${index}`
                        );
                        imageIndex.click();
                      }}
                    >
                      {value ? (
                        <CardMedia
                          component="img"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          image={
                            value.name
                              ? URL.createObjectURL(value)
                              : value.location
                          }
                          alt="product"
                        />
                      ) : (
                        <CloudUploadIcon
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                      )}
                    </ButtonBase>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ color: "#F77B72", fontWeight: "bold" }}
                    >
                      &ensp;&ensp;{error?.message}
                    </Typography>
                    <input
                      type="file"
                      id={`image${index}`}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const isUpload = e.target.files.length > 0;
                        if (isUpload) {
                          const url = e.target.files[0];
                          if (checkImageSize(url.size))
                            return notify("圖片限制 4.5 MB 以內");
                          onChange(url);
                        }
                      }}
                    />
                  </>
                )}
              />

              {/* 移動 按鈕 */}
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Button
                    fullWidth
                    disabled={index === 0}
                    variant="contained"
                    style={{ bgcolor: "primary.light" }}
                    onPress={() => {
                      move(index, index - 1);
                    }}
                  >
                    向前移動
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={index === fields.length - 1}
                    style={{ bgcolor: "primary.light" }}
                    onPress={() => move(index, index + 1)}
                  >
                    向後移動
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            style={{
              display: "flex",
              borderRadius: "10px",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <ButtonBase
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "5px",
                minHeight: "100px",
              }}
              onClick={() => append({ image: "" })}
            >
              <AddIcon style={{ color: "#ccc" }} />
            </ButtonBase>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

const SelectComponent = memo(function SelectComponent({ subCategories }) {
  const { control } = useFormContext();
  const select = useWatch({ control, name: "select" });
  return (
    <>
      {Boolean(select === 0) && (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                圖片尺寸為&nbsp;1:1&nbsp;，格式為&nbsp;jpg、png、gif、svg，容量需小於4.5MB
              </Typography>
            </Box>
            <ProductCarousel />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="name"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="商品名稱"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="categoryObject"
              rules={{
                required: true,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  label={"子分類"}
                  items={subCategories}
                  value={value}
                  onChange={(e, value) => onChange(value)}
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="brand"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="品牌"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="usage"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="用途"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="instruction"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="使用方法"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="feature"
              render={({ field }) => (
                <TextField {...field} label="商品特色" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="note"
              render={({ field }) => (
                <TextField {...field} label="香調" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="capacity"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="容量"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="stock"
              rules={{
                required: true,
                validate: (e) => {
                  if (isNaN(e)) {
                    return "必須為數字";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="庫存"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="shipmentFeeObject"
              render={({ field: { value, onChange } }) => (
                <Select
                  label={"是否免運"}
                  items={shipmentFeeSelect}
                  value={value}
                  onChange={(e, value) => {
                    if (value) {
                      onChange(value);
                    } else {
                      onChange({
                        label: "否",
                        value: false,
                      });
                    }
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="price"
              rules={{
                required: true,
                validate: (e) => {
                  if (isNaN(e)) {
                    return "必須為數字";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="單價"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="enabledObject"
              render={({ field: { value, onChange } }) => (
                <Select
                  label={"上架"}
                  items={enabledSelect}
                  value={value}
                  onChange={(e, value) => {
                    if (value) {
                      onChange(value);
                    } else {
                      onChange({
                        label: "是",
                        value: true,
                      });
                    }
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {Boolean(select === 1) && (
        <Controller
          control={control}
          name="contents"
          render={({ field: { value, onChange } }) => (
            <ContentEditor
              data={value}
              onChangeData={(e) => onChange({ details: e })}
            />
          )}
        />
      )}
    </>
  );
});

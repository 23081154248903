import React, { useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Switch,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";

import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import { Card, CardContent } from "../../components/Card";
import LoadingModal from "../../components/LoadingModal";

const GET_ADVERTISEMENTS = gql`
  query advertisements {
    advertisements {
      count
      pageCount
      contents {
        id
        filename
        mimetype
        encoding
        location
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_ADVERTISEMENT = gql`
  mutation deleteAdvertisement($id: Int!) {
    deleteAdvertisement(id: $id) {
      success
      message
    }
  }
`;

const GET_ADVERTISEMENTENABLE = gql`
  query advertisementEnable {
    advertisementEnable {
      id
      enable
    }
  }
`;

const EDIT_ADVERTISEMENTENABLE = gql`
  mutation editAdvertisementEnable($enabled: Boolean!) {
    editAdvertisementEnable(enabled: $enabled) {
      success
      message
    }
  }
`;

export default function AdvertisementTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();
  const { control, setValue } = useForm({
    defaultValues: {
      enabled: false,
    },
  });
  const enabledRef = useRef(false);

  const { data, loading, error, refetch } = useQuery(GET_ADVERTISEMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { loading: advertisementEnableLoading } = useQuery(
    GET_ADVERTISEMENTENABLE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ advertisementEnable }) {
        if (advertisementEnable) {
          const { enable } = advertisementEnable;
          setValue("enabled", enable);
        }
      },
    }
  );

  const [editAdvertisementEnable, { loading: editAdvertisementEnableLoading }] =
    useMutation(EDIT_ADVERTISEMENTENABLE, {
      onCompleted({ editAdvertisementEnable }) {
        if (editAdvertisementEnable.success) {
          setValue("enabled", enabledRef.current);
        } else if (editAdvertisementEnable.message) {
          Alert.notify(editAdvertisementEnable.message);
        }
      },
    });

  const [deleteAdvertisement, { loading: deleteAdvertisementLoading }] =
    useMutation(DELETE_ADVERTISEMENT, {
      onCompleted({ deleteAdvertisement }) {
        if (deleteAdvertisement.success) {
          refetch();
        } else if (deleteAdvertisement.message) {
          Alert.notify(deleteAdvertisement.message);
        }
      },
    });

  function _deleteAdvertisementitem(item) {
    Alert.alert("", `確定刪除廣告\u2002${item.filename}\u2002嗎？`, [
      {
        text: "確定",
        onClick: () => deleteAdvertisement({ variables: { id: item.id } }),
      },
      {
        text: "取消",
        type: "outlined",
      },
    ]);
  }

  return (
    <Card>
      <LoadingModal
        loading={deleteAdvertisementLoading || editAdvertisementEnableLoading}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography>廣告數量：{data?.advertisements.count}</Typography>
            </Box>
          </Grid>
          <Grid item style={{ paddingLeft: "20px" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>廣告啟用：</Typography>
              <Controller
                control={control}
                name="enabled"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      enabledRef.current = e.target.checked;
                      editAdvertisementEnable({
                        variables: { enabled: e.target.checked },
                      });
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        {loading || advertisementEnableLoading ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            height="480px"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            height="480px"
          >
            <Typography variant="h4">無法取得資料</Typography>
          </Box>
        ) : (
          <Table
            data={data ? data.advertisements.contents : []}
            header={["廣告圖", "建立時間", "最後更新時間", "刪除"]}
            tableWidth={2}
            columns={[
              (item) => {
                if (item.location) {
                  return (
                    <Grid container justifyContent="center">
                      <Box
                        style={{
                          position: "relative",
                          width: "100%",
                          overflow: "hidden",
                          paddingTop: "56.25%",
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={item.location}
                        />
                      </Box>
                    </Grid>
                  );
                } else {
                  return "";
                }
              },
              (item) => moment(item.createdAt).format("YYYY/MM/DD"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
              (item) => {
                return (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      _deleteAdvertisementitem(item);
                    }}
                  >
                    刪除
                  </Button>
                );
              },
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        )}
      </CardContent>
    </Card>
  );
}

import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useForm, Controller } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import TextFieldComponent from "../../components/TextFieldComponent";
import NumberTextField from "../../components/NumberTextField";
import { FieldCache } from "../../components/FieldCache";
import LoadingModal from "../../components/LoadingModal";
import { halfShapeAndFullForm } from "../../components/utils";

// ANCHOR 優惠券
const GET_COUPON = gql`
  query coupon($id: Int!) {
    coupon(id: $id) {
      id
      name
      code
      orderPrice
      discount
      startTime
      endTime
    }
  }
`;

// ANCHOR 儲存優惠券
const SAVE_COUPON = gql`
  mutation saveCoupon($couponInput: CouponInput!) {
    saveCoupon(couponInput: $couponInput) {
      success
      message
    }
  }
`;

export default function CouponForm({ id, onChangeSelect = () => {} }) {
  const { notify } = useAlert();
  const useStyles = makeStyles({
    textFieldCartSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
  });
  const classes = useStyles();
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      name: "",
      code: "",
      orderPrice: "",
      discount: "",
      startTime: null,
      endTime: null,
    },
  });
  const [getCoupon, { loading: couponLoading }] = useLazyQuery(GET_COUPON, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ coupon }) {
      if (coupon) {
        const { name, code, orderPrice, discount, startTime, endTime } = coupon;
        setValue("name", name);
        setValue("code", code);
        setValue("orderPrice", orderPrice);
        setValue("discount", discount);
        setValue("startTime", startTime);
        setValue("endTime", endTime);
      }
    },
  });
  useEffect(() => {
    if (id) {
      getCoupon({ variables: { id } });
    } else {
      reset();
    }
  }, [id, getCoupon]);
  const [saveCoupon, { loading: saveCouponLoading }] = useMutation(
    SAVE_COUPON,
    {
      onCompleted({ saveCoupon }) {
        if (saveCoupon.success) {
          notify("儲存成功！");
          onChangeSelect();
        } else if (saveCoupon.message) {
          notify(saveCoupon.message);
        }
      },
    }
  );
  function _saveCoupon({
    name,
    code,
    orderPrice,
    discount,
    startTime,
    endTime,
  }) {
    saveCoupon({
      variables: {
        couponInput: {
          id,
          name,
          code,
          orderPrice: orderPrice ? Number(orderPrice) : undefined,
          discount: discount ? Number(discount) : undefined,
          startTime,
          endTime,
        },
      },
    });
  }
  return (
    <Card style={{ flex: 1, padding: 15 }}>
      <LoadingModal loading={saveCouponLoading} />
      {couponLoading ? (
        <CardContent>
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "請輸入名稱" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldComponent
                      {...field}
                      label="名稱"
                      error={Boolean(error)}
                      helperText={error?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="code"
                  rules={{
                    required: "請輸入代碼",
                    validate: (e) => {
                      const regMail = new RegExp("^[0-9|a-zA-Z]+$");
                      if (e && !regMail.test(e)) {
                        return "必須為英文或數字";
                      } else if (e && halfShapeAndFullForm(e) > 10) {
                        return "必須為10位元以內";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldComponent
                      {...field}
                      label="代碼"
                      error={Boolean(error)}
                      helperText={error?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="orderPrice"
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <label style={{ fontSize: "0.8em" }}>訂單滿額</label>
                      <br />
                      <NumberTextField
                        {...field}
                        onBlur={(e) => {
                          const newValue =
                            e.target.value &&
                            !isNaN(e.target.value) &&
                            Number(e.target.value) > 0
                              ? e.target.value
                              : "";
                          field.onChange(newValue);
                          field.onBlur(newValue);
                        }}
                        InputProps={{
                          className: classes.textFieldCartSelectBox,
                        }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        fullWidth
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="discount"
                  rules={{ required: "請輸入折價金額" }}
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <label style={{ fontSize: "0.8em" }}>折價金額</label>
                      <br />
                      <NumberTextField
                        {...field}
                        onBlur={(e) => {
                          const newValue =
                            e.target.value &&
                            !isNaN(e.target.value) &&
                            Number(e.target.value) > 0
                              ? e.target.value
                              : "";
                          field.onChange(newValue);
                          field.onBlur(newValue);
                        }}
                        InputProps={{
                          className: classes.textFieldCartSelectBox,
                        }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        fullWidth
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="startTime"
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <label style={{ fontSize: "0.8em" }}>開始時間</label>
                      <br />
                      <DateTimePicker
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("endTime", null);
                        }}
                        inputVariant="outlined"
                        format="yyyy年MM月dd日 a hh點mm分"
                        error={Boolean(error)}
                        fullWidth
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldCache
                  control={control}
                  name="startTime"
                  render={(startTime) => (
                    <Box>
                      <label style={{ fontSize: "0.8em" }}>結束時間</label>
                      <br />
                      <Controller
                        control={control}
                        name="endTime"
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <DateTimePicker
                            {...field}
                            inputVariant="outlined"
                            format="yyyy年MM月dd日 a hh點mm分"
                            error={Boolean(error)}
                            minDate={new Date(startTime)}
                            disabled={!startTime}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_saveCoupon)}
              >
                儲存
              </Button>
            </Grid>
          </CardActions>
        </>
      )}
    </Card>
  );
}

import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  Paper,
} from "@material-ui/core";

/**
 * @param {{
 *   paper: boolean,
 *   data: any[],
 *   header: (string | string[])[],
 *   columns: string[],
 *   columnWidths: (number | string)[],
 *   alignItems: string[],
 *   alignHeaders: string[],
 *   tableWidth: number,
 *   onPress: Function
 * }} param0
 */
export default function Table({
  paper = false,
  data = [],
  header = [],
  columns = [],
  columnWidths = [],
  alignItems = [],
  alignHeaders = [],
  tableWidth = 1,
  onPress,
}) {
  const tableList = [320, 600, 960, 1280, 1920];
  const useStyles = makeStyles({
    table: {
      minWidth: tableList[changeTableWith()],
    },
    tableRow: {
      cursor: onPress && "pointer",
    },
  });
  const classes = useStyles();
  function changeColumns(data, item, rowIndex) {
    if (typeof item === "string") {
      return data[item];
    } else if (Array.isArray(item)) {
      let newData = Object.assign({}, data);
      for (let i = 0, len = item.length; i < len; i++) {
        newData = newData[item[i]];
      }
      return newData;
    } else if (typeof item === "function") {
      return item(data, rowIndex);
    } else {
      return item;
    }
  }
  function changeTableWith() {
    if (tableWidth <= 1) {
      return 0;
    } else if (tableWidth >= 5) {
      return 4;
    } else {
      return tableWidth;
    }
  }
  return (
    <TableContainer component={paper ? Paper : "div"}>
      <MuiTable className={classes.table}>
        <TableHead>
          <TableRow>
            {header.map((item, index) => (
              <TableCell key={index} align={alignHeaders[index] || "inherit"}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              hover={Boolean(onPress)}
              className={classes.tableRow}
              onClick={() => {
                if (onPress) {
                  onPress(row, rowIndex);
                }
              }}
            >
              {columns.map((item, index) => (
                <TableCell
                  key={index}
                  width={columnWidths[index]}
                  align={alignItems[index] || "inherit"}
                >
                  {changeColumns(row, item, rowIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";

export default function ProductTitle({ title, subtitle,enTitle }) {
  return (
    <Box style={{ textAlign: "center" }}>
      <Typography variant="h5" className="ProductTitle" style={{fontWeight:'900',letterSpacing:'6px',color:'#504F4F',fontFamily:'GENBKBASR'}}> 
        {title}
      </Typography>
      <br/>
      <Typography variant="p" style={{color:'#4f4f4f',fontSize:'0.9em',letterSpacing:'1px',fontFamily:'GENBKBASR'}}> 
        {subtitle}
      </Typography>
    </Box>
  );
}




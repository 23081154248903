import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  FormControl,
  Select,
  Grid,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import ProductList from "../components/ProductList";

const GET_PRODUCTS = gql`
  query products(
    $searchTerm: String
    $priority: Boolean
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    products(
      searchTerm: $searchTerm
      priority: $priority
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        images {
          filename
          mimetype
          encoding
          location
        }
        categoryId
        name
        brand
        capacity
        price
        shipmentFee
        stock
        enable
        contents {
          id
          type
          body
        }
      }
    }
  }
`;

export default function SearchPage() {
  const Alert = useAlert();
  const { search } = useLocation();
  const [page, setPage] = useState(
    localStorage.getItem("@twenty-seven-search")
      ? Number(localStorage.getItem("@twenty-seven-search"))
      : 1
  );
  const [latest, setLatest] = useState();
  const [price, setPrice] = useState();
  const [priority, setPriority] = useState(true);

  const [getProducts, { data, loading }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (search) {
      const pattern = new RegExp("[\u4E00-\u9FA5]+");

      let newName = search.split("?search=")[1];

      if (pattern.test(decodeURI(search.split("?search=")[1]))) {
        newName = decodeURI(search.split("?search=")[1]);
      }

      getProducts({
        variables: {
          searchTerm: newName,
          latest,
          priority,
          price,
          enabled: true,
          pageSize: 8,
          page,
        },
      });
    }
  }, [search, page, latest, price, priority]);

  useEffect(() => {
    if (localStorage.getItem("@twenty-seven-search")) {
      setPage(Number(localStorage.getItem("@twenty-seven-search")));
    } else {
      setPage(1);
    }
  }, [search]);

  return (
    <Box bgcolor="#f5f4ed" position="relative">
      {/* <SearchList /> */}
      {loading && !Boolean(data) ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Box pt={8}>
          <ProductList
            data={Boolean(data) ? data.products.contents : []}
            onSearchResult={(v) => {
              if (v) {
                if (v.value === "商品排序") {
                  setPriority(true);
                  setLatest(null);
                  setPrice(null);
                } else if (v.value === "上架時間-由新到舊") {
                  setPriority(null);
                  setLatest(true);
                  setPrice(null);
                } else if (v.value === "上架時間-由舊到新") {
                  setPriority(null);
                  setLatest(false);
                  setPrice(null);
                } else if (v.value === "價格-由高至低") {
                  setPriority(null);
                  setLatest(null);
                  setPrice(false);
                } else if (v.value === "價格-由低至高") {
                  setPriority(null);
                  setLatest(null);
                  setPrice(true);
                }
              } else {
                setPriority(true);
                setLatest(null);
                setPrice(null);
              }
            }}
          />
          <Grid container justifyContent="center">
            <Pagination
              count={
                Boolean(data) && data.products.pageCount
                  ? data.products.pageCount
                  : 1
              }
              size="large"
              color="primary"
              page={page}
              onChange={(e, n) => {
                localStorage.setItem("@twenty-seven-search", n);
                setPage(n);
              }}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
}

function SearchList() {
  // const theme = useTheme();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const useStyles = makeStyles((theme) => ({
    searchNav: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#D5CFB9",
      justifyContent: isDownTablet ? "flex-start" : "flex-end",
      position: "absolute",
      width: "100%",
      // paddingRight: "4em",
    },
    searchListBox: {
      // position:'relative',
    },
    menuItem: {
      backgroundColor: "#D5CFB9",
    },
    searchMenu: {
      position: "absolute",
      "& .MuiList-padding": {
        padding: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    searchSelect: {
      outline: "none",
      "& .MuiSelect-outlined": {
        outline: "none",
        border: "none",
      },
      "& option": {
        backgroundColor: "blue",
      },
    },
  }));
  const classes = useStyles();

  //找到搜尋對象 來輸出searchTarget TODO
  //  -> 再搜尋框輸入搜尋的目標之後 , 將搜尋的字串 放到 searchTarget中 !  ><!
  const searchWord = new URLSearchParams(useLocation().search);
  const searchLabel = [
    {
      text: "全部商品",
      target: searchWord.get("search") ?? "無",
    },
  ];
  const searchRule = [
    "商品排序",
    "上架時間-由新到舊",
    "上架時間-由舊到新",
    "價格-由高至低",
    "價格-由低至高",
  ];
  return (
    <Box className={classes.searchNav}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isDownTablet ? "flex-start" : "flex-end"}
        width="80%"
        mx="auto"
      >
        <Box>
          {searchLabel.map((item, index) => {
            return (
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {item.text} &ensp; | &ensp; {item.target}
              </Typography>
            );
          })}
        </Box>
        &ensp; &ensp; &ensp;
        <div>
          <FormControl
            variant="outlined"
            hiddenLabel
            className={classes.formControl}
          >
            {/* <InputLabel htmlFor="grouped-native-select">商品排序</InputLabel> */}

            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              className={classes.searchSelect}
            >
              {/* <option aria-label="None" value="" /> */}
              {searchRule.map((item) => {
                return (
                  <option className={classes.searchOptions}>{item}</option>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Box>
    </Box>
  );
}

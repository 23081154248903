import React, { useEffect, useMemo, useState, memo } from "react";
import {
  Grid,
  makeStyles,
  Box,
  Button,
  Typography,
  TableContainer,
  Paper,
  useMediaQuery,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  Controller,
  useForm,
  useWatch,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import {
  thousandsSeparator,
  getInvitationCode,
  halfShapeAndFullForm,
} from "../components/utils";
import LoadingModal from "../components/LoadingModal";
import NumberTextField from "../components/NumberTextField";
import TextFieldComponent from "../components/TextFieldComponent";
import SelectComponent from "../components/SelectComponent";
import { FieldCache } from "../components/FieldCache";

import { cartAmountStartChanging, cartAmountEndChanging } from "../redux/cart";

import {
  selectPaymentMethodList,
  shipmentMethoddList,
  shipmentSubTypedList,
} from "../utils/localData";
import { countiesList, districtsList } from "../utils/countiesDistricts";
import {
  checkSpecialSymbolsAndNumber,
  checkMobile,
} from "../utils/checkRegExp";

import useFreeShippingRule from "../hook/useFreeShippingRule";
import useShipmentFeeRule from "../hook/useShipmentFeeRule";
import emptyArray from "../utils/emptyArray";

const GET_CART = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          images {
            location
          }
          name
          price
          freeShipping
        }
        unitPrice
        amount
      }
    }
  }
`;

const GET_CART_TOTALPRICE = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          freeShipping
        }
      }
      totalPrice
    }
  }
`;

const GET_USER = gql`
  query user {
    user {
      id
      point
    }
  }
`;

const ADD_ITEMTOCART = gql`
  mutation addItemToCart($id: Int!, $cartItemInput: CartItemInput!) {
    addItemToCart(id: $id, cartItemInput: $cartItemInput) {
      success
      message
    }
  }
`;

const REMOVE_ITEMFROMCART = gql`
  mutation removeItemFromCart($id: Int!, $cartItemId: Int!) {
    removeItemFromCart(id: $id, cartItemId: $cartItemId) {
      success
      message
    }
  }
`;

const CREATE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput!) {
    createOrder(orderInput: $orderInput) {
      success
      message
      order {
        id
        paymentUrl
      }
    }
  }
`;

const CREATE_CART = gql`
  mutation createCart {
    createCart {
      id
    }
  }
`;

// ANCHOR 門市電子地圖
const GO_TO_LOGISTICSMAP = gql`
  query logisticsMap(
    $shipmentSubType: ShipmentSubType!
    $isCollection: Boolean!
    $randomCode: String!
    $returnUrl: String!
  ) {
    logisticsMap(
      shipmentSubType: $shipmentSubType
      isCollection: $isCollection
      randomCode: $randomCode
      returnUrl: $returnUrl
    )
  }
`;

// ANCHOR 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

// ANCHOR 優惠券
const GET_COUPON = gql`
  query coupon($code: String) {
    coupon(code: $code) {
      id
      discount
    }
  }
`;

export default function Cart() {
  return (
    <Box>
      <CartInfo />
    </Box>
  );
}

function CartInfo() {
  return (
    <Box>
      <BasicTable />
      {/* <IconListFooter/> */}
    </Box>
  );
}

function BasicTable() {
  const Alert = useAlert();
  const dispatch = useDispatch();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    table: {
      width: "100%",
    },
    tableTh: {
      display: "inline-block",
      textAlign: "left",
      fontSize: "0.8em",
    },
    CartInfoText: {
      letterSpacing: "1.2px",
    },
    tableHiddenGrid: {
      width: isDownTablet ? "0" : "",
      display: isDownTablet ? "none" : "inline-block",
    },
    tableShowGrid: {
      display: isDownTablet ? "inline-block" : "none",
    },
    cartSelectBox: {
      width: "100%",
      padding: "10px",
      marginTop: "0.3em",
      "&:focus": {
        outline: "none",
      },
    },
    cartSelectGrid: {
      width: isDownTablet ? "90%" : "30%",
      marginTop: isDownTablet ? "1em" : "0",
    },
    checkText: {
      letterSpacing: "0.1em",
      fontWeight: "bold",
    },
    checkButton: {
      display: "inline-block",
      backgroundColor: "#00c300",
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
      cursor: "pointer",
      color: "#fff",
      letterSpacing: "2px",
      textAlign: "center",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    shopButton: {
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
    },
    shopButtonText: {
      color: "#fff",
      textAlign: "center",
      fontSize: "16px",
    },
    CartTr: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "10px 20px",
      borderBottom: "0.1em solid gray",
      paddingLeft: isPhone ? "5px" : "",
    },
  });

  const createOrderForm = useForm({
    defaultValues: {
      recipient: "",
      recipientPhone: "",
      usePoint: "",
      deliveryFee: 0,
      shipmentMethod: "",
      discount: 0,
      shipmentSubType: "",
      paymentMethod: "",
      convenienceStore: "",
      district: "",
      subdistrict: "",
      address: "",
      cart: null,
      cartTotalPrice: 0,
      couponCode: "",
      couponCodeDiscount: 0,
    },
  });
  const { setValue } = createOrderForm;

  const { data, refetch, loading } = useQuery(GET_CART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
    },
    onCompleted({ cart }) {
      if (cart) {
        setValue("cart", cart);
        const twentySevenSelectStoreRandomCode = localStorage.getItem(
          `@twenty-sevenCartSelectStoreRandomCode`
        );
        if (twentySevenSelectStoreRandomCode) {
          getLogisticsMapData({
            variables: { randomCode: twentySevenSelectStoreRandomCode },
          });
        }
      }
    },
    onError() {
      return null;
    },
  });

  function getOldData() {
    const twentySevenSelectStoreGetValues = localStorage.getItem(
      `@twenty-sevenCartSelectStoreGetValues`
    );
    if (twentySevenSelectStoreGetValues) {
      const {
        recipient,
        recipientPhone,
        usePoint,
        couponCode,
        paymentMethod,
        shipmentSubType,
        district,
        subdistrict,
        address,
      } = JSON.parse(twentySevenSelectStoreGetValues);
      setValue("recipient", recipient);
      setValue("recipientPhone", recipientPhone);
      setValue("usePoint", usePoint);
      if (usePoint) {
        setValue("discount", Math.floor(usePoint / 50));
      }
      setValue("couponCode", couponCode);
      setValue("shipmentMethod", "CONVENIENCE_STORE");
      setValue("paymentMethod", paymentMethod);
      setValue("shipmentSubType", shipmentSubType);
      setValue("district", district);
      setValue("subdistrict", subdistrict);
      setValue("address", address);
      setTimeout(() => {
        localStorage.removeItem(`@twenty-sevenCartSelectStoreRandomCode`);
        localStorage.removeItem(`@twenty-sevenCartSelectStoreGetValues`);
      }, 0);
    }
  }

  const [getLogisticsMapData, { loading: logisticsMapDataLoading }] =
    useLazyQuery(GET_LOGISTICSMAPDATA, {
      onCompleted({ logisticsMapData }) {
        if (logisticsMapData) {
          const { cvsStoreId, cvsStoreName } = logisticsMapData;
          setValue("cvsStoreId", cvsStoreId);
          setValue("convenienceStore", cvsStoreName);
          getOldData();
        }
      },
      onError() {
        getOldData();
      },
    });

  //ANCHOR 刪除商品商品
  function deleteProduct(value) {
    removeItemFromCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemId: value,
      },
    });
  }

  const [removeItemFromCart, { loading: removeItemFromCartLoading }] =
    useMutation(REMOVE_ITEMFROMCART, {
      onCompleted({ removeItemFromCart }) {
        if (removeItemFromCart.success) {
          refetch();
          dispatch(cartAmountStartChanging());
        } else if (removeItemFromCart.message) {
          return Alert.notify(removeItemFromCart.message);
        }
      },
    });

  const classes = useStyles();

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <TableContainer component={Paper}>
        <LoadingModal
          loading={removeItemFromCartLoading || logisticsMapDataLoading}
        />
        <FormProvider {...createOrderForm}>
          <Box>
            <table
              style={{
                width: "100%",
                display: "block",
                minHeight: "30vh",
                overflow: "auto",
              }}
            >
              <tr
                style={{
                  display: "block",
                  padding: "5px 16px",
                  backgroundColor: "#C8C5C0",
                }}
              >
                <td
                  className={classes.tableTh}
                  style={{ width: isDownTablet ? "55%" : "30%" }}
                >
                  <Typography variant="p">商品資訊</Typography>
                </td>
                <td
                  className={classes.tableTh}
                  style={{
                    width: isDownTablet ? "40%" : "20%",
                    transform: isPhone ? "translateX(-10px)" : "",
                  }}
                >
                  <Typography
                    variant="p"
                    className={classes.CartInfoText}
                    style={{ transform: "translateX(140px)" }}
                  >
                    單件價格
                    <span className={classes.tableShowGrid}>
                      <Typography variant="p">&ensp;/&ensp;小計</Typography>
                    </span>
                  </Typography>
                </td>
                <div
                  className={classes.tableHiddenGrid}
                  style={{ width: "20%" }}
                >
                  <td className={classes.tableTh}>
                    <Typography variant="p">數量</Typography>
                  </td>
                </div>
                <div
                  className={classes.tableHiddenGrid}
                  style={{ width: "20%" }}
                >
                  <td className={classes.tableTh}>
                    <Typography variant="p">小計</Typography>
                  </td>
                </div>
                <td
                  className={classes.tableTh}
                  style={{ width: isDownTablet ? "5%" : "10%" }}
                />
              </tr>
              {data.cart.items.map((item) => (
                <tr className={classes.CartTr} key={item.id}>
                  {/* 商品圖片+名稱 */}
                  <div
                    style={{
                      display: "inline-block",
                      width: isDownTablet ? "55%" : "30%",
                    }}
                  >
                    <td>
                      <Box display="flex" alignItems="center">
                        <img
                          src={item.product.images[0].location}
                          width="40px"
                          height="50px"
                          style={{
                            objectFit: "contain",
                            padding: "5px 5px",
                            backgroundColor: "#F6F3EE",
                            borderRadius: "5px",
                          }}
                        />
                        <Box ml={2}>
                          <Typography
                            variant="body1"
                            className={classes.CartInfoText}
                          >
                            {item.product.name}
                          </Typography>
                        </Box>
                      </Box>
                      {isDownTablet && (
                        <TotalAmountPrice
                          productName={item.product.name}
                          productId={item.product.id}
                          amount={item.amount}
                          productPrice={item.product.price}
                        />
                      )}
                    </td>
                  </div>

                  {/* 單件價格/小計 */}
                  <div
                    style={{
                      display: "inline-block",
                      width: isDownTablet ? "40%" : "20%",
                    }}
                  >
                    <td style={{ display: "inline-block" }}>
                      <Typography className={classes.CartInfoText}>
                        Nt.{thousandsSeparator(item.product.price)}
                        <span className={classes.tableShowGrid}>
                          <Typography variant="p">
                            &ensp;/&ensp;Nt.
                            {thousandsSeparator(
                              item.product.price * item.amount
                            )}
                          </Typography>
                        </span>
                      </Typography>
                    </td>
                  </div>
                  {/* 數量  */}
                  {!isDownTablet && (
                    <TotalAmountPrice
                      totalPriceComponent
                      productName={item.product.name}
                      productId={item.product.id}
                      amount={item.amount}
                      productPrice={item.product.price}
                    />
                  )}
                  {/* 找到 全部的第一個 */}
                  <div
                    style={{
                      display: "inline-block",
                      width: isDownTablet ? "5%" : "10%",
                    }}
                  >
                    <td>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                          padding: "10px",
                        }}
                        onClick={() => deleteProduct(item.id)}
                      >
                        X
                      </button>
                    </td>
                  </div>
                </tr>
              ))}
            </table>
          </Box>
          <OrderMaterial />
        </FormProvider>
      </TableContainer>
    );
  } else {
    return null;
  }
}

function TotalAmountPrice({
  productName,
  productId,
  amount,
  productPrice,
  totalPriceComponent = false,
}) {
  const useStyles = makeStyles({
    tableHiddenGrid: {
      display: "inline-block",
    },
    CartInfoText: {
      letterSpacing: "1.2px",
    },
  });
  const classes = useStyles();
  const [productCount, setProductCount] = useState(1);

  useEffect(() => {
    if (Boolean(amount)) {
      setProductCount(amount);
    }
  }, [amount]);

  return (
    <>
      <div className={classes.tableHiddenGrid} style={{ width: "20%" }}>
        <td style={{ display: "inline-block" }}>
          <CountBox
            productName={productName}
            productId={productId}
            productPrice={productPrice}
            amount={amount}
            productCount={productCount}
            onChangeProductCount={(e) => setProductCount(e)}
          />
        </td>
      </div>
      {totalPriceComponent && (
        <div className={classes.tableHiddenGrid} style={{ width: "20%" }}>
          <td style={{ display: "inline-block" }}>
            <Typography className={classes.CartInfoText}>
              Nt.{thousandsSeparator(productPrice * productCount)}
            </Typography>
          </td>
        </div>
      )}
    </>
  );
}

//ANCHOR 商品數量
function CountBox({
  productName,
  productId,
  productPrice,
  amount,
  productCount,
  onChangeProductCount = () => {},
}) {
  const Alert = useAlert();
  const dispatch = useDispatch();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isSmPhone = useMediaQuery("(max-width:375px)");
  const useStyles = makeStyles({
    amount: {
      display: "flex",
      alignItems: "center",
    },
    amountMiner: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "20px",
      height: "20px",
      borderRight: "none",
      cursor: "pointer",
    },
    amountPlus: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "19px",
      height: "20px",
      borderLeft: "none",
      cursor: "pointer",
    },
    amountInput: {
      height: "20px",
      textAlign: "center",
      width: isDownTablet ? (isSmPhone ? "50px" : "90px") : "100px",
      border: "1px solid grey",
      "&:focus": {
        outline: "none",
      },
      "&:disabled": {
        backgroundColor: "white",
      },
    },
    tableHiddenGrid: {
      width: isDownTablet ? "0" : "",
      display: isDownTablet ? "none" : "inline-block",
    },
    CartInfoText: {
      letterSpacing: "1.2px",
    },
  });
  const classes = useStyles();

  function addTotal(value) {
    addItemToCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemInput: {
          productId,
          amount: Number(value),
        },
      },
    });
  }

  function lessTotal(value) {
    addItemToCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemInput: {
          productId,
          amount: Number(value),
        },
      },
    });
  }

  function changeTotal(value) {
    addItemToCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemInput: {
          productId,
          amount: Number(value),
        },
      },
    });
  }

  const [addItemToCart, { loading: addItemToCartLOading }] = useMutation(
    ADD_ITEMTOCART,
    {
      onCompleted({ addItemToCart }) {
        if (addItemToCart.success) {
          window.fbq("track", "AddToCart", {
            content_type: "product",
            content_ids: productId,
            content_name: productName,
            currency: "TWD",
            value: productPrice,
          });
          dispatch(cartAmountStartChanging());
          return null;
        } else if (addItemToCart.message) {
          onChangeProductCount(amount);
          return Alert.notify(addItemToCart.message);
        }
      },
    }
  );

  return (
    <Box>
      <Box className={classes.amount}>
        <button
          className={classes.amountMiner}
          onClick={() => {
            if (Number(productCount) > 1) {
              lessTotal(Number(productCount) - 1);
              onChangeProductCount(Number(productCount) - 1);
            }
          }}
          disabled={addItemToCartLOading}
        >
          <Typography>-</Typography>
        </button>
        <input
          type="text"
          value={productCount}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, "");
            if (onlyNums.length < 10) {
              changeTotal(
                Boolean(onlyNums) && onlyNums != 0 ? Number(onlyNums) : 1
              );
              onChangeProductCount(
                Boolean(onlyNums) && onlyNums != 0 ? Number(onlyNums) : 1
              );
            }
          }}
          className={classes.amountInput}
          disabled={addItemToCartLOading}
        />
        <button
          className={classes.amountPlus}
          onClick={() => {
            addTotal(Number(productCount) + 1);
            onChangeProductCount(Number(productCount) + 1);
          }}
          disabled={addItemToCartLOading}
        >
          <Typography>+</Typography>
        </button>
      </Box>
    </Box>
  );
}

/* ANCHOR 點數折抵規則 */
const pointsMessage = [`每50點可以折抵1元，可全額折抵`];

/* ANCHOR 送貨及付款方式 */
function OrderMaterial() {
  const { location } = useHistory();
  const theme = useTheme();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    textFieldCartSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
    cartSelectBox: {
      width: "100%",
      padding: "10px",
      marginTop: "0.3em",
      "&:focus": {
        outline: "none",
      },
    },
    cartSelectGrid: {
      width: isDownTablet ? "90%" : "30%",
      marginTop: isDownTablet ? "1em" : "0",
    },
    checkText: {
      letterSpacing: "0.1em",
      fontWeight: "bold",
    },
    checkButton: {
      display: "inline-block",
      backgroundColor: "#00c300",
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
      cursor: "pointer",
      color: "#fff",
      letterSpacing: "2px",
      textAlign: "center",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    shopButton: {
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
    },
    shopButtonText: {
      color: "#fff",
      textAlign: "center",
      fontSize: "16px",
    },
  });

  const classes = useStyles();
  const freeShippingRule = useFreeShippingRule();
  const shipmentFeeRule = useShipmentFeeRule();

  const { control, getValues, setValue } = useFormContext();

  const [getUser, { data: userData, loading: userLoading }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    }
  );
  useEffect(() => {
    getUser();
  }, [getUser]);

  const tipMessage = useMemo(() => {
    return [
      ` 購物金額滿NT$${freeShippingRule}元免運(含台灣離島)，`,
      `不到此金額一般宅配需加收NT$${shipmentFeeRule.homeDelivery}運費， 超商則需加收NT$${shipmentFeeRule.convenienceStore}運費，`,
      " 3~6天到貨，不含例假日。",
    ];
  }, [freeShippingRule, shipmentFeeRule]);

  const [goToLogisticsMap, { loading: logisticsMapLoading }] = useLazyQuery(
    GO_TO_LOGISTICSMAP,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ logisticsMap }) {
        if (logisticsMap) {
          window.location = logisticsMap;
        }
      },
    }
  );

  const chooseStore = async () => {
    const randomCode = getInvitationCode(7);
    localStorage.setItem(
      `@twenty-sevenCartSelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@twenty-sevenCartSelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        isCollection:
          getValues("paymentMethod") === "ISCOLLECTION" ? true : false,
        shipmentSubType: getValues("shipmentSubType"),
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };

  return (
    <form
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isDownTablet ? "column" : "row",
      }}
    >
      <LoadingModal loading={userLoading || logisticsMapLoading} />
      <RefetchCartDeliveryFee />
      <Box
        style={{
          width: isDownTablet ? "100%" : "80%",
          order: isDownTablet ? "2" : "",
        }}
      >
        <Box
          py={2}
          pl={2}
          display="flex"
          justifyContent="space-between"
          style={{ backgroundColor: "#C8C5C0" }}
        >
          <Typography variant="body1">選擇送貨及付款方式</Typography>
        </Box>
        <Box
          pt={5}
          pb={5}
          display="flex"
          justifyContent="space-around"
          style={{
            flexDirection: isDownTablet ? "column" : "row",
            alignItems: isDownTablet ? "center" : "",
            order: isDownTablet ? "1" : "",
          }}
        >
          {/* ANCHOR 收件人資訊 */}
          <Box className={classes.cartSelectGrid} style={{ display: "block" }}>
            <Box>
              <Controller
                control={control}
                name="recipient"
                rules={{
                  required: "請輸入收件人名字",
                  validate: (e) => {
                    if (
                      e &&
                      (checkSpecialSymbolsAndNumber.test(e) ||
                        halfShapeAndFullForm(e) < 4 ||
                        halfShapeAndFullForm(e) > 10)
                    ) {
                      return "收件人名字請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldComponent
                    {...field}
                    label="收件人名字"
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="recipientPhone"
                rules={{
                  required: "請輸入收件人手機",
                  validate: (e) => {
                    if (e && !checkMobile.test(e)) {
                      return "手機格式錯誤";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldComponent
                    {...field}
                    label="收件人手機"
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                      field.onChange(onlyNums);
                    }}
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Box>
                <label style={{ fontSize: "0.8em" }}>{`累積點數：${
                  userData?.user.point ?? 0
                }點`}</label>
                <br />
                <label style={{ fontSize: "0.8em" }}>輸入點數</label>
                <br />
                <Controller
                  control={control}
                  name="usePoint"
                  rules={{
                    validate: (e) => {
                      if (e && !isNaN(e) && Number(e) % 50 !== 0) {
                        return "輸入點數須為50的倍數";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <NumberTextField
                      {...field}
                      onBlur={(e) => {
                        const newValue =
                          e.target.value &&
                          !isNaN(e.target.value) &&
                          Number(e.target.value) > 0
                            ? Number(e.target.value) > userData?.user.point
                              ? userData?.user.point
                              : e.target.value
                            : "";
                        field.onChange(newValue);
                        field.onBlur(newValue);
                        if (newValue) {
                          setValue("discount", Math.floor(newValue / 50));
                        } else {
                          setValue("discount", 0);
                        }
                      }}
                      InputProps={{
                        className: classes.textFieldCartSelectBox,
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                      maxCount={userData?.user.point ? userData?.user.point : 0}
                      fullWidth
                    />
                  )}
                />
                {pointsMessage.map((item, index) => (
                  <Typography
                    key={item}
                    variant="caption"
                    component={isPhone ? "p" : "span"}
                    style={{
                      color: theme.palette.grey[500],
                      marginTop: index == 0 ? "0.8em" : 0,
                      fontSize: "0.6rem",
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          {/*ANCHOR 運送選擇 */}
          <Box className={classes.cartSelectGrid}>
            <Controller
              name="shipmentMethod"
              control={control}
              rules={{ required: "請選擇送貨方式" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="送貨方式"
                  listArray={shipmentMethoddList}
                  emptyLabel={"請選擇送貨方式"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            {tipMessage.map((item, index) => (
              <Typography
                key={item}
                variant="caption"
                component={isPhone ? "p" : "span"}
                style={{
                  color: theme.palette.grey[500],
                  marginTop: index == 0 ? "0.8em" : 0,
                  fontSize: "0.6rem",
                }}
              >
                {item}
              </Typography>
            ))}
            {/*ANCHOR 付款選擇 */}
            <Controller
              name="paymentMethod"
              control={control}
              rules={{ required: "請選擇付款方式" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="付款方式"
                  listArray={selectPaymentMethodList}
                  emptyLabel={"請選擇付款方式"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <FieldCache
            control={control}
            name="shipmentMethod"
            render={(shipmentMethod) => (
              <FieldCache
                control={control}
                name="paymentMethod"
                render={(paymentMethod) => {
                  if (paymentMethod && shipmentMethod === "CONVENIENCE_STORE") {
                    return (
                      <Box className={classes.cartSelectGrid}>
                        {/*ANCHOR 超商選擇 */}
                        <Controller
                          name="shipmentSubType"
                          control={control}
                          rules={{ required: "請選擇超商" }}
                          render={({ field, fieldState: { error } }) => (
                            <SelectComponent
                              {...field}
                              label="選擇超商"
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setValue("convenienceStore", "");
                              }}
                              listArray={shipmentSubTypedList}
                              emptyLabel={"請選擇超商"}
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                        />
                        <FieldCache
                          control={control}
                          name="shipmentSubType"
                          render={(shipmentSubType) => {
                            if (shipmentSubType) {
                              return (
                                <Controller
                                  control={control}
                                  name="convenienceStore"
                                  rules={{ required: "請選擇超商門市" }}
                                  render={({
                                    field,
                                    fieldState: { error },
                                  }) => (
                                    <TextFieldComponent
                                      {...field}
                                      id="cvsStoreNameTextField"
                                      label="超商門市"
                                      error={Boolean(error)}
                                      helperText={error?.message}
                                      fullWidth
                                      onClick={chooseStore}
                                      inputProps={{ readOnly: true }}
                                    />
                                  )}
                                />
                              );
                            } else return null;
                          }}
                        />
                      </Box>
                    );
                  } else if (
                    paymentMethod &&
                    shipmentMethod === "HOME_DELIVERY"
                  ) {
                    return (
                      <Box className={classes.cartSelectGrid}>
                        {/* ANCHOR 地址選擇 */}
                        <Controller
                          name="district"
                          control={control}
                          rules={{ required: "請選擇縣市" }}
                          render={({ field, fieldState: { error } }) => (
                            <SelectComponent
                              {...field}
                              label="縣市"
                              listArray={countiesList("zhTW")}
                              onChange={(e) => {
                                setValue("subdistrict", "");
                                field.onChange(e.target.value);
                              }}
                              emptyLabel={"請選擇縣市"}
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                        />
                        <FieldCache
                          control={control}
                          name="district"
                          render={(district) => (
                            <Controller
                              name="subdistrict"
                              control={control}
                              rules={{ required: "請選擇鄉鎮市區" }}
                              render={({ field, fieldState: { error } }) => (
                                <SelectComponent
                                  {...field}
                                  label="鄉鎮市區"
                                  listArray={districtsList(district)}
                                  emptyLabel={"請選擇鄉鎮市區"}
                                  error={Boolean(error)}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="address"
                          rules={{ required: "請輸入地址" }}
                          render={({ field, fieldState: { error } }) => (
                            <TextFieldComponent
                              {...field}
                              label="地址"
                              error={Boolean(error)}
                              helperText={error?.message}
                              fullWidth
                            />
                          )}
                        />
                      </Box>
                    );
                  } else return null;
                }}
              />
            )}
          />
        </Box>
      </Box>

      {/* ANCHOR 訂單資訊 */}
      <OrderInformation />
    </form>
  );
}

// ANCHOR 刷新運費
const RefetchCartDeliveryFee = memo(function RefetchCartDeliveryFee() {
  const dispatch = useDispatch();
  const cartAmountChanging = useSelector(
    (state) => state.cart.cartAmountChanging
  );
  const { control, setValue } = useFormContext();
  const shipmentMethod = useWatch({ control, name: "shipmentMethod" });
  const cart = useWatch({ control, name: "cart" });
  const freeShippingRule = useFreeShippingRule();
  const shipmentFeeRule = useShipmentFeeRule();

  const [getCart] = useLazyQuery(GET_CART_TOTALPRICE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ cart }) {
      if (cart) {
        setValue("cartTotalPrice", cart.totalPrice);
        dispatch(cartAmountEndChanging());
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    getCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
      },
    });
  }, []);

  useEffect(() => {
    if (cartAmountChanging) {
      getCart({
        variables: {
          id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        },
      });
    }
  }, [cartAmountChanging]);

  useEffect(() => {
    if (cart) {
      let itemArray = [];
      emptyArray(cart.items).forEach((item) => {
        itemArray.push(item.product.freeShipping);
      });
      if (cart.totalPrice >= freeShippingRule) {
        setValue("deliveryFee", 0);
      } else {
        if (shipmentMethod === "HOME_DELIVERY") {
          if (itemArray.indexOf(false) === -1) {
            setValue("deliveryFee", 0);
          } else {
            setValue("deliveryFee", shipmentFeeRule.homeDelivery);
          }
        } else if (shipmentMethod === "CONVENIENCE_STORE") {
          if (itemArray.indexOf(false) === -1) {
            setValue("deliveryFee", 0);
          } else {
            setValue("deliveryFee", shipmentFeeRule.convenienceStore);
          }
        } else if (itemArray.indexOf(false) === -1) {
          setValue("deliveryFee", 0);
        } else setValue("deliveryFee", 0);
      }
    }
  }, [cart, shipmentMethod, freeShippingRule, shipmentFeeRule, shipmentFeeRule]);
  return null;
});

function OrderInformation() {
  const Alert = useAlert();
  const history = useHistory();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles({
    textFieldCartSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
    cartSelectBox: {
      width: "100%",
      padding: "10px",
      marginTop: "0.3em",
      "&:focus": {
        outline: "none",
      },
    },
    cartSelectGrid: {
      width: isDownTablet ? "90%" : "30%",
      marginTop: isDownTablet ? "1em" : "0",
    },
    checkText: {
      letterSpacing: "0.1em",
      fontWeight: "bold",
    },
    couponCodeTextField: {
      display: "flex",
      flexDirection: "row",
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
      alignItems: "flex-end",
    },
    couponCodeTextFieldCheckButton: {
      width: 45,
      backgroundColor: "#00c300",
      cursor: "pointer",
      color: "#fff",
      letterSpacing: "2px",
      textAlign: "center",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    checkButton: {
      display: "inline-block",
      backgroundColor: "#00c300",
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
      cursor: "pointer",
      color: "#fff",
      letterSpacing: "2px",
      textAlign: "center",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    shopButton: {
      width: isDownTablet ? (isPhone ? "60%" : "40%") : "100%",
    },
    shopButtonText: {
      color: "#fff",
      textAlign: "center",
      fontSize: "16px",
    },
  });
  const classes = useStyles();
  const token = localStorage.getItem("@twenty-seven-webToken");
  const { control, handleSubmit, getValues, setValue } = useFormContext();
  const deliveryFee = useWatch({ control, name: "deliveryFee" });
  const discount = useWatch({ control, name: "discount" });
  const couponCodeDiscount = useWatch({ control, name: "couponCodeDiscount" });
  const cart = useWatch({ control, name: "cart" });
  const cartTotalPrice = useWatch({ control, name: "cartTotalPrice" });
  const [checkCouponCode, setCheckCouponCode] = useState("");

  const [createCart, { loading: createCartLoading }] = useMutation(
    CREATE_CART,
    {
      onCompleted({ createCart }) {
        localStorage.setItem("@twenty-seven-webCartId", createCart.id);
      },
      onError() {
        return null;
      },
    }
  );

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      async onCompleted({ createOrder }) {
        if (createOrder.success) {
          window.fbq("track", "InitiateCheckout");
          localStorage.removeItem("@twenty-seven-webCartId");
          await createCart();
          if (createOrder.order.paymentUrl) {
            window.location =
              createOrder.order.paymentUrl +
              "&paymentMethod=" +
              getValues("paymentMethod");
          } else {
            history.replace(`/my-account?clientBack=true`);
          }
        } else if (createOrder.message) {
          return Alert.notify(createOrder.message);
        }
      },
    }
  );

  function goPayment({
    recipient,
    recipientPhone,
    shipmentMethod,
    paymentMethod,
    shipmentSubType,
    cvsStoreId,
    convenienceStore,
    district,
    subdistrict,
    address,
    usePoint,
    couponCode,
  }) {
    if (!token) {
      return Alert.alert("", "請先登入後才可購買！", [
        {
          text: "確認",
          onClick: () => {
            history.push("/loginPage");
          },
        },
        { text: "取消", type: "outlined" },
      ]);
    }
    if (shipmentMethod === "HOME_DELIVERY") {
      createOrder({
        variables: {
          orderInput: {
            cartId: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
            shipmentInput: {
              recipient,
              recipientPhone,
              shipmentMethod: "HOME_DELIVERY",
              isCollection: paymentMethod === "ISCOLLECTION" ? true : false,
              district,
              subdistrict,
              address,
            },
            usePoint: usePoint ? Number(usePoint) : undefined,
            couponCode: couponCode ? couponCode : undefined,
          },
        },
      });
    } else {
      createOrder({
        variables: {
          orderInput: {
            cartId: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
            shipmentInput: {
              recipient,
              recipientPhone,
              shipmentMethod: "CONVENIENCE_STORE",
              isCollection: paymentMethod === "ISCOLLECTION" ? true : false,
              shipmentSubType,
              cvsStoreId,
              convenienceStore,
            },
            usePoint: usePoint ? Number(usePoint) : undefined,
            couponCode: couponCode ? couponCode : undefined,
          },
        },
      });
    }
  }

  const [getCoupon, { loading: couponLoading }] = useLazyQuery(GET_COUPON, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ coupon }) {
      if (coupon) {
        setValue("couponCode", checkCouponCode);
        setValue("couponCodeDiscount", coupon.discount);
      } else {
        setValue("couponCode", "");
        setValue("couponCodeDiscount", 0);
        Alert.notify("找不到優惠券");
      }
    },
  });

  return (
    <Box order="1" style={{ width: isDownTablet ? "100%" : "25%" }}>
      <LoadingModal
        loading={createCartLoading || createOrderLoading || couponLoading}
      />
      <Box
        py={2}
        pl={2}
        display="flex"
        justifyContent="space-between"
        style={{ backgroundColor: "#C8C5C0" }}
      >
        <Typography>訂單資訊</Typography>
      </Box>

      <Box
        pt={5}
        pb={5}
        px={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box display="flex" justifyContent="center" pb={1}>
          <Box className={classes.couponCodeTextField}>
            <TextFieldComponent
              value={checkCouponCode}
              onChange={(e) => setCheckCouponCode(e.target.value)}
              label="優惠券代碼"
              fullWidth
            />
            <Box width="5px" />
            <Button
              onClick={() =>
                getCoupon({ variables: { code: checkCouponCode } })
              }
              className={classes.couponCodeTextFieldCheckButton}
              disabled={!checkCouponCode}
            >
              確認
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box>
            <Typography className={classes.checkText}>
              小計 :&ensp;$
              {thousandsSeparator(cartTotalPrice)}元
            </Typography>
            <Typography className={classes.checkText}>
              運費 :&ensp;${deliveryFee}元
            </Typography>
            <Typography className={classes.checkText}>
              折抵 :&ensp;${discount + couponCodeDiscount}元
            </Typography>
          </Box>
          <div
            style={{
              width: "1px",
              height: "28px",
              backgroundColor: "gray",
              margin: isDownTablet ? (isPhone ? "0 1em" : "0 2em") : "0 1.5em",
            }}
          />
          <Box alignSelf="flex-start">
            <Typography className={classes.checkText}>
              合計 :&ensp;$
              {cartTotalPrice + deliveryFee - discount - couponCodeDiscount >= 0
                ? thousandsSeparator(
                    cartTotalPrice + deliveryFee - discount - couponCodeDiscount
                  )
                : 0}
              元
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={1}>
          <Button
            onClick={handleSubmit(goPayment)}
            className={classes.checkButton}
            disabled={!(emptyArray(cart?.items).length > 0)}
          >
            前往結帳
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" mt={1}>
          <Button
            color="primary"
            variant="contained"
            className={classes.shopButton}
            onClick={() => {
              history.push("/all-product");
            }}
          >
            <Typography className={classes.shopButtonText}>繼續購物</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

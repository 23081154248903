import { useEffect } from "react";
import { gql, useMutation } from "@apollo/react-hooks";

const CREATE_CART = gql`
  mutation createCart {
    createCart {
      id
    }
  }
`;

let cartId, creating;

export function CreateCart() {
  const [createCart] = useMutation(CREATE_CART, {
    onCompleted({ createCart }) {
      creating = false;
      localStorage.setItem("@twenty-seven-webCartId", createCart.id);
      cartId = createCart.id;
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (!cartId) {
      cartId = localStorage.getItem("@twenty-seven-webCartId");

      if (!cartId && !creating) {
        creating = true;
        createCart();
      }
    }
  }, []);

  return cartId || localStorage.getItem("@twenty-seven-webCartId");
}

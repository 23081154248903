import React, { useEffect, useMemo, useReducer, useState, memo } from "react";
import {
  FormLabel,
  CircularProgress,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  FormControl,
  MenuItem,
  FormHelperText,
  Select as Select2,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useForm, Controller } from "react-hook-form";

import moment from "moment-timezone";
import "moment/locale/zh-tw";

import { Card, CardContent, CardActions } from "../../components/Card";
import Grid from "../../components/Grid";
import Button from "../../components/Button";
import { Select } from "../../components/Form";
import Text from "../../components/Text";
import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import {
  thousandsSeparator,
  getInvitationCode,
  halfShapeAndFullForm,
} from "../../components/utils";
import LoadingModal from "../../components/LoadingModal";
import TextFieldComponent from "../../components/TextFieldComponent";
import { FieldCache } from "../../components/FieldCache";

import {
  shipmentMethodEnum,
  shipmentSubTypeEnum,
  orderStatusEnum,
  orderStatusList,
  shipmentSubTypedList,
} from "../../utils/localData";
import {
  getCounty,
  getDistrict,
  countiesList,
  districtsList,
} from "../../utils/countiesDistricts";
import emptyArray from "../../utils/emptyArray";
import {
  checkSpecialSymbolsAndNumber,
  checkMobile,
} from "../../utils/checkRegExp";

// ANCHOR 更改出貨地址
const UPDATE_SHIPMENTADDRESS = gql`
  mutation updateShipmentAddress(
    $orderId: Int!
    $shipmentAddressInput: OrderShipmentAddressInput!
  ) {
    updateShipmentAddress(
      orderId: $orderId
      shipmentAddressInput: $shipmentAddressInput
    ) {
      success
      message
    }
  }
`;

// ANCHOR 重新建立綠界出貨單
const CREATE_NEWECPAYSHIPMENTORDER = gql`
  mutation createNewEcpayShipmentOrder(
    $shipmentId: Int!
    $shipmentAddressInput: OrderShipmentAddressInput!
  ) {
    createNewEcpayShipmentOrder(
      shipmentId: $shipmentId
      shipmentAddressInput: $shipmentAddressInput
    ) {
      success
      message
    }
  }
`;

// ANCHOR 門市電子地圖
const GO_TO_LOGISTICSMAP = gql`
  query logisticsMap(
    $shipmentSubType: ShipmentSubType!
    $isCollection: Boolean!
    $randomCode: String!
    $returnUrl: String!
  ) {
    logisticsMap(
      shipmentSubType: $shipmentSubType
      isCollection: $isCollection
      randomCode: $randomCode
      returnUrl: $returnUrl
    )
  }
`;

// ANCHOR 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

const GET_ORDER = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      serialNumber
      user {
        id
        fullName
        mobile
      }
      totalPrice
      status
      items {
        id
        product {
          id
          images {
            location
          }
          name
          brand
          capacity
        }
        unitPrice
        amount
      }
      shipmentAddress {
        id
        recipient
        recipientPhone
        shipmentMethod
        # "運送子方式"
        shipmentSubType
        # "代收貨款"
        isCollection
        # "超商寄貨編號(前台僅顯示寄貨編號+驗證碼，欄位名稱為超商單號)"
        cvsPaymentNo
        # "超商驗證碼"
        cvsValidationNo
        convenienceStore
        district
        subdistrict
        address
        ecpayLogisticsId
        cvsPaymentNo
        cvsValidationNo
        createSuccess
        errorMessage
        logisticsRecords(admin: true) {
          id
          rtnCode
          rtnMsg
          statusUpdateTime
          createdAt
          updatedAt
        }
      }
      payments {
        id
        success
        tradeNumber
        paymentMethod
        totalPrice
        createdAt
      }
      # "使用的優惠券"
      usedCoupon {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_ORDERSTATUS = gql`
  mutation updateOrderStatus($id: Int!, $status: OrderStatus!) {
    updateOrderStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

// NOTE 關閉浮層
function _reducerDispatch(state, action) {
  switch (action.type) {
    case "open":
      return action.data;
    case "close":
      return null;
    default:
      return state;
  }
}
export default function OrderForm({ id }) {
  const Alert = useAlert();
  const history = useHistory();
  const useStyles = makeStyles({
    cartImg: {
      width: "50%",
      height: "100px",
      objectFit: "contain",
    },
  });

  const classes = useStyles();
  const [status, setStatus] = useState();

  const [updateShipmentAddressDialog, updateShipmentAddressDialogDispatch] =
    useReducer(_reducerDispatch, null);
  function _updateShipmentAddressDialogClose() {
    updateShipmentAddressDialogDispatch({ type: "close" });
  }

  const [getLogisticsMapData, { loading: logisticsMapDataLoading }] =
    useLazyQuery(GET_LOGISTICSMAPDATA, {
      onCompleted({ logisticsMapData }) {
        if (logisticsMapData) {
          const { cvsStoreId, cvsStoreName } = logisticsMapData;
          updateShipmentAddressDialogDispatch({
            type: "open",
            data: {
              cvsStoreId,
              cvsStoreName,
            },
          });
        }
      },
    });

  const [getOrder, { data, loading }] = useLazyQuery(GET_ORDER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ order }) {
      if (order) {
        const has = orderStatusList.find((item) => item.value === order.status);
        if (Boolean(has)) {
          setStatus(has);
        }
        const twentySevenConsoleOrderSelectStoreRandomCode =
          localStorage.getItem(
            `@twenty-sevenConsoleOrderSelectStoreRandomCode`
          );
        if (twentySevenConsoleOrderSelectStoreRandomCode) {
          getLogisticsMapData({
            variables: {
              randomCode: twentySevenConsoleOrderSelectStoreRandomCode,
            },
          });
        }
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getOrder({ variables: { id } });
    }
  }, [id, getOrder]);

  const [updateOrderStatus, { loading: updateOrderStatusLoading }] =
    useMutation(UPDATE_ORDERSTATUS, {
      onCompleted({ updateOrderStatus }) {
        if (updateOrderStatus.success) {
          getOrder({ variables: { id } });
          Alert.notify("更改成功");
        } else if (updateOrderStatus.message) {
          Alert.notify(updateOrderStatus.message);
        }
      },
    });

  function _save() {
    return Alert.notify("儲存成功", () => history.replace("/console/order"));
  }

  const order = useMemo(() => {
    if (data) return data.order;
    else return {};
  }, [data]);

  return (
    <Card>
      <LoadingModal
        loading={updateOrderStatusLoading || logisticsMapDataLoading}
      />
      <UpdateShipmentAddressDialog
        clickData={updateShipmentAddressDialog}
        onClose={(e) => {
          _updateShipmentAddressDialogClose();
          if (e === "mutation") {
            getOrder({ variables: { id } });
          }
        }}
      />
      <CardContent>
        <Grid container justifyContent="center">
          <Typography variant="h6">訂單資料</Typography>
        </Grid>
      </CardContent>
      <CardContent>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>訂單編號</FormLabel>
              <Text>&nbsp;{order.serialNumber}</Text>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>用戶名稱</FormLabel>
              <Text>&nbsp;{order.user?.fullName}</Text>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>手機號碼</FormLabel>
              <Text>&nbsp;{order.user?.mobile}</Text>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>總金額(含運費)</FormLabel>
              <Text>
                <Text>
                  &nbsp;NT$&nbsp;{thousandsSeparator(order.totalPrice)}
                </Text>
              </Text>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>建立日期</FormLabel>
              <Text>
                &nbsp;{moment(order.createdAt).format("YYYY-MM-DD HH:mm")}
              </Text>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <FormLabel>更新日期</FormLabel>
              <Text>
                &nbsp;{moment(order.updatedAt).format("YYYY-MM-DD HH:mm")}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>訂單狀態</FormLabel>
              <Select
                items={orderStatusList}
                value={status}
                onChange={(e, value) => {
                  Alert.alert(
                    "",
                    `確定要更改訂單狀態為\u2002${
                      orderStatusEnum[value.value]
                    }\u2002嗎？`,
                    [
                      {
                        text: "確認",
                        onClick: () => {
                          updateOrderStatus({
                            variables: { id, status: value.value },
                          });
                        },
                      },
                      { text: "取消", type: "outlined" },
                    ]
                  );
                }}
                disableClearable
                fullWidth
              />
            </Grid>
            {order.usedCoupon && (
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormLabel>使用的優惠券</FormLabel>
                <Text>&nbsp;{order.usedCoupon.name}</Text>
              </Grid>
            )}
            <Grid container item spacing={1}>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormLabel>收件人</FormLabel>
                <Text>&nbsp;{order.shipmentAddress?.recipient}</Text>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormLabel>收件人電話</FormLabel>
                <Text>&nbsp;{order.shipmentAddress?.recipientPhone}</Text>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormLabel>運送方式</FormLabel>
                <Text>
                  &nbsp;
                  {shipmentMethodEnum[order.shipmentAddress?.shipmentMethod]}
                  {order.shipmentAddress?.isCollection ? "-貨到付款" : ""}
                </Text>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <FormLabel>地址</FormLabel>
                <Text>
                  &nbsp;
                  {order.shipmentAddress?.shipmentMethod === "CONVENIENCE_STORE"
                    ? `${
                        order.shipmentAddress?.shipmentSubType
                          ? shipmentSubTypeEnum[
                              order.shipmentAddress?.shipmentSubType
                            ]
                          : ""
                      }${order.shipmentAddress?.convenienceStore}`
                    : order.shipmentAddress?.district
                    ? getCounty(order.shipmentAddress?.district) +
                      getDistrict(
                        order.shipmentAddress?.district,
                        order.shipmentAddress?.subdistrict
                      ) +
                      order.shipmentAddress?.address
                    : ""}
                </Text>
              </Grid>
            </Grid>
            {order.shipmentAddress?.shipmentMethod === "CONVENIENCE_STORE" && (
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <Typography>物流資料</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FormLabel>物流是否建立成功</FormLabel>
                  <Text>
                    &nbsp;
                    {order.shipmentAddress?.createSuccess ? "是" : "否"}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FormLabel>綠界物流編號</FormLabel>
                  <Text>&nbsp;{order.shipmentAddress?.ecpayLogisticsId}</Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FormLabel>超商寄貨編號</FormLabel>
                  <Text>&nbsp;{order.shipmentAddress?.cvsPaymentNo}</Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FormLabel>超商驗證碼</FormLabel>
                  <Text>&nbsp;{order.shipmentAddress?.cvsValidationNo}</Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FormLabel>錯誤訊息</FormLabel>
                  <Text>&nbsp;{order.shipmentAddress?.errorMessage}</Text>
                </Grid>
              </Grid>
            )}
            <Grid container item>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <Typography>訂單品項</Typography>
              </Grid>
              {order?.status === "READY_FOR_SHIPMENT" &&
                (order.shipmentAddress?.shipmentMethod === "CONVENIENCE_STORE"
                  ? !order.shipmentAddress?.createSuccess
                  : true) && (
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onPress={() => {
                        updateShipmentAddressDialogDispatch({
                          type: "open",
                          data: {
                            orderId: order.id,
                            fullName: order.user.fullName,
                            shipmentId: order.shipmentAddress.id,
                            shipmentMethod:
                              order.shipmentAddress?.shipmentMethod,
                            isCollection: order.shipmentAddress.isCollection,
                          },
                        });
                      }}
                    >
                      {order.shipmentAddress?.shipmentMethod === "HOME_DELIVERY"
                        ? "更改出貨地址"
                        : "重新建立物流單"}
                    </Button>
                  </Grid>
                )}
              <Table
                data={order.items}
                tableWidth={2}
                header={[
                  "商品圖片",
                  "商品名稱",
                  "品牌",
                  "容量",
                  "單價",
                  "數量",
                ]}
                columns={[
                  (item) => {
                    return (
                      <img
                        src={item.product.images[0].location}
                        className={classes.cartImg}
                      />
                    );
                  },
                  (item) => item.product.name,
                  (item) => item.product.brand,
                  (item) => item.product.capacity,
                  (item) => thousandsSeparator(item.unitPrice),
                  (item) => item.amount,
                ]}
              />
            </Grid>
            {emptyArray(order.shipmentAddress?.logisticsRecords).length > 0 && (
              <Grid container item>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <Typography>物流紀錄</Typography>
                </Grid>
                <Table
                  data={order.shipmentAddress.logisticsRecords}
                  tableWidth={2}
                  header={[
                    "狀態代碼",
                    "狀態訊息",
                    "狀態更新時間",
                    "建立時間",
                    "更新時間",
                  ]}
                  columns={[
                    "rtnCode",
                    "rtnMsg",
                    (item) =>
                      moment(item.statusUpdateTime).format("YYYY-MM-DD HH:mm"),
                    (item) => moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
                    (item) => moment(item.updatedAt).format("YYYY-MM-DD HH:mm"),
                  ]}
                />
              </Grid>
            )}
            {emptyArray(order.payments).length > 0 && (
              <Grid container item>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <Typography>付款紀錄</Typography>
                </Grid>
                <Table
                  data={order.payments}
                  tableWidth={2}
                  header={[
                    "付款單號",
                    "付款狀態",
                    "付款方式",
                    "總金額",
                    "付款時間",
                  ]}
                  columns={[
                    (item) => item.tradeNumber,
                    (item) => (item.success ? "成功" : "失敗"),
                    (item) =>
                      item.paymentMethod === "CREDIT_CARD" ? "信用卡" : "ATM",
                    (item) => item.totalPrice,
                    (item) => moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
                  ]}
                />
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
      {false && (
        <CardActions style={{ width: "100%" }}>
          <Button color="primary" variant="contained" onPress={_save}>
            儲存
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

const UpdateShipmentAddressDialog = memo(function UpdateShipmentAddressDialog({
  clickData,
  onClose = () => {},
}) {
  const { location } = useHistory();
  const { notify } = useAlert();
  const { control, setValue, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      fullName: "",
      recipient: "",
      recipientPhone: "",
      shipmentSubType: "",
      cvsStoreId: "",
      convenienceStore: "",
      district: "",
      subdistrict: "",
      address: "",
      shipmentMethod: "",
      orderId: "",
      shipmentId: "",
      isCollection: "",
    },
  });

  useEffect(() => {
    if (clickData && clickData?.cvsStoreId && clickData?.cvsStoreName) {
      const twentySevenConsoleOrderSelectStoreGetValues = localStorage.getItem(
        "@twenty-sevenConsoleOrderSelectStoreGetValues"
      );
      if (twentySevenConsoleOrderSelectStoreGetValues) {
        const {
          fullName,
          recipient,
          recipientPhone,
          shipmentSubType,
          shipmentMethod,
          shipmentId,
          isCollection,
        } = JSON.parse(twentySevenConsoleOrderSelectStoreGetValues);
        setValue("fullName", fullName);
        setValue("recipient", recipient);
        setValue("recipientPhone", recipientPhone);
        setValue("shipmentSubType", shipmentSubType);
        setValue("shipmentMethod", shipmentMethod);
        setValue("shipmentId", shipmentId);
        setValue("isCollection", isCollection);
        setValue("cvsStoreId", clickData?.cvsStoreId);
        setValue("convenienceStore", clickData?.cvsStoreName);
      }
      setTimeout(() => {
        localStorage.removeItem(
          `@twenty-sevenConsoleOrderSelectStoreRandomCode`
        );
        localStorage.removeItem(
          `@twenty-sevenConsoleOrderSelectStoreGetValues`
        );
      }, 0);
    } else {
      setValue("fullName", clickData?.fullName);
      setValue("shipmentMethod", clickData?.shipmentMethod);
      setValue("orderId", clickData?.orderId);
      setValue("shipmentId", clickData?.shipmentId);
      setValue("isCollection", clickData?.isCollection);
    }
  }, [clickData]);

  const [updateShipmentAddress, { loading: updateShipmentAddressLoading }] =
    useMutation(UPDATE_SHIPMENTADDRESS, {
      onCompleted({ updateShipmentAddress }) {
        if (updateShipmentAddress.success) {
          notify("出貨地址更改成功！");
          onClose("mutation");
          reset();
        } else if (updateShipmentAddress.message) {
          notify(updateShipmentAddress.message);
        }
      },
    });

  const [
    createNewEcpayShipmentOrder,
    { loading: createNewEcpayShipmentOrderLoading },
  ] = useMutation(CREATE_NEWECPAYSHIPMENTORDER, {
    onCompleted({ createNewEcpayShipmentOrder }) {
      if (createNewEcpayShipmentOrder.success) {
        notify("重新建立物流單成功！");
        onClose("mutation");
        reset();
      } else if (createNewEcpayShipmentOrder.message) {
        notify(createNewEcpayShipmentOrder.message);
      }
    },
  });

  function _submit(data) {
    if (data.shipmentMethod === "HOME_DELIVERY") {
      const shipmentAddressInput = {
        recipient: data.recipient,
        recipientPhone: data.recipientPhone,
        shipmentMethod: data.shipmentMethod,
        isCollection: data.isCollection,
        district: data.district,
        subdistrict: data.subdistrict,
        address: data.address,
      };
      updateShipmentAddress({
        variables: { orderId: data.orderId, shipmentAddressInput },
      });
    } else {
      const shipmentAddressInput = {
        recipient: data.recipient,
        recipientPhone: data.recipientPhone,
        shipmentMethod: data.shipmentMethod,
        isCollection: data.isCollection,
        cvsStoreId: data.cvsStoreId,
        convenienceStore: data.convenienceStore,
      };
      createNewEcpayShipmentOrder({
        variables: { shipmentId: data.shipmentId, shipmentAddressInput },
      });
    }
  }

  function _onClose(value) {
    if (!updateShipmentAddressLoading || !createNewEcpayShipmentOrderLoading) {
      onClose(value);
      reset();
    }
  }

  const [goToLogisticsMap, { loading: logisticsMapLoading }] = useLazyQuery(
    GO_TO_LOGISTICSMAP,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ logisticsMap }) {
        if (logisticsMap) {
          window.location = logisticsMap;
        }
      },
    }
  );

  const chooseStore = async () => {
    const randomCode = getInvitationCode(7);
    localStorage.setItem(
      `@twenty-sevenConsoleOrderSelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@twenty-sevenConsoleOrderSelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        isCollection: getValues("isCollection"),
        shipmentSubType: getValues("shipmentSubType"),
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };

  return (
    <Dialog
      open={Boolean(clickData)}
      onClose={_onClose}
      fullWidth
      maxWidth="xs"
    >
      <LoadingModal loading={logisticsMapLoading} />
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        {clickData?.shipmentMethod === "HOME_DELIVERY"
          ? "更改出貨地址"
          : "重新建立物流單"}
        <IconButton
          onClick={_onClose}
          size="small"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="recipient"
          rules={{
            required: "請輸入收件人名字",
            validate: (e) => {
              if (
                e &&
                (checkSpecialSymbolsAndNumber.test(e) ||
                  halfShapeAndFullForm(e) < 4 ||
                  halfShapeAndFullForm(e) > 10)
              ) {
                return "收件人名字請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldComponent
              {...field}
              label="收件人名字"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="recipientPhone"
          rules={{
            required: "請輸入收件人手機",
            validate: (e) => {
              if (e && !checkMobile.test(e)) {
                return "手機格式錯誤";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldComponent
              {...field}
              label="收件人手機"
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                field.onChange(onlyNums);
              }}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        {clickData?.shipmentMethod === "HOME_DELIVERY" ? (
          <>
            <Controller
              name="district"
              control={control}
              rules={{ required: "請選擇縣市" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="縣市"
                  listArray={countiesList("zhTW")}
                  onChange={(e) => {
                    setValue("subdistrict", "");
                    field.onChange(e.target.value);
                  }}
                  emptyLabel={"請選擇縣市"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <FieldCache
              control={control}
              name="district"
              render={(district) => (
                <Controller
                  name="subdistrict"
                  control={control}
                  rules={{ required: "請選擇鄉鎮市區" }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectComponent
                      {...field}
                      label="鄉鎮市區"
                      listArray={districtsList(district)}
                      emptyLabel={"請選擇鄉鎮市區"}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="address"
              rules={{ required: "請輸入地址" }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldComponent
                  {...field}
                  label="地址"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </>
        ) : (
          <>
            <Controller
              name="shipmentSubType"
              control={control}
              rules={{ required: "請選擇超商" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="選擇超商"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue("convenienceStore", "");
                  }}
                  listArray={shipmentSubTypedList}
                  emptyLabel={"請選擇超商"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <FieldCache
              control={control}
              name="shipmentSubType"
              render={(shipmentSubType) => {
                if (shipmentSubType) {
                  return (
                    <Controller
                      control={control}
                      name="convenienceStore"
                      rules={{ required: "請選擇超商門市" }}
                      render={({ field, fieldState: { error } }) => (
                        <TextFieldComponent
                          {...field}
                          id="cvsStoreNameTextField"
                          label="超商門市"
                          error={Boolean(error)}
                          helperText={error?.message}
                          fullWidth
                          onClick={chooseStore}
                          inputProps={{ readOnly: true }}
                        />
                      )}
                    />
                  );
                } else return null;
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onPress={handleSubmit(_submit)}
              disabled={
                updateShipmentAddressLoading ||
                createNewEcpayShipmentOrderLoading
              }
            >
              {updateShipmentAddressLoading ||
              createNewEcpayShipmentOrderLoading
                ? "處理中..."
                : "確定"}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onPress={_onClose}>
              關閉
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});

function SelectComponent({
  label,
  error,
  helperText,
  listArray = [],
  emptyLabel,
  ...props
}) {
  const useStyles = makeStyles({
    selectMenuItemSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <label style={{ fontSize: "0.8em" }}>{label}</label>
      <br />
      <FormControl fullWidth error={typeof error === "boolean" ? error : false}>
        <Select2
          {...props}
          className={classes.selectMenuItemSelectBox}
          displayEmpty
        >
          {emptyLabel && (
            <MenuItem
              className={classes.selectMenuItemSelectBox}
              value=""
              disabled
            >
              {emptyLabel}
            </MenuItem>
          )}
          {listArray.map((item) => (
            <MenuItem
              className={classes.selectMenuItemSelectBox}
              value={item.value}
              key={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select2>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

import { configureStore } from "@reduxjs/toolkit";

import advertisement from "./advertisement";
import consolePriority from "./consolePriority";
import console from "./console";
import cart from "./cart";

export default configureStore({
  reducer: {
    advertisement,
    consolePriority,
    console,
    cart,
  },
});

import React, { useState } from "react";

import Table from "../components/Table";

export default function HomePage() {
  const [conut, setCount] = useState(null);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
    </div>
  );
}

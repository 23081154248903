import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
  ButtonBase,
  MenuItem,
  useTheme,
  IconButton,
  Menu,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const useStyles = makeStyles({
  gridContent: {
    padding: 8,
  },
  gridArea: {
    "& > *": { padding: 8 },
  },
  gridItem: {
    "& > *": { padding: 4 },
  },
  textInputFlex: {
    flex: 1,
  },
});

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

export default function ContentEditor({
  data,
  onChangeData = () => {},
  lockFirst,
}) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState();
  const [openAddMenu, setOpenAddMenu] = useState();
  const [details, setDetails] = useState(data.details);
  const [itemIndex, setItemIndex] = useState();
  useEffect(() => {
    setDetails(data.details);
  }, [data.details]);
  const desciptionTypeArray = [
    {
      title: "標題",
      type: "HEADING",
      newItem: {
        type: "HEADING",
        // "內文"
        body: "",
      },
    },
    {
      title: "內文",
      type: "PARAGRAPH",
      newItem: {
        type: "PARAGRAPH",
        // "內文"
        body: "",
      },
    },
    {
      title: "圖片",
      type: "IMAGE",
      newItem: {
        type: "IMAGE",
      },
    },
    {
      title: "影片",
      type: "YOUTUBE_VIDEO",
      newItem: {
        type: "YOUTUBE_VIDEO",
        // "內文"
        body: "",
      },
    },
  ];
  useEffect(() => {
    _onChangeData();
  }, [details]);
  const menuArray = [
    {
      title: "刪除此項",
      fn: (deleteIndex) => {
        setDetails((e) => e.filter((item, index) => index !== deleteIndex));
      },
    },
    {
      title: "插入上方",
      openAddMenu: (currentTarget) => {
        setOpenAddMenu(currentTarget);
      },
    },
  ];
  const addMenuArray = [
    {
      title: "新增標題",
      newItem: {
        type: "HEADING",
        // "內文"
        body: "",
      },
    },
    {
      title: "新增內文",
      newItem: {
        type: "PARAGRAPH",
        // "內文"
        body: "",
      },
    },
    {
      title: "新增圖片",
      newItem: {
        type: "IMAGE",
      },
    },
    {
      title: "新增影片",
      newItem: {
        type: "YOUTUBE_VIDEO",
        // "內文"
        body: "",
      },
    },
  ];
  function _onChangeData() {
    onChangeData(details);
  }

  function swapItems(index1, index2) {
    details[index1] = details.splice(index2, 1, details[index1])[0];
  }
  return (
    <Grid container item classes={{ root: classes.gridArea }}>
      <Grid container item spacing={1} alignItems="center">
        <Grid item>
          <Typography>選擇新增項目</Typography>
        </Grid>
        {desciptionTypeArray.map((item) => (
          <Grid item key={item.type}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDetails((e) => [...e, item.newItem])}
            >
              {item.title}
            </Button>
          </Grid>
        ))}
      </Grid>
      {Boolean(data) &&
        details.map((item, index) => (
          <Grid container item alignItems="center" key={index}>
            <ContentArea
              data={item}
              detailIndex={index}
              onChangeData={(ee) => {
                setDetails((e) => [...e.fill(ee, index, index + 1)]);
                _onChangeData();
              }}
            />
            <IconButton
              fullWidth
              onClick={() => {
                swapItems(index, index - 1);
                setItemIndex(index);
              }}
              disabled={index === 0}
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              fullWidth
              onClick={() => {
                swapItems(index, index + 1);
                setItemIndex(index);
              }}
              disabled={index === details.length-1}
            >
              <ArrowDownward />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setOpenMenu(e.currentTarget);
                setItemIndex(index);
              }}
              disabled={Boolean(lockFirst && index === 0)}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        ))}
      <Menu
        open={Boolean(openAddMenu)}
        anchorEl={openAddMenu}
        onClose={() => setOpenAddMenu()}
      >
        {addMenuArray.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                details.splice(itemIndex, 0, item.newItem);
                setOpenAddMenu();
              }}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={() => setOpenMenu()}
      >
        {menuArray.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {

              item.fn && item.fn(itemIndex);
              item.openAddMenu && item.openAddMenu(e.currentTarget);
              setOpenMenu();
            }}
            disabled={Boolean(
              lockFirst &&
                ((itemIndex === 1 && index === 2) ||
                  (details.length - 1 === itemIndex && index === 3))
            )}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}

function ContentArea({ data, onChangeData = () => {}, detailIndex }) {
  const classes = useStyles();
  const theme = useTheme();
  const useStylesProductDesciption = makeStyles({
    heading: {
      ...theme.typography.h6,
      textAlign: "center",
    },
  });
  const classes2 = useStylesProductDesciption();
  const [body, setBody] = useState(data.body);
  function _onChangeData(item) {
    onChangeData({
      type: item.type,
      body: item.body,
    });
  }
  useEffect(() => {
    setBody(data.body);
  }, [data.body]);

  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    onCompleted({ uploadImage }) {
      delete uploadImage.__typename;
      setBody(uploadImage.location);
      _onChangeData({
        type: "IMAGE",
        body: uploadImage.location,
      });
    },
  });
  switch (data.type) {
    case "HEADING":
      return (
        <TextField
          variant="outlined"
          inputProps={{ className: classes2.heading }}
          value={body}
          onChange={(e) => setBody(e.target.value)}
          onBlur={(e) =>
            _onChangeData({
              type: "HEADING",
              body: e.target.value,
            })
          }
          placeholder="請輸入標題"
          classes={{ root: classes.textInputFlex }}
        />
      );
    case "PARAGRAPH":
      return (
        <TextField
          variant="outlined"
          multiline
          value={body}
          onChange={(e) => setBody(e.target.value)}
          onBlur={(e) =>
            _onChangeData({
              type: "PARAGRAPH",
              body: e.target.value,
            })
          }
          placeholder="請輸入內文"
          classes={{ root: classes.textInputFlex }}
        />
      );
    case "IMAGE":
      return (
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <ButtonBase
            style={{ flex: 1, height: 200 }}
            component="label"
            htmlFor={`uploadImage${detailIndex}`}
          >
            {body ? (
              <img
                src={body}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <CloudUploadIcon />
            )}
          </ButtonBase>
          <>
            <input
              id={`uploadImage${detailIndex}`}
              type="file"
              accept="image/*"
              onChange={(event) => {
                const {
                  target: {
                    validity,
                    files: [file],
                  },
                } = event;

                if (validity.valid) {
                  uploadImage({ variables: { image: file } });
                }
              }}
              style={{
                display: "none",
              }}
            />
          </>
        </Box>
      );
    case "YOUTUBE_VIDEO":
      return (
        <TextField
          variant="outlined"
          placeholder="請輸入youtube網址"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          onBlur={(e) =>
            _onChangeData({
              type: "YOUTUBE_VIDEO",
              body: e.target.value,
            })
          }
          classes={{ root: classes.textInputFlex }}
        />
      );
    default:
      return null;
  }
}

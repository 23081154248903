import { useAlert } from "./components/Alert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { useHistory } from "react-router-dom";

export default function useLayoutSettings() {
  const Alert = useAlert();
  const history = useHistory();
  function signOut() {
    Alert.alert("", "確定要登出嗎？", [
      { text: "取消", type: "outlined" },
      {
        text: "確認",
        onClick: () => {
          localStorage.removeItem("@twenty-seven-webConsoleToken");
          localStorage.removeItem("@twenty-seven-webConsole-ProductSearchTerm");
          localStorage.removeItem(
            "@twenty-seven-webConsole-ProductPageSizeObject"
          );
          localStorage.removeItem(
            "@twenty-seven-webConsole-ProductCategoryIdObject"
          );
          localStorage.removeItem("@twenty-seven-webConsole-ProductPage");
          history.replace("/console/login");
        },
      },
    ]);
  }
  const setting = {
    navbar: {
      buttons: [
        //{title:"名稱",icon:圖標,onPress:要做的函式} 最多三個
        // {
        //   title: "通知",
        //   icon: NotificationsIcon,
        //   onPress: () => Alert.alert("", "通知一號", [{ text: "確認" }]),
        // },
        // {
        //   title: "設定",
        //   icon: SettingsIcon,
        //   onPress: () => Alert.alert("", "設定一號", [{ text: "確認" }]),
        // },
        {
          title: "登出",
          icon: ExitToAppIcon,
          onPress: signOut,
        },
      ],
    },
    drawer: {
      logo: require("./images/Logo.png").default,
      items: [
        // title:"標題",route:"路由路線",subItems:[{同前面}]，沒有子層的話就不要給subItems，有subItems時不會有route
        // {
        //   //首頁，沒意外不用動
        //   title: "首頁",
        //   route: "/console/home",
        // },
        // {
        //   title: "打卡系統",
        //   subItems: [
        //     { title: "打卡", route: "/console/clockIn" },
        //     { title: "出勤", route: "/console/attendanceSheet" },
        //   ],
        // },
        {
          title: "輪播圖管理",
          route: "/console/carousel",
        },
        {
          title: "廣告管理",
          route: "/console/advertisement",
        },
        {
          title: "商品分類管理",
          route: "/console/category",
        },
        {
          title: "商品管理",
          route: "/console/product",
        },
        {
          title: "會員管理",
          route: "/console/member",
        },
        {
          title: "優惠券管理",
          route: "/console/coupon",
        },
        {
          title: "點數管理",
          route: "/console/point",
        },
        {
          title: "購物活動管理",
          route: "/console/event",
        },
        {
          title: "訂單管理",
          route: "/console/order",
        },
        {
          title: "已結帳的訂單",
          route: "/console/checkoutOrder",
        },
        {
          title: "頁面管理",
          route: "/console/picture",
        },
        // {
        //   title: "出貨管理",
        //   route: "/console/shipment",
        // },
      ],
    },
  };
  return setting;
}

import React from "react";
import SliderImg from "../components/SliderImg";
import {
  makeStyles,
  Box,
  Typography,
  Container,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import ProductTitle from "../components/ProductTitle";
import { useAlert } from "../components/Alert";

const GET_PAGECONTENT = gql`
  query pageContent($area: PageContentArea) {
    pageContent(area: $area) {
      id
      imageUrl
    }
  }
`;

export default function Index() {
  return (
    <Box position="relative">
      {SliderImg()}
      <IndexAbout style={{ marginTop: "1000px" }} />
    </Box>
  );
}

function IndexAbout() {
  const Alert = useAlert();
  const isPhone = useMediaQuery("(max-width:600px)");
  const useStyles = makeStyles({
    titleBox: {
      display: "flex",
      justifyContent: "center",
    },
    bannerTextBox: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
    title: {
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "2px",
        height: "70%",
        left: "-5%",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#504F4F",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "2px",
        height: "70%",
        right: "-5%",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#504F4F",
      },
    },
    bannerText: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      "&::before": {
        content: '""',
        position: "absolute",
        left: "-10%",
        top: "0",
        bottom: 0,
        width: "3px",
        backgroundColor: "#fff",
      },
    },
  });

  const { data, loading } = useQuery(GET_PAGECONTENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      area: "ABOUT_US",
    },
    onError() {
      return null;
    },
  });

  const classes = useStyles();
  return (
    <Box>
      <Box py={3} px={1}>
        <Box py={4} px={2}>
          <Box className={classes.titleBox}>
            <Typography
              variant="subtitle1"
              style={{ color: "#504F4F", display: "inline-block" }}
              align={isPhone ? "left" : "center"}
              className={classes.title}
            >
              讓每日的沐浴時光都是高級享受，
              <br sytle={{ display: isPhone ? "block" : "" }} />
              最真誠的與自己對話。
              <br />
              當我沉浸在香氣中一切變得如此美好。
            </Typography>
          </Box>
        </Box>
        {loading ? (
          <Box
            display="flex"
            height="400px"
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
              transform="none"
            />
          </Box>
        ) : (
          <Box height="400px" position="relative">
            <img
              src={data?.pageContent.imageUrl}
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            />
            <Box className={classes.bannerTextBox}>
              <Box className={classes.bannerText}>
                <Typography
                  variant={isPhone ? "h5" : "h3"}
                  style={{ color: "#fff" }}
                >
                  關於我們
                </Typography>
                <Typography
                  variant={isPhone ? "h6" : "h5"}
                  style={{ color: "#fff" }}
                >
                  About us
                </Typography>
                <Typography
                  variant={isPhone ? "body2" : "h6"}
                  style={{ color: "#fff" }}
                >
                  客服電話:04-7372728
                </Typography>
                <Typography
                  variant={isPhone ? "body2" : "h6"}
                  style={{ color: "#fff" }}
                >
                  客服官方LINE:@hic0389f
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* 下面抬頭 */}
      <ProductTitle title="品牌理念" subtitle="Member Benefits" />

      {/* 內文 */}
      <AboutContent />
      <ProductTitle title="About us" />
      <AboutContentEN />
      {/* --------------- */}
      <IconList />
    </Box>
  );
}

// ---------

function AboutContent() {
  const aboutContent = [
    " 貳柒的品牌創立，",
    "源自一隻舉世聞名的海龜—被吸管插住呼吸孔的海龜。",
    "身為香氛製造業者，塑料與我們息息相關，然而，我不禁反思",
    "「當人們汲汲營營享受時尚時，對於環境的重視是否缺乏了？」",
    "我們冀望的，是創造一個對於環境充滿善意的香氛品牌。　",
    "於是貳柒誕生了。",
    "而我們也相信，小小的作為，能凝聚出大大的希望。",
  ];

  return (
    <Container maxWidth="md">
      <Box
        textAlign="center"
        px={2}
        py={1}
        mb={1}
        display="flex"
        flexDirection="column"
        letterSpacing="1.5px"
        fontWeight="bold"
      >
        {aboutContent.map((item, index) => (
          <Typography
            key={index}
            variant="subtitle1"
            style={{ color: "#7E7D7D", padding: "0.2em 0" }}
          >
            {item}
          </Typography>
        ))}
      </Box>
    </Container>
  );
}

function AboutContentEN() {
  const aboutContent = [
    " Twenty-seven was a fragrance brand based on environmental friendly especially Ocean.",
    "We are looking for a perfect way",
    " which can make human and nature coexist harmoniously.",
  ];

  return (
    <Container maxWidth="md">
      <Box
        textAlign="center"
        px={2}
        py={1}
        mb={4}
        display="flex"
        flexDirection="column"
        letterSpacing="1.5px"
        fontWeight="bold"
      >
        {aboutContent.map((item, index) => (
          <Typography
            key={index}
            variant="subtitle1"
            style={{
              color: "#7E7D7D",
              padding: "0.1em 0",
              fontFamily: "GENBKBASR",
            }}
          >
            {item}
          </Typography>
        ))}
      </Box>
    </Container>
  );
}

// -----------

function IconList() {
  const iconArr = [
    {
      iconImg: require("../images/lineIcon.png").default,
      href: "https://line.naver.jp/ti/p/@951ouuyz",
    },
    {
      iconImg: require("../images/igIcon.png").default,
      href: "https://www.instagram.com/2chi.tw/",
    },
    {
      iconImg: require("../images/fbIcon.png").default,
      href: "https://www.facebook.com/2chi.tw",
    },
  ];

  const useStyles = makeStyles({
    SocialLink: {
      padding: "10px",
      "&+&": {
        marginLeft: "0.6em",
      },
    },
    SocialIconImg: {
      display: "inline-block",
      width: "40px",
      height: "40px",
      objectFit: "contain",
      verticalAlign: "middle",
    },
  });
  // style={{display:'flex',justifyContent:"center"}}
  const classes = useStyles();
  return (
    <Box
      py={3}
      px={4}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      bgcolor="#f5f4ed"
    >
      {iconArr.map((item, index) => (
        <a
          key={index}
          target="_blank"
          rel="noopener"
          href={item.href}
          className={classes.SocialLink}
        >
          <img src={item.iconImg} className={classes.SocialIconImg} />
        </a>
      ))}
    </Box>
  );
}

import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import Button from "../../components/Button";
import { Card } from "../../components/Card";
import PictureFloatingLayer from "./PictureFloatingLayer";

const GET_PAGECONTENTS = gql`
  query pageContents {
    pageContents {
      id
      area
    }
  }
`;

export default function PictureTable() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    id: null,
    open: false,
    area: "",
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { data, refetch, loading } = useQuery(GET_PAGECONTENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });
  function getArea(value) {
    switch (value) {
      case "ABOUT_US":
        return "關於我們";
      case "ALL_PRODUCT":
        return "全部商品";
      default:
        return null;
    }
  }
  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <Table
          data={data.pageContents}
          header={["名稱", "編輯"]}
          columns={[
            (item) => getArea(item.area),
            (item) => {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  onPress={(e) => {
                    e.stopPropagation();
                    changeData("open", true);
                    changeData("id", item.id, true);
                  }}
                >
                  編輯
                </Button>
              );
            },
          ]}
        />
        <PictureFloatingLayer
          open={newData.open}
          id={newData.id}
          onFloatingwindowClose={(value) => {
            changeData("open", false);
            changeData("id", undefined, true);
            if (value === "refetch") {
              refetch();
            }
          }}
        />
      </Card>
    );
  } else {
    return null;
  }
}

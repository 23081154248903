import React, { useState, useEffect, useRef, memo } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  CircularProgress,
  Grid,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setEndAdvertisementChanging } from "../redux/advertisement";

import AspectRatioBox from "../components/AspectRatioBox";

const GET_ADVERTISEMENTENABLE = gql`
  query advertisementEnable {
    advertisementEnable {
      id
      enable
    }
  }
`;

const GET_ADVERTISEMENTS = gql`
  query advertisements {
    advertisements {
      contents {
        id
        location
      }
    }
  }
`;

export default memo(function NewsScreen() {
  const advertisementChanging = useSelector(
    (state) => state.advertisement.advertisementChanging
  );

  const dataForm = useForm({
    defaultValues: {
      image: undefined,
    },
  });
  const { setValue } = dataForm;

  const { data } = useQuery(GET_ADVERTISEMENTENABLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { loading } = useQuery(GET_ADVERTISEMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ advertisements }) {
      if (advertisements) {
        const newAdvertisements = [];
        advertisements.contents.forEach((item) => {
          newAdvertisements.push({
            ...item,
          });
        });
        for (let i = newAdvertisements.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [newAdvertisements[i], newAdvertisements[j]] = [
            newAdvertisements[j],
            newAdvertisements[i],
          ];
        }
        setValue("image", newAdvertisements[0]);
      }
    },
  });

  return (
    <FormProvider {...dataForm}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={
          data && typeof advertisementChanging === "boolean"
            ? data.advertisementEnable.enable && advertisementChanging
            : false
        }
        style={{ zIndex: 100 }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: 0,
          }}
        >
          {loading ? (
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <>
              <CountDownTwo />
              <NewsPost />
            </>
          )}
        </Box>
      </Dialog>
    </FormProvider>
  );
});

function CountDown({ idleTime, onFinish = () => {} }) {
  const [count, setCount] = useState(idleTime);
  const countDownRef = useRef();
  function cleanCountDown() {
    if (countDownRef.current) {
      clearInterval(countDownRef.current);
    }
  }
  useEffect(() => {
    countDownRef.current = setInterval(() => {
      setCount((e) => e - 1);
    }, 1000);
    return cleanCountDown;
  }, []);
  useEffect(() => {
    if (count < 1) {
      onFinish();
      cleanCountDown();
    }
  }, [count]);
  return `廣告結束倒數計時${count}秒鐘.....`;
}

function CountDownTwo() {
  const dispatch = useDispatch();
  const [lockClose, setLockClose] = useState(true);

  return (
    <DialogTitle
      style={{
        display: "flex",
        flex: 1,
        justifyContent: !lockClose && "flex-end",
      }}
    >
      {lockClose ? (
        <CountDown idleTime={5} onFinish={() => setLockClose(false)} />
      ) : (
        "\u2002"
      )}
      {!lockClose && (
        <IconButton onClick={() => dispatch(setEndAdvertisementChanging())}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

function NewsPost() {
  const { control } = useFormContext();
  const image = useWatch({ control, name: "image" });

  return (
    <DialogContent
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        marginBottom: 13,
      }}
    >
      <Box
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          paddingTop: "56.25%",
        }}
      >
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          src={image?.location}
        />
      </Box>
    </DialogContent>
  );
}

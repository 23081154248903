import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Collapse,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

export default function PcMenuList({ menuListArray, bannerPorp }) {
  const { pathname } = useLocation();
  const {
    menuSelectIndex,
    setMenuSelectIndex,
    menuImg,
    setMenuImg,
    menuLockImg,
    setMenuLockImg,
    menuImgNumber,
    setMenuImgNumber,
    menuImgHistory,
    setMenuImgHistory,
    setFirstOpen,
    firstOpen,
    open,
    setOpen,
    menuColor,
    setMenuColor,
  } = bannerPorp;

  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:460px)");

  const history = useHistory();

  const handleClick = (index) => {
    if (firstOpen) {
      if (menuColor === index) {
        setMenuColor(undefined);
        setOpen(false);
      } else {
        setMenuColor(index);
        setOpen(true);
      }
    } else {
      if (menuColor === index) {
        setMenuColor(undefined);
        setOpen(false);
      } else {
        setMenuColor(index);
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    setMenuColor(undefined);
    setOpen(false);
  }, [pathname]);

  const useStyles = makeStyles({
    navMenu: {
      listStyle: "none",
    },
  });

  const useStyles2 = makeStyles((theme) => ({
    root: {
      width: "30%",
      maxWidth: 120,
      backgroundColor: theme.palette.background.paper,
    },
    ListControl: {
      backgroundColor: "#F6F3EE",
      padding: "0",
    },
    List: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
      position: "absolute",
      zIndex: "9999",
    },
    ListItem: {
      width: "100px",
      "&+&": {
        marginLeft: "2em",
      },
    },
    ListItemLink: {
      fontSize: "1em",
      textAlign: "center",
      fontWeight: "bold",
      cursor: "pointer",
      transition: "all 0.3s",
      borderRadius: "50px",
      padding: "5px 2px",
      color: "#4f4f4f",

      "&:hover": {
        color: "#fff",
        fontWeight: "900",
      },
    },
    secondListItem: {
      width: "120px",
      padding: "0",
      backgroundColor: "rgba(0,0,0,0.1)",
      opacity: "0.5",
    },
    subListControl: {
      color: "#000",
      display: "flex",
      justifyContent: "center",
    },
    BannerText: {
      position: "absolute",
      height: "400px",
      left: "0",
      right: "0",
      textAlign: "center",
      zIndex: "-1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bannerContentTitle: {
      color: "#fff",
      letterSpacing: "6px",
      fontFamily: "GENBKBASR",
      fontSize: "2.5em",
    },
    bannerContentSubTitle: {
      color: "#fff",
      letterSpacing: "3.2px",
      fontSize: "0.88em",
      fontFamily: "GENBKBASR",
    },
    bannerContentLabel: {
      display: "block",
      color: "#fff",
      letterSpacing: "2px",
      lineHeight: "1.5",
      fontWeight: "300",
      fontSize: "0.95em",
    },
  }));

  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    // pc模式的 list
    <List style={{ padding: "0", display: isDownTablet ? "none" : "block" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap={isPhone ? "wrap" : "nowrap"}
        className={classes2.ListControl}
      >
        {menuListArray.map((item, index) => (
          <ListItem
            className={classes2.ListItem}
            onClick={() => {
              if (item.second[0]) {
                setFirstOpen(true);
                setMenuImg(true);
                setMenuLockImg(false);
                setMenuSelectIndex(index);
                setMenuImgHistory(index);
                localStorage.removeItem("@ProductsSearch");
              } else {
                setFirstOpen(false);
                setOpen(false);
                setMenuImg(true);
                setMenuImgNumber(index);
                setMenuLockImg(true);
                setMenuImgHistory(index);
                if (item.label === "全部商品") {
                  history.push("/all-product");
                }
                localStorage.removeItem("@ProductsSearch");
              }
            }}
          >
            {/* ANCHOR 顏色收回時變回透明, 每次跳轉到新頁面 , 搜尋的box 跟  menulist都預設收回狀態 */}
            <ListItemText
              primary={item.label}
              onClick={() => {
                handleClick(index);
              }}
              className={classes2.ListItemLink}
              style={{
                backgroundColor:
                  pathname.replace("-page", "").split("/")[1] &&
                  pathname.replace("-page", "").split("/")[1] === item.enName
                    ? "#7E7D7D"
                    : "transparent",
                color:
                  pathname.replace("-page", "").split("/")[1] &&
                  pathname.replace("-page", "").split("/")[1] === item.enName
                    ? "#fff"
                    : "#212121",
              }}
            />
          </ListItem>
        ))}
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes2.List}>
          {/* ANCHOR 子層 */}
          {
            <Box display="flex" alignItems="center">
              {typeof menuSelectIndex === "number" &&
                menuListArray[menuSelectIndex]?.second.map((item) => {
                  return (
                    <Box className={classes2.subListControl} width="100%">
                      <Container>
                        <ListItem
                          className={classes.secondListItem}
                          onClick={() => {
                            setMenuImg(false);
                            setMenuLockImg(false);
                            history.push(
                              `/${menuListArray[menuSelectIndex].enName}/${item.enName}-page`
                            );
                          }}
                        >
                          {/* 子選單 */}
                          <ListItemText
                            className="secondListItemLink hoverVerticalLines"
                            style={
                              pathname.replace("-page", "").split("/")[
                                pathname.replace("-page", "").split("/")
                                  .length - 1
                              ] === item.enName
                                ? {
                                    backgroundColor: "#A9A9A9",
                                    borderRadius: 15,
                                  }
                                : null
                            }
                            primary={item.label}
                          />
                        </ListItem>
                      </Container>
                    </Box>
                  );
                })}
            </Box>
          }
        </List>
      </Collapse>
    </List>
  );
}

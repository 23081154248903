import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/react-hooks";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import { Select } from "../components/Form";

// ANCHOR 點數轉換規則
const GET_TRANSFORMPOINTRULE = gql`
  query transformPointRule {
    transformPointRule {
      id
      point
      price
    }
  }
`;

// ANCHOR 儲存點數轉換規則
const SAVE_TRANSFORMPOINTRULE = gql`
  mutation saveTransformPointRule($price: Float!, $point: Int!) {
    saveTransformPointRule(price: $price, point: $point) {
      success
      message
    }
  }
`;

// ANCHOR 贈送點數規則
const GET_SENDPOINTRULE = gql`
  query sendPointRule($type: SendPointRuleType) {
    sendPointRule(type: $type) {
      id
      point
      type
    }
  }
`;

// ANCHOR 儲存點數轉換規則
const SAVE_SENDPOINTRULE = gql`
  mutation saveSendPointRule($point: Int!, $type: SendPointRuleType!) {
    saveSendPointRule(point: $point, type: $type) {
      success
      message
    }
  }
`;

const checkTransformPointRule = ["price", "point"];
const checkSendPointRule = ["sendPointRulePoint"];
export default function PointPage() {
  const { notify } = useAlert();
  const { control, trigger, setValue, getValues } = useForm({
    defaultValues: {
      price: "",
      point: "",
      sendPointRulePoint: "",
      type: { label: "註冊贈送", value: "REGISTER" },
    },
  });

  const { loading } = useQuery(GET_TRANSFORMPOINTRULE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ transformPointRule }) {
      if (transformPointRule) {
        const { price, point } = transformPointRule;
        setValue("price", price);
        setValue("point", point);
      }
    },
    onError() {
      return null;
    },
  });

  const [saveTransformPointRule, { loading: saveTransformPointRuleLoading }] =
    useMutation(SAVE_TRANSFORMPOINTRULE, {
      onCompleted({ saveTransformPointRule }) {
        if (saveTransformPointRule.success) {
          notify("儲存成功！");
        } else if (saveTransformPointRule.message) {
          notify(saveTransformPointRule.message);
        }
      },
    });

  async function _saveTransformPointRule() {
    let hadError = 0;
    for (const item of checkTransformPointRule) {
      const result = await trigger(item);
      if (!result) {
        hadError += 1;
      }
    }
    if (hadError > 0) {
      return;
    }
    const price = getValues("price");
    const point = getValues("point");
    saveTransformPointRule({
      variables: { price: parseFloat(price), point: parseInt(point, 10) },
    });
  }

  const { loading: sendPointRuleLoading } = useQuery(GET_SENDPOINTRULE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      type: "REGISTER",
    },
    onCompleted({ sendPointRule }) {
      if (sendPointRule) {
        setValue("sendPointRulePoint", sendPointRule.point);
      }
    },
  });

  const [saveSendPointRule, { loading: saveSendPointRuleLoading }] =
    useMutation(SAVE_SENDPOINTRULE, {
      onCompleted({ saveSendPointRule }) {
        if (saveSendPointRule.success) {
          notify("儲存成功！");
        } else if (saveSendPointRule.message) {
          notify(saveSendPointRule.message);
        }
      },
    });

  async function _saveSendPointRule() {
    let hadError = 0;
    for (const item of checkSendPointRule) {
      const result = await trigger(item);
      if (!result) {
        hadError += 1;
      }
    }
    if (hadError > 0) {
      return;
    }
    const sendPointRulePoint = getValues("sendPointRulePoint");
    const type = getValues("type");
    saveSendPointRule({
      variables: { point: parseInt(sendPointRulePoint, 10), type: type.value },
    });
  }

  return (
    <Card>
      <LoadingModal
        loading={saveTransformPointRuleLoading || saveSendPointRuleLoading}
      />
      <CardContent>
        <Typography>點數管理</Typography>
      </CardContent>
      <CardContent>
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                name="price"
                rules={{
                  required: "請輸入消費金額",
                  validate: (e) => {
                    if (isNaN(e)) {
                      return "必須是數字";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="消費金額"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                name="point"
                rules={{
                  required: "請輸入贈送點數",
                  validate: (e) => {
                    if (isNaN(e)) {
                      return "必須是數字";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="贈送點數"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end">
          {!loading && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => _saveTransformPointRule()}
            >
              儲存
            </Button>
          )}
        </Grid>
      </CardActions>
      <CardContent>
        <Typography>註冊贈送點數</Typography>
      </CardContent>
      <CardContent>
        {sendPointRuleLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                name="type"
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Grid item xs={12}>
                    <Select
                      label="送點數規則類型"
                      items={[{ label: "註冊贈送", value: "REGISTER" }]}
                      value={value}
                      onChange={(e, value) => {
                        onChange(value);
                      }}
                      disabled
                      error={error}
                      fullWidth
                    />
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                name="sendPointRulePoint"
                rules={{
                  required: "請輸入贈送點數",
                  validate: (e) => {
                    if (isNaN(e)) {
                      return "必須是數字";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="贈送點數"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end">
          {!loading && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => _saveSendPointRule()}
            >
              儲存
            </Button>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, Box, useMediaQuery, Button } from "@material-ui/core";

export default function IconListFooter() {
  return (
    <Box>
      <IconList />
    </Box>
  );
}

const iconArr = [
  {
    iconImg: require("../images/lineIcon.png").default,
    href: "https://line.naver.jp/ti/p/@951ouuyz",
  },
  {
    iconImg: require("../images/igIcon.png").default,
    href: "https://www.instagram.com/2chi.tw/",
  },
  {
    iconImg: require("../images/fbIcon.png").default,
    href: "https://www.facebook.com/2chi.tw",
  },
];

function IconList() {
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const { pathname } = useLocation();
  const useStyles = makeStyles({
    SocialLink: {
      padding: "10px",
      display: "inline-block",
      "&+&": {
        marginLeft: "0.6em",
      },
    },
    SocialIconImg: {
      display: "inline-block",
      width: "40x",
      height: "40px",
      objectFit: "contain",
      verticalAlign: "middle",
      transition: "all 0.1s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  });
  const classes = useStyles();

  if (pathname === "/") {
    return "";
  } else {
    return (
      <Box
        px={4}
        py={1.5}
        alignItems="cetner"
        justifyContent="flex-end"
        style={{
          backgroundColor: "#F6F3EE",
          display: isDownTablet ? "flex" : "flex",
        }}
      >
        {iconArr.map((item, index) => {
          return (
            <Box ml={1} pl={1}>
              <Button
                onClick={() =>
                  window.open(iconArr[index].href, "_blank", "noopener")
                }
                className={classes.SocialLink}
              >
                <img
                  src={iconArr[index].iconImg}
                  className={classes.SocialIconImg}
                />
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  }
}

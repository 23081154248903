import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useForm, Controller } from "react-hook-form";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import { Card, CardContent, CardActions } from "../../components/Card";
import LoadingModal from "../../components/LoadingModal";

const GET_USER = gql`
  query user($id: Int) {
    user(id: $id) {
      id
      username
      mobile
      email
      fullName
      nickname
      dateOfBirth
      point
      admin
      createdAt
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: Int, $userInput: UserInput!, $console: Boolean!) {
    updateUser(id: $id, userInput: $userInput, console: $console) {
      success
      message
    }
  }
`;

const SET_USERASADMIN = gql`
  mutation setUserAsAdmin($id: Int!, $admin: Boolean) {
    setUserAsAdmin(id: $id, admin: $admin) {
      success
      message
    }
  }
`;

export default function MemberForm({ id, onWatchPointRecords = () => {} }) {
  const Alert = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      fullName: "",
      mobile: "",
      email: "",
      dateOfBirth: null,
      point: "",
      totalPoints: "",
    },
  });

  const [getUser, { data, loading }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ user }) {
      if (user) {
        setValue("fullName", user.fullName);
        setValue("mobile", user.mobile);
        setValue("email", user.email);
        setValue("dateOfBirth", user.dateOfBirth);
        setValue("point", user.point);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getUser({ variables: { id } });
    }
  }, [id]);

  function _submit(dataInput) {
    const { point, totalPoints, ...oldDataInput } = dataInput;
    const userInput = {
      ...oldDataInput,
    };
    updateUser({ variables: { id, userInput, console: true } });
  }

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted({ updateUser }) {
        if (updateUser.success) {
          getUser({ variables: { id } });
          Alert.notify("更改成功");
        } else if (updateUser.message) {
          Alert.notify("更改失敗，請再次嘗試！");
        }
      },
    }
  );
  const [setUserAsAdmin, { loading: setUserAsAdminLoading }] = useMutation(
    SET_USERASADMIN,
    {
      onCompleted({ setUserAsAdmin }) {
        if (setUserAsAdmin.success) {
          getUser({ variables: { id } });
          Alert.notify("升級成功");
        } else if (setUserAsAdmin.message) {
          Alert.notify(setUserAsAdmin.message);
        }
      },
    }
  );
  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <LoadingModal loading={updateUserLoading || setUserAsAdminLoading} />
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>會員資料</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  Alert.alert("", "確定要升為管理員嗎？", [
                    {
                      text: "確認",
                      onClick: () =>
                        setUserAsAdmin({ variables: { id, admin: true } }),
                    },
                    { text: "取消", type: "outlined" },
                  ]);
                }}
                disabled={data.user.admin}
              >
                升為管理員
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="fullName"
                rules={{
                  required: "名稱為必填選項",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="名稱"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="mobile"
                rules={{
                  required: "手機號碼為必填選項",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="手機號碼"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "電子信箱為必填選項",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="電子信箱"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: "生日為必填" }}
                render={({
                  field: { ref, ...other },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="生日"
                    format="yyyy/MM/dd"
                    openTo="year"
                    {...other}
                    error={error}
                    maxDate={new Date()}
                    helperText={error && error.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="point"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} label="累積點數" fullWidth disabled />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-end",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onWatchPointRecords}
                >
                  查看點數紀錄
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(_submit)}
                >
                  儲存
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  } else {
    return null;
  }
}

import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { useAlert } from "../components/Alert";
import TabView from "../components/TabView";
import CouponTable from "./component/CouponTable";
import CouponForm from "./component/CouponForm";

export default function CouponListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["列表", "新增"],
    select: 0,
    dataObject: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  return (
    <Grid>
      <TabView
        paper
        tabs={newData.tabViewData}
        index={newData.select}
        onChange={(e) => {
          if (newData.select === 2 && e !== 2) {
            Alert.alert("", "確定離開編輯？", [
              {
                text: "確定",
                onClick: () => {
                  changeData("dataObject", null);
                  newData.tabViewData.splice(2, 1);
                  changeData("tabViewData", newData.tabViewData);
                  changeData("select", e, true);
                },
              },
              { text: "取消", type: "outlined" },
            ]);
          } else {
            changeData("dataObject", undefined);
            changeData("select", e, true);
          }
        }}
      >
        <CouponTable
          onEditSelect={(item) => {
            changeData("dataObject", item);
            newData.tabViewData.push("編輯");
            changeData("tabViewData", newData.tabViewData);
            changeData("select", 2, true);
          }}
        />
        <CouponForm
          onChangeSelect={() => {
            changeData("dataObject", undefined);
            changeData("tabViewData", ["列表", "新增"]);
            changeData("select", 0, true);
          }}
        />
        <CouponForm
          id={newData.dataObject && newData.dataObject.id}
          onChangeSelect={(item) => {
            changeData("dataObject", item);
            changeData("tabViewData", ["列表", "新增"]);
            changeData("select", 0, true);
          }}
        />
      </TabView>
    </Grid>
  );
}

export function addThousandSeparator(number) {
  const reg = /(\d)(?=(?:\d{3})+$)/g;
  let newNumber = number.split(".");
  return (
    newNumber[0].replace(reg, "$1,") +
    (typeof newNumber[1] !== "undefined" ? "." + newNumber[1] : "")
  );
}
export function thousandsSeparator(number) {
  if (typeof number === "number" || typeof number === "string") {
    return Number(number).toLocaleString("en-US");
  } else {
    return 0;
  }
}
export function removeThousandSeparator(number) {
  return String(number).replace(/,/g, "");
}
export function splitArray(array = [], number) {
  let newArray = [];
  let index = 0;
  while (index < array.length) {
    newArray.push(array.slice(index, (index += number)));
  }
  return newArray;
}
export function checkImageSize(value) {
  if (value < 1048576) return false;
  else {
    const imageSize = Math.floor((value / 1048576).toFixed(1) * 10) / 10;
    if (imageSize > 4.4) return true;
    else return false;
  }
}
// ANCHOR 產生邀請碼
export function getInvitationCode(length) {
  const randomChars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
  let invitationCode = "";
  for (let i = 0; i < length; i++) {
    invitationCode += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return invitationCode;
}
// ANCHOR 計算字串位元數
export function halfShapeAndFullForm(data) {
  let totalNumber = 0;
  function matchRule(rule) {
    return data.match(rule) ? data.match(rule).length : 0;
  }
  if (data) {
    totalNumber =
      matchRule(/[\u4e00-\u9fa5]/g) * 2 +
      matchRule(/[\u0000-\u00ff]/g) +
      matchRule(/[\uff00-\uffff]/g) * 2;
  }
  return totalNumber;
}

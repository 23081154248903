import { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment-timezone";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import Table from "../../components/Table";
import { thousandsSeparator } from "../../components/utils";
import LoadingModal from "../../components/LoadingModal";

// ANCHOR 優惠券列表
const GET_COUPONS = gql`
  query coupons($latest: Boolean, $pageSize: Int, $page: Int) {
    coupons(latest: $latest, pageSize: $pageSize, page: $page) {
      count
      pageCount
      contents {
        id
        name
        code
        startTime
        endTime
        revenue
        createdAt
        updatedAt
      }
    }
  }
`;

// ANCHOR 隱藏優惠券
const HIDE_COUPON = gql`
  mutation hideCoupon($id: Int!) {
    hideCoupon(id: $id) {
      success
      message
    }
  }
`;

export default function CouponTable({ onEditSelect = () => {} }) {
  const { alert, notify } = useAlert();
  const [page, setPage] = useState(1);
  const {
    data: couponsData,
    refetch: couponsRefetch,
    loading: couponsLoading,
  } = useQuery(GET_COUPONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize: 10,
      page: 1,
    },
  });
  const [hideCoupon, { loading: hideCouponLoading }] = useMutation(
    HIDE_COUPON,
    {
      onCompleted({ hideCoupon }) {
        if (hideCoupon.success) {
          couponsRefetch();
        } else if (hideCoupon.message) {
          notify(hideCoupon.message);
        }
      },
    }
  );
  return (
    <Card>
      <LoadingModal loading={hideCouponLoading} />
      <CardContent>
        <Typography>優惠券數量：{couponsData?.coupons.count}</Typography>
      </CardContent>
      {couponsLoading ? (
        <CardContent>
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Table
              paper
              data={couponsData ? couponsData.coupons.contents : []}
              header={[
                "名稱",
                "代碼",
                "開始時間",
                "結束時間",
                "營業額",
                "建立日期",
                "更新日期",
                "刪除",
              ]}
              tableWidth={3}
              columns={[
                "name",
                "code",
                (item) => moment(item.startTime).format("YYYY-MM-DD HH:mm"),
                (item) => moment(item.endTime).format("YYYY-MM-DD HH:mm"),
                (item) => thousandsSeparator(item.revenue),
                (item) => moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
                (item) => moment(item.updatedAt).format("YYYY-MM-DD HH:mm"),
                (item) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("", `確定要刪除優惠券名稱「${item.name}」嗎？`, [
                        {
                          text: "確認",
                          onClick: () =>
                            hideCoupon({ variables: { id: item.id } }),
                        },
                        { text: "取消", type: "outlined" },
                      ]);
                    }}
                  >
                    刪除
                  </Button>
                ),
              ]}
              onPress={(item) => onEditSelect(item)}
            />
          </CardContent>
          <CardActions>
            <Grid container justifyContent="center">
              <Pagination
                count={
                  couponsData
                    ? couponsData.coupons.pageCount
                      ? couponsData.coupons.pageCount
                      : 1
                    : 1
                }
                size="large"
                color="primary"
                page={page}
                onChange={(e, n) => setPage(n)}
              />
            </Grid>
          </CardActions>
        </>
      )}
    </Card>
  );
}

import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useDispatch, batch } from "react-redux";

import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import Button from "../../components/Button";
import { Card, CardActions } from "../../components/Card";
import LoadingFloating from "../../components/LoadingFloating";

import {
  setConsolePriorityId,
  openConsolePriority,
} from "../../redux/consolePriority";

import CategoryAddFloatingLayer from "./CategoryAddFloatingLayer";

import PriorityFloatingLayer from "./PriorityFloatingLayer";

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation hideCategory($id: Int!) {
    hideCategory(id: $id) {
      success
      message
    }
  }
`;

export default function CategoryTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();
  const dispatch = useDispatch();
  const [newData, setNewData] = useState({
    id: undefined,
    open: false,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { data, refetch, loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  const [hideCategory, { loading: deleteCategoryLoading }] = useMutation(
    DELETE_CATEGORY,
    {
      onCompleted({ hideCategory }) {
        if (hideCategory.success) {
          refetch();
          Alert.notify("刪除成功");
        } else if (hideCategory.message) {
          Alert.notify(hideCategory.message);
        }
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <LoadingFloating loading={deleteCategoryLoading} />
        <PriorityFloatingLayer />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onPress={() => changeData("open", true, true)}
          >
            新增主分類
          </Button>
        </CardActions>
        <CategoryAddFloatingLayer
          open={newData.open}
          id={newData.id}
          onFloatingwindowClose={(value) => {
            changeData("open", false);
            changeData("id", undefined, true);
            if (value === "refetch") {
              refetch();
            }
          }}
        />
        <Table
          paper
          data={data.categories.filter(
            (item) => item.name !== "會員專區" && item.name !== "限定商品"
          )}
          header={["系列", "編輯", "操作"]}
          columns={[
            "name",
            (item) => {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  onPress={(e) => {
                    e.stopPropagation();
                    changeData("open", true);
                    changeData("id", item.id, true);
                  }}
                >
                  編輯
                </Button>
              );
            },
            (item) => {
              return (
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={(event) => {
                        event.stopPropagation();
                        batch(() => {
                          dispatch(setConsolePriorityId(item.id));
                          dispatch(openConsolePriority());
                        });
                      }}
                      disabled={
                        item.name === "限定商品" || item.name === "會員專區"
                      }
                    >
                      順序
                    </Button>
                  </Grid>
                  <Grid item></Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    onPress={(e) => {
                      e.stopPropagation();
                      Alert.alert("", "確定要刪除嗎？", [
                        {
                          text: "確認",
                          onClick: () =>
                            hideCategory({
                              variables: { id: Number(item.id) },
                            }),
                        },
                        { text: "取消", type: "outlined" },
                      ]);
                    }}
                    disabled={
                      item.name === "限定商品" || item.name === "會員專區"
                    }
                  >
                    刪除
                  </Button>
                </Grid>
              );
            },
          ]}
          onPress={(item) => {
            onEditSelect(item);
          }}
        />
      </Card>
    );
  } else {
    return null;
  }
}

import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";

import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import { Card, CardContent } from "../../components/Card";

const GET_CAROUSELS = gql`
  query carousels {
    carousels {
      id
      section
    }
  }
`;

export default function CarouselsListTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();

  const { data, loading } = useQuery(GET_CAROUSELS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  function getSection(value) {
    switch (value) {
      case "HOME_PAGE":
        return "首頁";
    }
  }

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <CardContent>
          <Table
            data={data.carousels}
            header={["項目", "建立時間", "最後更新時間"]}
            tableWidth={1}
            columns={[
              (item) => getSection(item.section),
              (item) => moment(item.createdAt).format("YYYY/MM/DD"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
}

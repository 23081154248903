import "./App.css";
import React, { createElement, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { colors, CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { zhTW } from "date-fns/locale";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import MyAccount from "./pages/MyAccount";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import SearchPage from "./pages/SearchPage";

import { AlertProvider } from "./components/Alert";
import Layout from "./components/Layout";

import Index from "./pages/Index";

import ProductListPage from "./pages/ProductListPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import AllProduct from "./pages/AllProduct";
import MemberTerms from "./pages/MemberTerms";
import Cart from "./pages/Cart";
// 後台
import ConsoleHomePage from "./console/HomePage";
import ConsoleLoginPage from "./console/LoginPage";
import ConsoleClockInPage from "./console/ClockInPage";
import ConsoleAttendanceSheetPage from "./console/AttendanceSheetPage";
import ConsoleCategoryListPage from "./console/CategoryListPage";
import ConsoleAdvertisementListPage from "./console/AdvertisementListPage";
import ConsoleProductListPage from "./console/ProductListPage";
import ConsoleCarouselListPage from "./console/CarouselListPage";
import ConsoleMemberListPage from "./console/MemberListPage";
import ConsolePointPage from "./console/PointPage";
import ConsoleEventListPage from "./console/EventListPage";
import ConsoleOrderListPage from "./console/OrderListPage";
import ConsoleCheckoutOrderPage from "./console/CheckoutOrderPage";
import ConsoleShipmentListPage from "./console/ShipmentListPage";
import ConsolePictureListPage from "./console/PictureListPage";
import ShipmentDetailPage from "./console/ShipmentDetailPage";
import ConsoleCouponListPage from "./console/CouponListPage";

import WebSiteLayout from "./WebSiteLayout";
import { CreateCart } from "./components/CreateCart";

// 新增
const route = [
  // { path: "/", component: LoginPage },
  // 單打斜線 代表預設為首頁
  { path: "/", component: Index },
  { path: "/loginPage", component: LoginPage },
  { path: "/my-account", component: MyAccount },
  { path: "/cart-page", component: Cart },
  { path: "/register", component: RegisterPage },
  { path: "/forget-password", component: ForgetPasswordPage },
  { path: "/change-password", component: ChangePasswordPage },
  { path: "/search-page", component: SearchPage },
  { path: `/:name/:name2-page`, component: ProductListPage },
  { path: "/product-detail/:id", component: ProductDetailPage },
  { path: "/all-product", component: AllProduct },
  { path: "/member-terms", component: MemberTerms },
];

const consoleHomeRoute = "/console/product";
const consoleLoginRoute = "/console/login";
const consoleRoute = [
  { path: "/console/home", page: ConsoleHomePage },
  { path: "/console/clockIn", page: ConsoleClockInPage },
  { path: "/console/attendanceSheet", page: ConsoleAttendanceSheetPage },
  { path: "/console/category", page: ConsoleCategoryListPage },
  { path: "/console/advertisement", page: ConsoleAdvertisementListPage },
  { path: "/console/product", page: ConsoleProductListPage },
  { path: "/console/carousel", page: ConsoleCarouselListPage },
  { path: "/console/member", page: ConsoleMemberListPage },
  { path: "/console/point", page: ConsolePointPage },
  { path: "/console/event", page: ConsoleEventListPage },
  { path: "/console/order", page: ConsoleOrderListPage },
  { path: "/console/checkoutOrder", page: ConsoleCheckoutOrderPage },
  { path: "/console/shipment", page: ConsoleShipmentListPage },
  { path: "/console/picture", page: ConsolePictureListPage },
  { path: "/console/shipment-detail/:id", page: ShipmentDetailPage },
  { path: "/console/coupon", page: ConsoleCouponListPage },
];

export default function App() {
  CreateCart();
  const theme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
        size: "small",
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiButtonGroup: {
        disableElevation: true,
      },
      MuiContainer: {
        maxWidth: "md",
      },
      MuiSelect: {
        variant: "outlined",
      },
    },
    overrides: {
      MuiSelect: {
        root: {
          padding: `${8}px ${16}px`,
          "&.MuiSelect-selectMenu": {
            height: 24,
            display: "flex",
            alignItems: "center",
          },
        },
      },
      MuiButton: { root: { height: 40 } },
    },
    typography: {
      body2: {
        fontSize: 14,
      },
      body1: {
        fontSize: "0.8em",
      },
      h3: {
        fontSize: "2.5em",
      },
      h4: {
        fontSize: "2em",
      },
      fontFamily: ["Noto Sans TC", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#504F4F",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      success: {
        main: "#00c300",
      },
    },
  });
  const consoleTheme = createTheme({
    palette: {
      primary: {
        main: colors.teal[500],
      },
      secondary: {
        main: colors.amber[500],
      },
    },
    typography: {
      fontFamily: ["Noto Sans TC", "sans-serif"].join(","),
    },
    props: {
      MuiTextField: {
        variant: "outlined",
        size: "small",
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiButtonGroup: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiButton: { root: { height: 40 } },
    },
  });
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTW}>
          <AlertProvider>
            <Router>
              <ScrollToTop />
              <Switch>
                <Route exact path="/log-in">
                  <LoginPage />
                </Route>
                {/* 新增 */}
                <Route exact path={route.map(({ path }) => path)}>
                  <RouteVerify />
                  <Layout>
                    <Switch>
                      {route.map(({ path, component }) => (
                        <Route key={path} path={path} exact>
                          {createElement(component)}
                        </Route>
                      ))}
                    </Switch>
                  </Layout>
                </Route>
                {/* 新增 */}
                {/* 後台 */}
                <ThemeProvider theme={consoleTheme}>
                  <AlertProvider>
                    <Route path="/console">
                      <ConsoleRouteVerify />
                      {/* 登入路由 */}
                      <Route path={consoleLoginRoute}>
                        <ConsoleLoginPage />
                      </Route>
                      {/* 一般路由 */}
                      <Route exact path={consoleRoute.map((item) => item.path)}>
                        <WebSiteLayout>
                          <Switch>
                            {consoleRoute.map((item) => (
                              <Route exact path={item.path} key={item.path}>
                                {createElement(item.page)}
                              </Route>
                            ))}
                          </Switch>
                        </WebSiteLayout>
                      </Route>
                    </Route>
                  </AlertProvider>
                </ThemeProvider>
              </Switch>
            </Router>
          </AlertProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}
function getToken() {
  return localStorage.getItem("@twenty-seven-webConsoleToken");
}
function ConsoleRouteVerify() {
  const { pathname } = useLocation();
  if (
    (pathname === consoleLoginRoute || pathname === "/console") &&
    getToken()
  ) {
    // 在登入情況下進到登入頁
    return <Redirect to={consoleHomeRoute} />;
  } else if (pathname !== consoleLoginRoute && !getToken()) {
    // 在未登入情況進到非登入(後台)畫面
    return <Redirect to={consoleLoginRoute} />;
  }
  return null;
}
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function RouteVerify() {
  const { pathname } = useLocation();
  const token = localStorage.getItem("@twenty-seven-webToken");

  if (token) {
    // NOTE 已經登入的情況
    const checkRoute = [
      "/loginPage",
      "/register",
      "/forget-password",
      "/member-terms",
    ];
    if (checkRoute.find((item) => item === pathname)) {
      return <Redirect to="/" />;
    }
  } else {
    // NOTE 尚未登入的情況
    if (
      (pathname.startsWith("/member") && pathname !== "/member-terms") ||
      pathname === "/my-account"
    ) {
      return <Redirect to="/loginPage" />;
    }
  }
  return null;
}

import React from "react";
import { makeStyles, Box, TextField } from "@material-ui/core";

export default function TextFieldComponent({
  label,
  error,
  helperText,
  ...props
}) {
  const useStyles = makeStyles({
    textFieldCartSelectBox: {
      fontSize: "1rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7e7d7d",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <label style={{ fontSize: "0.8em" }}>{label}</label>
      <br />
      <TextField
        {...props}
        InputProps={{
          className: classes.textFieldCartSelectBox,
        }}
        error={Boolean(error)}
        helperText={error && helperText}
        fullWidth
      />
    </Box>
  );
}

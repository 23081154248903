import React, { useState } from "react";
import {
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
  makeStyles,
  Checkbox,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import ProductTitle from "../components/ProductTitle";
import LoadingFloating from "../components/LoadingFloating";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode($mobile: String!, $forRegister: Boolean) {
    sendVerificationCode(mobile: $mobile, forRegister: $forRegister) {
      success
      message
    }
  }
`;

const REGISTER = gql`
  mutation register($userInput: UserInput!) {
    register(userInput: $userInput) {
      success
      message
    }
  }
`;

export default function RegisterPage() {
  const Alert = useAlert();
  const history = useHistory();
  const theme = useTheme();
  const emailPattern = /^[\w\.]+@\w+((\.|-)\w+)+$/;
  const passwordPattern = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;
  const mobilePattern = /^[0-9]{10}$/;
  const verifyPattern = /^[0-9]{6}$/;
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      fullName: "",
      username: "",
      email: "",
      password: "",
      repassword: "",
      dateOfBirth: null,
      mobile: "",
      verificationCode: "",
      agree: false,
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [verifying, setVerifying] = useState(false);

  function _sendVerification() {
    sendVerificationCode({
      variables: { mobile: getValues().mobile, forRegister: true },
    });
  }

  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATIONCODE,
    {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode.success) {
          return Alert.notify("驗證碼發送成功！", setVerifying(true));
        } else if (sendVerificationCode.message) {
          return Alert.notify(sendVerificationCode.message);
        }
      },
    }
  );

  function _submit(data) {
    const userInput = {
      ...data,
    };
    delete userInput.repassword;
    delete userInput.agree;
    register({ variables: { userInput } });
  }

  const [register, { loading: registerLoading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register.success) {
        window.fbq("track", "CompleteRegistration");
        return Alert.notify("註冊成功！", () => history.replace("/"));
      } else if (register.message) {
        return Alert.notify(register.message);
      }
    },
  });

  const useStyles = makeStyles({
    textstyle: {
      "& label.Mui-focused": {
        color: theme.palette.grey[600],
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },
    RemindMessage: {
      color: [theme.palette.secondary.dark],
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    RemindMessagePassword: {
      color: "rgba(79,79,79,0.6)",
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    RemindVerify: {
      color: [theme.palette.secondary.dark],
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    RemindMessageVerify: {
      color: "rgba(79,79,79,0.6)",
      marginTop: "5px",
      letterSpacing: "1.2px",
    },
    sex: {
      fontSize: "0.8em",
      display: "block",
      color: "rgba(79,79,79,0.9)",
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      borderBottom: "1px solid rgba(79,79,79,0.6)",
      appearance: "none",
      outline: "none",
      padding: "3px 0",
      cursor: "pointer",
    },
    sendButton: {
      color: "#fff",
      backgroundColor: [theme.palette.grey[700]],
      "&:hover": {
        backgroundColor: [theme.palette.grey[500]],
      },
    },
    sendButtonText: {
      padding: "10px 30px",
    },
  });
  const classes = useStyles();

  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <LoadingFloating loading={loading || registerLoading} />
      <CardContent>
        <Box py={4} mb={6} borderBottom="1px solid rgba(79,79,79,0.6)">
          <ProductTitle title="註冊會員" subtitle="Sign Up" />
        </Box>
        <Box padding={1}>
          <Grid container spacing={1}>
            <Grid container item>
              {/* fullName */}
              <Controller
                name="fullName"
                control={control}
                rules={{ required: "姓名為必填" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="姓名"
                    {...field}
                    error={error}
                    helperText={error?.message}
                    fullWidth
                    className={classes.textstyle}
                    // className={fullNameError?classes.textstyle:classes.texterrorstyle}
                  />
                )}
              />
            </Grid>
            <Grid container item alignItems="flex-start">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "信箱為必填",
                  pattern: {
                    value: emailPattern,
                    message: "信箱格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="信箱"
                    {...field}
                    error={error}
                    helperText={error?.message}
                    style={{ flex: 1 }}
                  />
                )}
              />
            </Grid>

            {/* 新增帳號 ANCHOR 判斷 */}
            <Grid container item alignItems="flex-start">
              <Controller
                name="username"
                control={control}
                rules={{
                  required: "帳號為必填",
                  pattern: {
                    value: passwordPattern, //帳號規則還不確定
                    message: "帳號格式錯誤，需包含英文以及數字！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="帳號"
                    {...field}
                    error={error}
                    helperText={error?.message}
                    style={{ flex: 1 }}
                  />
                )}
              />
            </Grid>

            <Grid container item>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "密碼為必填",
                  pattern: {
                    value: passwordPattern,
                    message: "密碼格式錯誤，需包含英文以及數字！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="密碼"
                    {...field}
                    error={error}
                    helperText={error?.message}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            size="small"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="repassword"
                control={control}
                rules={{
                  required: "未輸入密碼",
                  validate: {
                    value: (value) =>
                      getValues("password") === value || "密碼不一致",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    type={showRePassword ? "text" : "password"}
                    label="再次輸入密碼"
                    {...field}
                    error={error}
                    helperText={error?.message}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            size="small"
                            onClick={() => setShowRePassword((e) => !e)}
                          >
                            {showRePassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: "生日為必填" }}
                render={({
                  field: { ref, ...other },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="生日 (YYYY/MM/DD)"
                    type="date"
                    {...other}
                    error={error}
                    maxDate={new Date()}
                    helperText={error?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="mobile"
                control={control}
                rules={{
                  required: "電話為必填",
                  pattern: {
                    value: mobilePattern,
                    message: "電話格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      label="電話"
                      {...field}
                      error={error}
                      helperText={error?.message}
                      style={{ flex: 1 }}
                    />
                    &ensp;
                    <Button
                      variant="contained"
                      onClick={_sendVerification}
                      disabled={error}
                      style={{ height: "37.63px", backgroundColor: "#f44336" }}
                    >
                      <Typography
                        variant="caption"
                        style={{ padding: "0px 10px", color: "#fff" }}
                      >
                        電話驗證
                      </Typography>
                    </Button>
                  </>
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="verificationCode"
                control={control}
                rules={{
                  required: "驗證碼未輸入",
                  pattern: {
                    value: verifyPattern,
                    message: "驗證碼格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label={verifying ? "驗證碼" : "請先按下電話驗證"}
                    {...field}
                    error={error}
                    helperText={error?.message}
                    disabled={!verifying}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Controller
                name="agree"
                control={control}
                rules={{ required: "請勾選" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Typography style={{ color: "#f44336" }}>
                      &nbsp;&nbsp;
                      {error?.message}
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography style={{ letterSpacing: "1.2px" }}>
                      我已閱讀並同意
                      <a
                        target="_blank"
                        rel="noopener"
                        href="/member-terms"
                        style={{
                          color: "#76b881",
                          textDecoration: "underline",
                        }}
                      >
                        <b>網站服務條款及隱私政策</b>
                      </a>
                    </Typography>
                  </>
                )}
              />
              {/* 註冊按鈕 */}
            </Grid>
            <Grid container item>
              <Button
                variant="contained"
                onClick={handleSubmit(_submit)}
                fullWidth
              >
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.grey[800] }}
                >
                  註冊
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <ContactUs />
    </Container>
  );
}

function ContactUs() {
  return (
    <Box display="flex" alignItems="center" py={5} mx="auto">
      <Box mx={3}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          聯絡我們
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {/* 連結指向27的 對話框 */}
        <Button href="https://line.naver.jp/ti/p/@xjz0909p">
          <img
            src={require("../images/lineIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
        {/* middle line */}
        <Box width="1.5px" height="20px" bgcolor="#212121" mx={1}></Box>
        <Button href="https://www.instagram.com/globallive1491/">
          <img
            src={require("../images/igIcon.png").default}
            width="60px"
            height="50px"
            style={{ objectFit: "contain" }}
          />
        </Button>
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { DatePicker } from "antd";

import Table from "../../components/Table";
import { useAlert } from "../../components/Alert";
import { Card, CardContent, CardActions } from "../../components/Card";
import { Select, TextInput } from "../../components/Form";
import LoadingModal from "../../components/LoadingModal";

const GET_EVENTS = gql`
  query events(
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $enabled: Boolean
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    events(
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      enabled: $enabled
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        name
        startedAt
        endedAt
        items {
          id
        }
        enable
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_EVENT = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id) {
      success
      message
    }
  }
`;

const latestArray = [
  {
    label: "從新到舊",
    value: true,
  },
  {
    label: "從舊到新",
    value: false,
  },
];

const pageSizeArray = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

const enabledArray = [
  {
    label: "啟用",
    value: true,
  },
  {
    label: "未啟用",
    value: false,
  },
];

export default function EventTable({ onEditSelect = () => {} }) {
  const { RangePicker } = DatePicker;
  const { alert, notify } = useAlert();

  const [newData, setNewData] = useState({
    latestObject: {
      label: "從新到舊",
      value: true,
    },
    pageSizeObject: {
      label: "10",
      value: 10,
    },
    enabledObject: undefined,
    startTime: null,
    endTime: null,
    searchTerm: "",
    page: 1,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { data, loading, error, refetch } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      searchTerm: newData.searchTerm,
      startTime: newData.startTime,
      endTime: newData.endTime,
      enabled: newData.enabledObject ? newData.enabledObject.value : undefined,
      latest: newData.latestObject.value,
      pageSize: newData.pageSizeObject.value,
      page: newData.page,
    },
  });

  const [deleteEvent, { loading: deleteEventLoading }] = useMutation(
    DELETE_EVENT,
    {
      onCompleted({ deleteEvent }) {
        if (deleteEvent.success) {
          refetch();
          notify("刪除成功！");
        } else if (deleteEvent.message) {
          notify(deleteEvent.message);
        }
      },
    }
  );

  return (
    <Card>
      <LoadingModal loading={deleteEventLoading} />
      <CardContent>
        <Typography>購物活動數量：{data?.events.count}</Typography>
      </CardContent>
      <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <TextInput
              label={"搜尋"}
              value={newData.searchTerm}
              onChange={(value) => changeData("searchTerm", value, true)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              label={"查看狀態"}
              items={enabledArray}
              value={newData.enabledObject}
              onChange={(e, value) => changeData("enabledObject", value, true)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              label={"排序"}
              items={latestArray}
              value={newData.latestObject}
              onChange={(e, value) => {
                if (value) {
                  changeData("latestObject", value, true);
                } else {
                  changeData(
                    "latestObject",
                    {
                      label: "從新到舊",
                      value: true,
                    },
                    true
                  );
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              label={"每頁顯示"}
              items={pageSizeArray}
              value={newData.pageSizeObject}
              onChange={(e, value) => {
                if (value) {
                  changeData("pageSizeObject", value, true);
                } else {
                  changeData(
                    "pageSizeObject",
                    {
                      label: "10",
                      value: 10,
                    },
                    true
                  );
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RangePicker
              locale="zh-tw"
              format="YYYY-MM-DD"
              value={[
                newData.startTime ? moment(newData.startTime) : null,
                newData.endTime ? moment(newData.endTime) : null,
              ]}
              style={{ height: 40, width: "100%" }}
              onChange={(value) => {
                if (value) {
                  changeData(
                    "startTime",
                    moment(value[0])
                      .tz("Asia/Taipei")
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .toDate()
                  );
                  changeData(
                    "endTime",
                    moment(value[1])
                      .tz("Asia/Taipei")
                      .hour(23)
                      .minute(59)
                      .second(59)
                      .toDate(),
                    true
                  );
                } else {
                  changeData("startTime", null);
                  changeData("endTime", null, true);
                }
              }}
              placeholder={["開始時間", "結束時間"]}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <CardContent>
        {loading ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            height="480px"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            height="480px"
          >
            <Typography variant="h4">無法取得資料</Typography>
          </Box>
        ) : (
          <Table
            data={data ? data.events.contents : []}
            header={[
              "活動名稱",
              "活動商品數量",
              "開始時間",
              "結束時間",
              "狀態",
              "建立時間",
              "最後更新時間",
              "刪除",
            ]}
            tableWidth={2}
            columns={[
              "name",
              (item) => (item.items[0] ? item.items.length : "不限"),
              (item) => moment(item.startedAt).format("YYYY/MM/DD"),
              (item) => moment(item.endedAt).format("YYYY/MM/DD"),
              (item) => (item.enable ? "啟用" : "未啟用"),
              (item) => moment(item.createdAt).format("YYYY/MM/DD"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
              ({ id, name }) => {
                return (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("", `確定要刪除活動\u2002${name}\u2002嗎？`, [
                        {
                          text: "確定",
                          onClick: deleteEvent({ variables: { id } }),
                          type: "contained",
                        },
                        {
                          text: "取消",
                          type: "outlined",
                        },
                      ]);
                    }}
                  >
                    刪除
                  </Button>
                );
              },
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        )}
      </CardContent>
      <CardActions>
        <Grid container justifyContent="center">
          <Pagination
            count={
              data ? (data.events.pageCount ? data.events.pageCount : 1) : 1
            }
            size="large"
            color="primary"
            page={newData.page}
            onChange={(e, n) => changeData("page", n, true)}
          />
        </Grid>
      </CardActions>
    </Card>
  );
}

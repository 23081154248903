import React, { useState, useEffect, Fragment } from "react";
import { Box, Grid, CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import ProductTitle from "../components/ProductTitle";
import ProductList from "../components/ProductList";

const GET_PRODUCTS = gql`
  query products(
    $priority: Boolean
    $latest: Boolean
    $price: Boolean
    $enabled: Boolean
    $pageSize: Int
    $page: Int
  ) {
    products(
      latest: $latest
      priority: $priority
      price: $price
      enabled: $enabled
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        images {
          filename
          mimetype
          encoding
          location
        }
        categoryId
        name
        brand
        capacity
        price
        shipmentFee
        stock
        enable
        contents {
          id
          type
          body
        }
      }
    }
  }
`;

export default function AllProduct() {
  const Alert = useAlert();
  const { pathname } = useLocation();
  const [page, setPage] = useState(
    localStorage.getItem("@twenty-seven-all-product")
      ? Number(localStorage.getItem("@twenty-seven-all-product"))
      : 1
  );
  const [latest, setLatest] = useState(null);
  const [price, setPrice] = useState(null);
  const [priority, setPriority] = useState(true);
  const [getProducts, { data, loading }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  useEffect(() => {
    getProducts({
      variables: {
        latest,
        priority,
        price,
        enabled: true,
        pageSize: 8,
        page,
      },
    });
  }, [pathname, page, latest, price, priority]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Grid>
        <ProductList
          data={data.products.contents}
          onSearchResult={(v) => {
            if (v) {
              if (v.value === "商品排序") {
                setPriority(true);
                setLatest(null);
                setPrice(null);
              } else if (v.value === "上架時間-由新到舊") {
                setPriority(null);
                setLatest(true);
                setPrice(null);
              } else if (v.value === "上架時間-由舊到新") {
                setPriority(null);
                setLatest(false);
                setPrice(null);
              } else if (v.value === "價格-由高至低") {
                setPriority(null);
                setLatest(null);
                setPrice(false);
              } else if (v.value === "價格-由低至高") {
                setPriority(null);
                setLatest(null);
                setPrice(true);
              }
            } else {
              setPriority(true);
              setLatest(null);
              setPrice(null);
            }
          }}
        />
        <Grid container justifyContent="center">
          <Pagination
            count={data.products.pageCount ? data.products.pageCount : 1}
            size="large"
            color="primary"
            page={page}
            onChange={(e, n) => {
              localStorage.setItem("@twenty-seven-all-product", n);
              setPage(n);
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

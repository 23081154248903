import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Box,
  Container,
  useMediaQuery,
  TextField,
  InputAdornment,
  Button,
  Paper,
  IconButton,
  Grid,
  Badge,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "./Alert";
import IconListFooter from "./IconListFooter";
import PcMenuList from "./PcMenuList";
import MobileMenuList from "./MobileMenuList";
import { ReactComponent as LogoWords } from "../images/svg/logowords.svg";
import AdvertisementFloatingLayer from "./AdvertisementFloatingLayer";

const GET_PAGECONTENT = gql`
  query pageContent($area: PageContentArea) {
    pageContent(area: $area) {
      id
      imageUrl
    }
  }
`;

const GET_CATEGORIES = gql`
  query categories($enabled: Boolean) {
    categories(enabled: $enabled) {
      id
      name
      enName
      image
      subCategories(front: true) {
        id
        name
        enName
        image
        content {
          id
          title
          subTitle
          label1
          label2
          label3
        }
      }
    }
  }
`;

const GET_CART = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          images {
            location
          }
          name
          price
        }
        unitPrice
        amount
      }
      totalPrice
    }
  }
`;

const menuArray = [
  {
    path: "/",
    label: "搜尋商品",
    show: true,
    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={require("../images/messageIcon.png").default}
        width="23px"
        height="23px"
        style={{ objectFit: "contain" }}
      />
    ),
  },
  {
    path: "/loginPage",
    label: "會員登錄",
    show: true,
    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={require("../images/memberIcon.png").default}
        width="23px"
        height="23px"
        style={{ objectFit: "contain" }}
      />
    ),
  },
  {
    path: "/my-account",
    label: "我的帳戶",
    show: true,
    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Grid style={{ display: "flex" }}>
        <AccountCircleIcon />
      </Grid>
    ),
  },
  {
    path: "/cart-page",
    label: "購物車",
    show: true,
    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={require("../images/CartIcon.png").default}
        width="23px"
        height="23px"
        style={{ objectFit: "contain" }}
      />
    ),
  },
  {
    path: "../",
    label: "登出",
    show: true,
    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Grid style={{ display: "flex" }}>
        <ExitToAppIcon />
      </Grid>
    ),
  },
];

export default function Layout({ children }) {
  const Alert = useAlert();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const [menuListArray, setMenuListArray] = useState([]);
  const pageContentArray = useRef([]);

  const [getPageContent, { loading: pageContentLoading }] = useLazyQuery(
    GET_PAGECONTENT,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        area: "ALL_PRODUCT",
      },
      onCompleted({ pageContent }) {
        if (pageContent) {
          pageContentArray.current.push({
            label: "全部商品",
            enName: "all-product",
            path: "/all-product",
            img: pageContent.imageUrl,
            second: [],
          });
        }
        getCategories({ variables: { enabled: true } });
      },
    }
  );

  const [getCategories, { loading }] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ categories }) {
      if (categories) {
        categories.forEach((item) => {
          const newSubCategories = [];
          item.subCategories.forEach((item2) => {
            newSubCategories.push({
              label: item2.name,
              enName: `${item2.enName}`,
              path: `${item.enName}/${item2.enName}`,
              img: item2.image,
              content: {
                title: item2.content && item2.content.title,
                subTitle: item2.content && item2.content.subTitle,
                label1: item2.content && item2.content.label1,
                label2: item2.content && item2.content.label2,
                label3: item2.content && item2.content.label3,
              },
            });
          });
          if (item.name !== "會員專區" && item.name !== "限定商品") {
            pageContentArray.current.push({
              label: item.name,
              enName: `${item.enName}`,
              path: `/${item.enName}`,
              img: item.image,
              second: newSubCategories,
            });
          }
        });
        setMenuListArray(pageContentArray.current);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    getPageContent();
  }, []);

  const isTable = useMediaQuery("(min-width:769px)");
  // useStyle2 是電腦
  // ANCHOR PC banner TEXT
  const useStyles2 = makeStyles((theme) => ({
    root: {
      width: "30%",
      maxWidth: 120,
      backgroundColor: theme.palette.background.paper,
    },
    List: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
      position: "absolute",
      zIndex: "9999",
    },
    ListItem: {
      width: "100px",
      "&+&": {
        marginLeft: "2em",
      },
    },
    subListControl: {
      color: "#000",
      display: "flex",
      justifyContent: "center",
    },
    BannerText: {
      position: "absolute",
      height: "400px",
      left: "0",
      right: "0",
      textAlign: "center",
      zIndex: "-1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bannerContentTitle: {
      color: "#fff",
      letterSpacing: "6px",
      fontFamily: "GENBKBASR",
      // fontSize: "2.5em",
    },
    bannerContentSubTitle: {
      color: "#fff",
      letterSpacing: "3.2px",
      // fontSize: "0.88em",
      fontFamily: "GENBKBASR",
    },
    bannerContentLabel: {
      display: "block",
      color: "#fff",
      letterSpacing: "2px",
      lineHeight: "1.4",
      fontWeight: "100",
      // fontSize: "0.95em",
    },
    showbanner: {
      display: isTable ? "block" : "none",
    },
  }));
  const classes2 = useStyles2();

  //PC banner img
  const [menuSelectIndex, setMenuSelectIndex] = useState(undefined);
  const [firstOpen, setFirstOpen] = useState(true);
  const [open, setOpen] = useState();
  const [menuColor, setMenuColor] = useState();
  const [menuImg, setMenuImg] = useState(true);
  //判斷子層父層
  const [menuLockImg, setMenuLockImg] = useState(true);
  //固定父層圖片
  const [menuImgNumber, setMenuImgNumber] = useState(0);
  //父層圖片的陣列號
  const [menuImgHistory, setMenuImgHistory] = useState(0);
  const bannerPorp = {
    menuSelectIndex,
    setMenuSelectIndex,
    menuImg,
    setMenuImg,
    menuLockImg,
    setMenuLockImg,
    menuImgNumber,
    setMenuImgNumber,
    menuImgHistory,
    setMenuImgHistory,
    setFirstOpen,
    firstOpen,
    open,
    setOpen,
    menuColor,
    setMenuColor,
  };

  //mobile banner img
  const [bannerUrl, setBannerUrl] = useState("none");
  const [bannerIndex, setBannerIndex] = useState(undefined);
  const mobileBannerPorp = {
    bannerUrl,
    setBannerUrl,
    bannerIndex,
    setBannerIndex,
  };

  const location = useLocation();

  //pc Banner
  // 各頁面的banner

  function BannerStyle(img_url) {
    const pathname = location.pathname;
    return (
      <Fragment>
        {/* 暫時先用這種方法擋掉 */}
        {pathname !== "/" && (
          <Box
            style={{
              width: "100%",
              paddingBottom: "43.75%",
              height: 0,
              position: "relative",
            }}
          >
            <img
              src={img_url}
              width="100%"
              style={{
                position: "absolute",
                objectFit: "cover",
                inset: 0,
                height: "100%",
              }}
              alt="page-banner"
            />
          </Box>
        )}
      </Fragment>
    );
  }

  function Banner(index) {
    const checkList = [
      "/loginPage",
      "/member-terms",
      "/register",
      "/forget-password",
      "/cart-page",
      "/change-password",
      "/search-page",
      "/product-detail",
    ];
    if (location.pathname !== "/") {
      if (checkList.find((item) => location.pathname.startsWith(item))) {
        return null;
      } else if (index === 0 || index === 5 || location.pathname === "/") {
        return BannerStyle(menuListArray[index].img);
      } else {
        return BannerStyle(menuListArray[index].second[0].img);
      }
    }
  }

  // ANCHOR mobile Banner
  function MobileBanner(url, index = 0) {
    // 手機板不要banner
    if (
      location.pathname === "/" ||
      location.pathname === "/member-terms" ||
      location.pathname === "/loginPage" ||
      location.pathname === "/register" ||
      location.pathname === "/change-password" ||
      location.pathname === "/forget-password" ||
      location.pathname === "/my-account" ||
      location.pathname === "/search-page" ||
      location.pathname === "/cart-page" ||
      location.pathname.startsWith("/product-detail")
    ) {
      return undefined;
    } else if ((index === 0 || index === 5) && menuListArray[0]) {
      return (
        // 主分類的圖
        <Box
          style={{
            width: "100%",
            paddingBottom: "43.75%",
            height: 0,
            position: "relative",
          }}
        >
          <img
            src={menuListArray[index].img}
            width="100%"
            style={{
              position: "absolute",
              objectFit: "cover",
              inset: 0,
              height: "100%",
            }}
            alt="main"
          />
        </Box>
      );
    } else if (index === undefined && url === "none") {
      return undefined;
    } else {
      let findBannerContent =
        menuListArray[0] &&
        menuListArray[index]?.second.find(
          (item) =>
            location.pathname ===
            `/${menuListArray[index].enName}/${item.enName}-page`
        );
      if (findBannerContent === undefined) {
        return undefined;
      } else {
        return (
          // 整個banner 外框
          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            width="100%"
            pb={"43.75%"}
          >
            {/* ANCHOR 子分類的圖 */}
            <img
              src={
                menuListArray[index]?.second.find(
                  (item) =>
                    location.pathname ===
                    `/${menuListArray[index].enName}/${item.enName}-page`
                ).img
              }
              style={{
                display: "inline-block",
                objectFit: "cover",
                position: "absolute",
                inset: 0,
                width: "100%",
                height: "100%",
              }}
              alt="outer"
            />
          </Box>
        );
      }
    }
  }
  return (
    <Box maxWidth="1280px" mx="auto">
      <AdvertisementFloatingLayer />
      <Box position="relative">
        <Box id="detailProductClose" position="sticky" zIndex={99}>
          <Header bannerPorp={bannerPorp} />
          <Menu bannerPorp={bannerPorp} mobileBannerPorp={mobileBannerPorp} />
        </Box>
        {/* ANCHOR Banner*/}
        {isDownTablet && (
          <Box id="mobilePromoteClear" position="relative">
            {MobileBanner(bannerUrl, bannerIndex)}
          </Box>
        )}

        {/*  */}
        {/* ANCHOR Banner*/}
        {(loading || pageContentLoading) &&
        (location.pathname !== "/" || location.pathname !== "/cart-page") ? (
          <Box
            style={{
              width: "100%",
              paddingBottom: "43.75%",
              height: 0,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100%"
                transform="none"
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes2.showbanner}>
            {/* 是購物車或是 /product-detail開頭嗎 是的話null , 不是的話menuimg,*/}
            {/* NOTE 電腦不要page-banner 在這邊擋住 */}
            {location.pathname === "/cart-page" ||
            location.pathname === "/loginPage" ||
            location.pathname === "/register" ||
            location.pathname === "/member-terms" ||
            location.pathname === "/my-account" ||
            location.pathname === "/forget-password" ||
            location.pathname.startsWith("/product-detail")
              ? null
              : menuImg
              ? menuLockImg
                ? typeof menuImgNumber === "number" &&
                  menuListArray[0] &&
                  (location.pathname === "/search-page" ||
                  location.pathname === "/member-terms" ||
                  location.pathname === "/register" ||
                  location.pathname === "/forget-password" ||
                  location.pathname === "/change-password" ||
                  location.pathname === "/" ||
                  location.pathname === "/my-account" ||
                  location.pathname === "/loginPage"
                    ? null
                    : !isDownTablet &&
                      BannerStyle(menuListArray[menuImgNumber].img))
                : Banner(menuImgHistory)
              : typeof menuSelectIndex === "number" &&
                BannerStyle(
                  menuListArray[menuSelectIndex]?.second.find(
                    (item) => location.pathname === `/${item.path}-page`
                  )
                    ? menuListArray[menuSelectIndex].second.find(
                        (item) => location.pathname === `/${item.path}-page`
                      ).img
                    : null
                )}
            {/* TODO mobile 版本 */}
          </Box>
        )}

        <Box>{children}</Box>
      </Box>
      {/* layout footer */}
      <IconListFooter />
    </Box>
  );
}

function Header({ bannerPorp }) {
  const theme = useTheme();
  const history = useHistory();
  const isTable = useMediaQuery(theme.breakpoints.up("sm"));
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const useStyles = makeStyles({
    footer: {
      textAlign: "center",
    },
    headerColor: {
      backgroundColor: "#F6F3EE",
    },
    logo: {
      width: isTable ? "80px" : "70px",
      height: isTable ? "80px" : "70px",
    },
  });
  const classes = useStyles();

  return (
    <Container maxWidth="" disableGutters>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ justifyContent: isDownTablet ? "center" : "space-between" }}
          py={2}
          px={4}
          className={classes.headerColor}
        >
          <Box>
            <Button
              onClick={() => history.push("/")}
              style={{ height: "100%", width: "100%" }}
            >
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                src={require("../images/Logo.png").default}
                style={{ objectFit: "contain" }}
                className={classes.logo}
              />
            </Button>
          </Box>

          <div style={{ display: isDownTablet ? "none" : "block" }}>
            <Box style={{ transform: "translateX(100px)", cursor: "pointer" }}>
              <LogoWords
                width="320px"
                onClick={() => {
                  history.push("/");
                }}
              />
            </Box>
          </div>

          <div style={{ display: isDownTablet ? "none" : "block" }}>
            <Box>
              <MenuList bannerPorp={bannerPorp} />
            </Box>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

function MenuList({ bannerPorp }) {
  const useStyles = makeStyles((theme) => ({
    navMenu: {
      listStyle: "none",
      padding: "0px",
    },
    listItem: {
      textDecoration: "none",
      display: "inline-block",
      padding: "5px",
      color: "#212121",
      transition: "all 0.3s",
      fontFamily: "GENBKBASB , serif",
      height: "40px",
      "&.MuiButton-root:hover": {
        color: "#ccc",
        backgroundColor: "#f5f4ed",
      },
      // 這邊只改字體顏色, ( 登出除外 )
      "&:hover span:last-child": {
        color: "#ccc",
      },
    },
    searchListItem: {
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    badge: {
      color: "#fff",
      "& .MuiBadge-badge": {
        backgroundColor: [theme.palette.success.light],
      },
      "& .MuiBadge-badge:hover": {
        backgroundColor: [theme.palette.success.light],
        color: "#fff",
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [searchBox, setSearchBox] = useState(false);
  //searchpage搜尋內容
  const [searchContent, setSearchContent] = useState();
  const token = localStorage.getItem("@twenty-seven-webToken");
  //子層菜單顯示
  const { setOpen, setMenuColor } = bannerPorp;

  function tool(item) {
    if (item === "登出") {
      return _logout;
    } else if (item === "購物車") {
      return _shoppingCar;
    } else {
      return _search;
    }
  }

  function _shoppingCar() {
    setOpen(false);
    setMenuColor(undefined);
    history.push("/cart-page");
  }

  function _search() {
    setSearchBox(!searchBox);
  }

  function _logout() {
    localStorage.removeItem("@twenty-seven-webMemberId");
    localStorage.removeItem("@twenty-seven-webToken");
    history.replace("/");
  }

  //ANCHOR 搜尋enter送出
  function keyPress(event) {
    if (event.keyCode === 13) {
      history.push(
        `/search-page?search=${
          searchContent === undefined ? "無" : searchContent
        }`
      );
    }
  }

  function CartBadgeContent({ badgeClasses, itemIcon }) {
    const Alert = useAlert();

    const [getCart, { data }] = useLazyQuery(GET_CART, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    });

    useEffect(() => {
      if (localStorage.getItem("@twenty-seven-webCartId")) {
        getCart({
          variables: {
            id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
          },
        });
      }
    }, []);
    return (
      <Badge className={badgeClasses} badgeContent={data?.cart.items?.length}>
        <span>{itemIcon}</span>
      </Badge>
    );
  }

  return (
    // 更改處
    <ul
      className={classes.navMenu}
      style={{ display: "flex", position: "relative", alignItems: "center" }}
    >
      {/* ANCHOR 搜尋 */}
      {menuArray.map((item) => {
        if (item.label === "會員登錄") {
          if (!token) {
            return (
              <li className="listItemBox">
                <Button
                  onClick={() => history.push(item.path)}
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>{item.icon}</span>
                  &ensp;
                  <span>{item.label}</span>
                </Button>
              </li>
            );
          } else {
            return null;
          }
        } else if (item.label === "我的帳戶") {
          if (token) {
            return (
              <li className="listItemBox">
                <Button
                  onClick={() => history.push(item.path)}
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>{item.icon}</span>
                  &ensp;
                  <span>{item.label}</span>
                </Button>
              </li>
            );
          } else {
            return null;
          }
        } else {
          if (!token && item.label === "登出") {
            return null;
          } else {
            return (
              <li
                className="listItemBox"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={tool(item.label)}
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.label === "購物車" ? (
                    <CartBadgeContent
                      badgeClasses={classes.badge}
                      itemIcon={item.icon}
                    />
                  ) : (
                    <span>{item.icon}</span>
                  )}
                  &ensp;
                  <span>{item.label}</span>
                </Button>
              </li>
            );
          }
        }
      })}
      {/* 改這邊 */}
      <TextField
        variant="outlined"
        size="small"
        className={classes.searchListItem}
        component={Paper}
        onKeyUp={(e) => {
          localStorage.removeItem("@twenty-seven-search");
          keyPress(e);
        }}
        onChange={(e) => {
          setSearchContent(e.target.value);
        }}
        style={{
          position: "absolute",
          top: "40px",
          left: 10,
          display: searchBox ? "block" : "none",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                onClick={() => {
                  localStorage.removeItem("@twenty-seven-search");
                  history.push(
                    `/search-page?search=${
                      searchContent === undefined ? "無" : searchContent
                    }`
                  );
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </ul>
  );
}

function Menu({ bannerPorp, mobileBannerPorp }) {
  const Alert = useAlert();
  const [menuListArray, setMenuListArray] = useState([]);
  const pageContentArray = useRef([]);

  const [getPageContent, { loading: pageContentLoading }] = useLazyQuery(
    GET_PAGECONTENT,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        area: "ALL_PRODUCT",
      },
      onCompleted({ pageContent }) {
        if (pageContent) {
          pageContentArray.current.push({
            label: "全部商品",
            enName: "all-product",
            path: "/all-product",
            img: pageContent.imageUrl,
            second: [],
          });
        }
        getCategories({ variables: { enabled: true } });
      },
    }
  );

  const [getCategories, { loading }] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ categories }) {
      if (categories) {
        categories.forEach((item) => {
          const newSubCategories = [];
          item.subCategories.forEach((item2) => {
            newSubCategories.push({
              label: item2.name,
              enName: `${item2.enName}`,
              path: `${item.enName}/${item2.enName}`,
              img: item2.image,
              content: {
                title: item2.content && item2.content.title,
                subTitle: item2.content && item2.content.subTitle,
                label1: item2.content && item2.content.label1,
                label2: item2.content && item2.content.label2,
                label3: item2.content && item2.content.label3,
              },
            });
          });
          if (item.name !== "會員專區" && item.name !== "限定商品") {
            pageContentArray.current.push({
              label: item.name,
              enName: `${item.enName}`,
              path: `/${item.enName}`,
              img: item.image,
              second: newSubCategories,
            });
          }
        });
        setMenuListArray(pageContentArray.current);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    getPageContent();
  }, []);

  const isDownTablet = useMediaQuery("(max-width:1024px)");

  return (
    <Box height="40px" style={{ backgroundColor: "#F6F3EE" }}>
      {menuListArray.length > 0 && (
        <Box textAlign="center">
          {isDownTablet ? (
            <MobileMenuList
              menuListArray={menuListArray ? menuListArray : null}
              mobileBannerPorp={mobileBannerPorp}
            />
          ) : (
            <PcMenuList
              menuListArray={menuListArray ? menuListArray : null}
              bannerPorp={bannerPorp}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import { Card, CardContent, CardActions } from "../../components/Card";
import Table from "../../components/Table";
import Button from "../../components/Button";
import CategoryAddFloatingLayer from "./CategoryAddFloatingLayer";
import LoadingFloating from "../../components/LoadingFloating";

const GET_CATEGORY = gql`
  query category($id: Int!) {
    category(id: $id) {
      id
      name
      subCategories {
        id
        name
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation hideCategory($id: Int!) {
    hideCategory(id: $id) {
      success
      message
    }
  }
`;

export default function SubcategoryTable({ id, onChangeSelect = () => {} }) {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    id: undefined,
    categoryId: id,
    open: false,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  useEffect(() => {
    if (Boolean(id)) {
      getCategory({ variables: { id } });
    }
  }, [id]);

  const [getCategory, { data, loading }] = useLazyQuery(GET_CATEGORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  const [hideCategory, { loading: deleteCategoryLoading }] = useMutation(
    DELETE_CATEGORY,
    {
      onCompleted({ hideCategory }) {
        if (hideCategory.success) {
          getCategory({ variables: { id } });
          Alert.notify("刪除成功");
        } else if (hideCategory.message) {
          Alert.notify(hideCategory.message);
        }
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <LoadingFloating loading={deleteCategoryLoading} />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onPress={() => changeData("open", true, true)}
          >
            新增子分類
          </Button>
        </CardActions>
        <CategoryAddFloatingLayer
          open={newData.open}
          id={newData.id}
          categoryId={newData.categoryId}
          onFloatingwindowClose={(value) => {
            changeData("open", false);
            changeData("id", undefined, true);
            if (value === "refetch") {
              getCategory({ variables: { id } });
            }
          }}
        />
        <CardContent>
          <Table
            data={Boolean(data) ? data.category.subCategories : []}
            header={["子分類名稱", "編輯", "刪除"]}
            columns={[
              "name",
              (item) => {
                return (
                  <Button
                    color="primary"
                    variant="contained"
                    onPress={(e) => {
                      e.stopPropagation();
                      changeData("open", true);
                      changeData("id", item.id, true);
                    }}
                  >
                    編輯
                  </Button>
                );
              },
              (item) => {
                return (
                  <Button
                    color="primary"
                    variant="contained"
                    onPress={(e) => {
                      e.stopPropagation();
                      Alert.alert("", "確定要刪除嗎？", [
                        {
                          text: "確認",
                          onClick: () =>
                            hideCategory({
                              variables: { id: Number(item.id) },
                            }),
                        },
                        { text: "取消", type: "outlined" },
                      ]);
                    }}
                  >
                    刪除
                  </Button>
                );
              },
            ]}
            // onPress={(item) => onChangeSelect(item)}
          />
        </CardContent>
      </Card>
    );
  }
}

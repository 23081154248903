import XLSX from "xlsx";

export async function excelDownload(entozh, excelData, fileName) {
  function handleExportAll() {
    const json = excelData.map((item) => {
      return Object.keys(item).reduce((newData, key) => {
        const newKey = entozh[key] || key;
        newData[newKey] = item[key];
        return newData;
      }, {});
    });
    const sheet = XLSX.utils.json_to_sheet(json);
    openDownloadDialog(sheet2blob(sheet, undefined), `${fileName}.xlsx`);
  }
  function openDownloadDialog(url, saveName) {
    if (typeof url == "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 創建blob地址
    }
    var aLink = document.createElement("a");
    aLink.href = url;
    aLink.download = saveName || ""; // HTML5新增的屬性，指定保存文件名，可以不要後綴，注意，file:///模式下不會生效
    var event;
    if (window.MouseEvent) event = new MouseEvent("click");
    else {
      event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
    }
    aLink.dispatchEvent(event);
  }
  function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || "sheet1";
    var workbook = {
      SheetNames: [sheetName],
      Sheets: {},
    };
    workbook.Sheets[sheetName] = sheet; // 生成excel的配置項
    var wopts = {
      bookType: "xlsx", // 要生成的文件類型
      bookSST: false, // 是否生成Shared String Table，官方解釋是，如果開啟生成速度會下降，但在低版本IOS設備上有更好的兼容性
      type: "binary",
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    }); // 字符串轉ArrayBuffer
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    return blob;
  }
  handleExportAll();
}

import React, { useState, useEffect, memo, useReducer, Fragment } from "react";
import {
  Container,
  Grid,
  Button,
  makeStyles,
  useTheme,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import {
  thousandsSeparator,
  halfShapeAndFullForm,
  getInvitationCode,
} from "../components/utils";
import TextFieldComponent from "../components/TextFieldComponent";
import SelectComponent from "../components/SelectComponent";
import { FieldCache } from "../components/FieldCache";

import {
  countiesList,
  districtsList,
  getCounty,
  getDistrict,
} from "../utils/countiesDistricts";
import {
  shipmentSubTypeEnum,
  shipmentSubTypedList,
  orderStatusEnum,
  selectPaymentMethodList,
} from "../utils/localData";
import {
  checkSpecialSymbolsAndNumber,
  checkMobile,
} from "../utils/checkRegExp";
import emptyArray from "../utils/emptyArray";

const GET_USER = gql`
  query user($id: Int) {
    user(id: $id) {
      id
      mobile
      email
      fullName
      dateOfBirth
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: Int, $userInput: UserInput!, $console: Boolean!) {
    updateUser(id: $id, userInput: $userInput, console: $console) {
      success
      message
    }
  }
`;

const GET_ORDERS = gql`
  query orders($userId: Int, $personal: Boolean, $latest: Boolean) {
    orders(userId: $userId, personal: $personal, latest: $latest) {
      contents {
        id
        serialNumber
        user {
          id
          fullName
          mobile
        }
        totalPrice
        status
        note
        shipmentAddress {
          id
          recipient
          recipientPhone
          shipmentMethod
          # "運送子方式"
          shipmentSubType
          # "代收貨款"
          isCollection
          # "超商寄貨編號(前台僅顯示寄貨編號+驗證碼，欄位名稱為超商單號)"
          cvsPaymentNo
          # "超商驗證碼"
          cvsValidationNo
          convenienceStore
          district
          subdistrict
          address
        }
        payments {
          id
          success
          tradeNumber
          totalPrice
          paymentMethod
          bankCode
          virtualAccount
          expireDate
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_ORDER = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      items {
        id
        product {
          id
          images {
            location
          }
          name
          brand
          capacity
        }
        unitPrice
        amount
      }
      createdAt
      updatedAt
    }
  }
`;

// ANCHOR 重新付款
const RETRY_PAYMENT = gql`
  mutation retryPayment($orderId: Int) {
    retryPayment(orderId: $orderId) {
      success
      message
      order {
        id
        paymentUrl
      }
    }
  }
`;

// ANCHOR 更改出貨地址
const UPDATE_SHIPMENTADDRESS = gql`
  mutation updateShipmentAddress(
    $orderId: Int!
    $shipmentAddressInput: OrderShipmentAddressInput!
  ) {
    updateShipmentAddress(
      orderId: $orderId
      shipmentAddressInput: $shipmentAddressInput
    ) {
      success
      message
    }
  }
`;

// ANCHOR 重新建立綠界出貨單
const CREATE_NEWECPAYSHIPMENTORDER = gql`
  mutation createNewEcpayShipmentOrder(
    $shipmentId: Int!
    $shipmentAddressInput: OrderShipmentAddressInput!
  ) {
    createNewEcpayShipmentOrder(
      shipmentId: $shipmentId
      shipmentAddressInput: $shipmentAddressInput
    ) {
      success
      message
    }
  }
`;

// ANCHOR 門市電子地圖
const GO_TO_LOGISTICSMAP = gql`
  query logisticsMap(
    $shipmentSubType: ShipmentSubType!
    $isCollection: Boolean!
    $randomCode: String!
    $returnUrl: String!
  ) {
    logisticsMap(
      shipmentSubType: $shipmentSubType
      isCollection: $isCollection
      randomCode: $randomCode
      returnUrl: $returnUrl
    )
  }
`;

// ANCHOR 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

export default function MyAccount() {
  return (
    <Container
      maxWidth="md"
      style={{
        minHeight: `calc(100vh - 255px)`,
      }}
    >
      <Box py={3}>
        <SimpleTabs />
      </Box>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.4)",
    borderBottom: "0",
    "&.MuiPaper-elevation4": {
      boxShadow: "none",
    },
  },
  tabsBox: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    "&.MuiPaper-elevation4": {
      boxShadow: "none",
    },
  },
  tab: {
    height: "80px",
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  tabPanelOrder: {
    "& .MuiBox-root": {
      padding: "0",
    },
  },
}));
// tabs
function SimpleTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("clientBack") === "true") {
      setValue(1);
      window.fbq("track", "Purchase", {
        value: parseFloat(searchParams.get("value")),
        currency: "TWD",
      });
      history.replace({ search: "" });
    }
  }, []);

  useEffect(() => {
    const twentySevenMemberSelectStoreRandomCode = localStorage.getItem(
      `@twenty-sevenMemberSelectStoreRandomCode`
    );
    if (twentySevenMemberSelectStoreRandomCode) {
      setValue(1);
    }
  }, []);

  return (
    <Box mx={2}>
      <Container maxWidth={isDownTablet ? "sm" : "md"}>
        <div
          style={{
            backgroundColor: [theme.palette.background.paper],
          }}
        >
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="simple tabs example"
              className={classes.tabsBox}
              style={{ color: "#000" }}
            >
              <Tab label="個人資訊" className={classes.tab} {...a11yProps(0)} />
              <Tab label="訂單資訊" className={classes.tab} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel
            value={value}
            index={0}
            style={{ border: "1px solid rgba(0,0,0,0.4)", boxShadow: "0" }}
          >
            <Profile />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            style={{ border: "1px solid rgba(0,0,0,0.4)" }}
            className={classes.tabPanelOrder}
          >
            <PastOrders />
          </TabPanel>
        </div>
      </Container>
    </Box>
  );
}

function Profile() {
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const useStyles = makeStyles((theme) => ({
    textstyle: {
      fontSize: "1.4em",
      marginTop: "0.8em",
      "& label.Mui-focused": {
        color: "black",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
    },
    texterrorstyle: {
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
    },
    personalInfo: {
      display: "flex",
      flexDirection: "column",
      "& label": {
        display: "inline-block",
        width: "50px",
      },
      "& div": {
        paddingTop: "1em",
        display: "flex",
        alignItems: "center",
      },
      "& div input": {
        outline: "none",
        width: isDownTablet ? "100%" : "80%",
        padding: "4px 10px",
      },
    },
    sendVerifyBtn: {
      backgroundColor: theme.palette.grey[700],
      "&:hover": {
        backgroundColor: theme.palette.grey[500],
      },
    },
    sendVerifyText: {
      color: "#fff",
      padding: "4px 4px",
      fontSize: "0.7rem",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyles();

  const Alert = useAlert();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const emailPattern = /^[\w\.]+@\w+((\.|-)\w+)+$/;
  const passwordPattern = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;
  const mobilePattern = /^[0-9]{10}$/;
  const verifyPattern = /^[0-9]{6}$/;

  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      repassword: "",
      dateOfBirth: null,
      mobile: "",
      verificationCode: "",
    },
  });
  const [verifying, setVerifying] = useState(false);
  function _sendVerification() {
    setVerifying(true);
  }

  const { data: userData, loading } = useQuery(GET_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: Number(localStorage.getItem("@twenty-seven-webMemberId")),
    },
    onCompleted({ user }) {
      if (user) {
        setValue("fullName", user.fullName);
        setValue("email", user.email);
        setValue("dateOfBirth", moment(user.dateOfBirth).format("YYYY-MM-DD"));
      }
    },
    onError() {
      return null;
    },
  });

  function _submit(data) {
    const userInput = {
      ...data,
    };
    delete userInput.repassword;
    if (!Boolean(data.password)) {
      delete userInput.password;
    }
    updateUser({
      variables: {
        id: Number(localStorage.getItem("@twenty-seven-webMemberId")),
        userInput,
        console: false,
      },
    });
  }

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted({ updateUser }) {
        if (updateUser.success) {
          return Alert.notify("更新成功！", () => history.goBack());
        } else if (updateUser.message) {
          Alert.notify(updateUser.message);
        }
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <Box p={4}>
      <LoadingModal loading={updateUserLoading} />
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <PersonOutlineIcon style={{ width: "20px" }} />
              &ensp;
              {/* 可替換名稱 ANCHOR */}
              <Typography variant="caption">
                {userData?.user.fullName}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <CreateIcon style={{ width: "20px" }} />
              &ensp;
              <Typography variant="caption">編輯會員資料</Typography>
            </Box>
          </Box>
          {/* ANCHOR 規則要嘛 ?? XD */}

          <Grid container spacing={1}>
            <Grid container item>
              <Controller
                name="fullName"
                control={control}
                rules={{ required: "姓名為必填" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="姓名"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    className={!error ? classes.textstyle : ""}
                  />
                )}
              />
            </Grid>
            <Grid container item alignItems="flex-start">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "信箱為必填",
                  pattern: {
                    value: emailPattern,
                    message: "信箱格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="信箱"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    style={{ flex: 1 }}
                    className={!error ? classes.textstyle : ""}
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="password"
                control={control}
                rules={{
                  pattern: {
                    value: passwordPattern,
                    message: "密碼格式錯誤，需包含英文以及數字！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="密碼"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    className={!error ? classes.textstyle : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            size="small"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="repassword"
                control={control}
                rules={{
                  required: getValues("password") && "未輸入密碼",
                  validate: {
                    value: (value) =>
                      getValues("password") === value || "密碼不一致",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="再次輸入密碼"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    className={!error ? classes.textstyle : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            size="small"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: "生日為必填" }}
                render={({
                  field: { ref, ...other },
                  fieldState: { error },
                }) => (
                  <TextField
                    {...other}
                    label="生日 (YYYY/MM/DD)"
                    type="date"
                    className={!error ? classes.textstyle : ""}
                    error={error}
                    maxDate={new Date()}
                    helperText={error && error.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="mobile"
                control={control}
                rules={{
                  required: "電話為必填",
                  pattern: {
                    value: mobilePattern,
                    message: "手機格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <TextField
                        label="手機號碼"
                        {...field}
                        error={error}
                        helperText={error && error.message}
                        className={!error ? classes.textstyle : ""}
                        style={{ width: "70%" }}
                      />
                      <Button
                        variant="contained"
                        onClick={_sendVerification}
                        disabled={error}
                        className={classes.sendVerifyBtn}
                      >
                        <Typography
                          variant="body2"
                          className={classes.sendVerifyText}
                        >
                          傳送驗證碼
                        </Typography>
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Grid>
            <Grid container item>
              <Controller
                name="verificationCode"
                control={control}
                rules={{
                  required: "驗證碼未輸入",
                  pattern: {
                    value: verifyPattern,
                    message: "驗證碼格式不正確！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label={verifying ? "驗證碼" : "請先按下電話驗證"}
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    disabled={!verifying}
                    fullWidth
                    className={!error ? classes.textstyle : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent={isDownTablet ? "center" : "flex-end"}
            alignItems="flex-end"
            height="100%"
          >
            <Button variant="outlined" style={{ width: "100px" }}>
              <Typography variant="caption" style={{ padding: "4px 10px" }}>
                取消
              </Typography>
            </Button>
            &ensp;
            <Button
              variant="outlined"
              style={{
                width: "100px",
                color: "#fff",
                backgroundColor: "#1e88e5",
              }}
              onClick={handleSubmit(_submit)}
            >
              <Typography variant="caption" style={{ padding: "4px 10px" }}>
                儲存變更
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

// NOTE 訂單內容dispatch
function _reducerDispatch(state, action) {
  switch (action.type) {
    case "open":
      return action.data;
    case "close":
      return null;
    default:
      return state;
  }
}
function PastOrders() {
  const { alert } = useAlert();
  const theme = useTheme();
  const isDownTablet = useMediaQuery("(max-width:1024px)");
  const isPhone = useMediaQuery("(max-width:576px)");
  const useStyles = makeStyles((theme) => ({
    shopInfoText: {
      letterSpacing: "1.2px",
    },
    gridBox: {
      borderTop: "1px solid rgba(0,0,0,0.4)",
      padding: "1.5em 0",
      color: theme.palette.grey[800],
      display: "flex",
      alignItems: "center",
    },
    cartImg: {
      width: "50%",
      height: "100px",
      objectFit: "contain",
    },
    cartImgMobile: {
      width: "80px",
      height: "80px",
      objectFit: "contain",
    },
  }));

  const classes = useStyles();

  const ordersInfoTitle = [
    {
      text: "訂單編號",
    },
    {
      text: "總金額(含運費)",
    },
    {
      text: "狀態",
    },
    {
      text: "收件資訊",
    },
    {
      text: "運送方式",
    },
    {
      text: "操作",
    },
  ];

  const shopInfoTitle = [
    {
      text: "商品資訊",
    },
    {
      text: "數量",
    },
    {
      text: "單價",
    },
    {
      text: "品牌",
    },
    {
      text: "容量",
    },
  ];

  const [orderId, setOrderId] = useState();

  const [getLogisticsMapData, { loading: logisticsMapDataLoading }] =
    useLazyQuery(GET_LOGISTICSMAPDATA, {
      onCompleted({ logisticsMapData }) {
        if (logisticsMapData) {
          const { cvsStoreId, cvsStoreName } = logisticsMapData;
          updateShipmentAddressDialogDispatch({
            type: "open",
            data: {
              cvsStoreId,
              cvsStoreName,
            },
          });
        }
      },
    });

  const { data, refetch, loading } = useQuery(GET_ORDERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: Number(localStorage.getItem("@twenty-seven-webMemberId")),
      personal: true,
      latest: true,
    },
    onCompleted({ orders }) {
      if (orders) {
        const twentySevenMemberSelectStoreRandomCode = localStorage.getItem(
          `@twenty-sevenMemberSelectStoreRandomCode`
        );
        if (twentySevenMemberSelectStoreRandomCode) {
          getLogisticsMapData({
            variables: { randomCode: twentySevenMemberSelectStoreRandomCode },
          });
        }
      }
    },
    onError() {
      return null;
    },
  });

  const [getOrder, { data: orderData, loading: orderLoading }] = useLazyQuery(
    GET_ORDER,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    }
  );

  useEffect(() => {
    if (Boolean(orderId)) {
      getOrder({ variables: { id: orderId } });
    }
  }, [orderId]);

  const [paymentMethodDialog, paymentMethodDialogDispatch] = useReducer(
    _reducerDispatch,
    null
  );
  function _paymentMethodDialogClose() {
    paymentMethodDialogDispatch({ type: "close" });
  }
  const [updateShipmentAddressDialog, updateShipmentAddressDialogDispatch] =
    useReducer(_reducerDispatch, null);
  function _updateShipmentAddressDialogClose() {
    updateShipmentAddressDialogDispatch({ type: "close" });
  }

  return (
    <Box>
      <PaymentMethodDialog
        clickData={paymentMethodDialog}
        onClose={_paymentMethodDialogClose}
      />
      <UpdateShipmentAddressDialog
        clickData={updateShipmentAddressDialog}
        onClose={(e) => {
          _updateShipmentAddressDialogClose();
          if (e === "mutation") {
            refetch();
          }
        }}
      />
      {/*ANCHOR 電腦出現  */}
      <Container
        maxWidth="md"
        style={{ display: isDownTablet ? "none" : "block" }}
      >
        <Grid container style={{ textAlign: "center" }}>
          {/* 上方資訊 */}
          <Grid container item md={12}>
            {Boolean(orderId) && (
              <Grid item md={2}>
                <Box mb={2} mt="1em">
                  <ButtonBase
                    style={{ width: "100%" }}
                    onClick={() => setOrderId(null)}
                  >
                    <KeyboardBackspace />
                  </ButtonBase>
                </Box>
              </Grid>
            )}
            {Boolean(orderId)
              ? shopInfoTitle.map((item, index) => {
                  return (
                    <Grid item md={Boolean(orderId) ? 2 : 3}>
                      <Box mb={2} mt="1em">
                        <Typography
                          variant="body2"
                          style={{ color: theme.palette.text.secondary }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })
              : ordersInfoTitle.map((item, index) => {
                  return (
                    <Grid item md={2}>
                      <Box mb={2} mt="1em">
                        <Typography
                          variant="body2"
                          style={{ color: theme.palette.text.secondary }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
            {Boolean(orderId) && <Grid item md={2} />}
          </Grid>
          {/* 下方購物商品資訊 */}

          {loading || orderLoading || logisticsMapDataLoading ? (
            <Grid container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <>
              {Boolean(data) && (
                <>
                  {Boolean(orderId) && Boolean(orderData) ? (
                    <Grid item container xs={12}>
                      {orderData.order.items.map((item) => {
                        return (
                          <Grid container xs={12} className={classes.gridBox}>
                            <Grid xs={4}>
                              <Box
                                display="flex"
                                style={{ paddingLeft: "2em" }}
                                alignItems="center"
                              >
                                <img
                                  src={item.product.images[0].location}
                                  className={classes.cartImg}
                                />
                                <Typography
                                  variant="body2"
                                  className={classes.shopInfoText}
                                >
                                  {item.product.name}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid xs={2}>
                              <Typography
                                variant="body2"
                                className={classes.shopInfoText}
                              >
                                {item.amount}
                              </Typography>
                            </Grid>

                            <Grid xs={2}>
                              <Typography
                                variant="body2"
                                className={classes.shopInfoText}
                              >
                                {thousandsSeparator(item.unitPrice)}
                              </Typography>
                            </Grid>

                            <Grid xs={2}>
                              <Typography
                                variant="body2"
                                className={classes.shopInfoText}
                              >
                                {item.product.brand}
                              </Typography>
                            </Grid>

                            <Grid xs={2}>
                              <Typography
                                variant="body2"
                                className={classes.shopInfoText}
                              >
                                {item.product.capacity}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid item container xs={12}>
                      {data.orders.contents.map((item) => {
                        const orderPayment =
                          emptyArray(item.payments).length > 0
                            ? item.payments[item.payments.length - 1]
                            : undefined;
                        return (
                          <ButtonBase
                            key={item.id}
                            style={{ width: "100%" }}
                            onClick={() => setOrderId(item.id)}
                          >
                            <Grid xs={12} className={classes.gridBox}>
                              <Grid
                                item
                                container
                                xs={2}
                                justifyContent="center"
                              >
                                <Typography variant="body2">
                                  {item.serialNumber}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                container
                                xs={2}
                                justifyContent="center"
                              >
                                <Typography variant="body2">
                                  {thousandsSeparator(item.totalPrice)}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                container
                                xs={2}
                                justifyContent="center"
                                direction="column"
                              >
                                <Grid item xs={12}>
                                  <Typography variant="body2">
                                    {orderStatusEnum[item.status]}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    borderBottom:
                                      item.shipmentAddress.cvsPaymentNo &&
                                      item.shipmentAddress.cvsValidationNo &&
                                      "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Typography variant="body2">
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </Typography>
                                </Grid>
                                {item.shipmentAddress.cvsPaymentNo &&
                                  item.shipmentAddress.cvsValidationNo && (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography variant="body2">
                                          超商單號
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography variant="body2">
                                          {item.shipmentAddress.cvsPaymentNo +
                                            item.shipmentAddress
                                              .cvsValidationNo}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                              </Grid>
                              <Grid
                                item
                                container
                                xs={2}
                                style={{
                                  padding: isPhone ? "0.5em" : "1em",
                                  borderRight: "1px solid rgba(0,0,0,0.4)",
                                }}
                              >
                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    收件人
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {item.shipmentAddress?.recipient}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    收件人電話
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    {item.shipmentAddress?.recipientPhone}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={2}
                                style={{
                                  padding: isPhone ? "0.5em" : "1em",
                                  borderRight: "1px solid rgba(0,0,0,0.4)",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {item.shipmentAddress.shipmentMethod ===
                                    "CONVENIENCE_STORE"
                                      ? "超商取貨"
                                      : "宅配"}
                                    {item.shipmentAddress?.isCollection
                                      ? "-貨到付款"
                                      : ""}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    地址
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    {item.shipmentAddress?.shipmentMethod ===
                                    "CONVENIENCE_STORE"
                                      ? `${
                                          item.shipmentAddress?.shipmentSubType
                                            ? shipmentSubTypeEnum[
                                                item.shipmentAddress
                                                  ?.shipmentSubType
                                              ]
                                            : ""
                                        }${
                                          item.shipmentAddress?.convenienceStore
                                        }`
                                      : item.shipmentAddress?.district
                                      ? getCounty(
                                          item.shipmentAddress?.district
                                        ) +
                                        getDistrict(
                                          item.shipmentAddress?.district,
                                          item.shipmentAddress?.subdistrict
                                        ) +
                                        item.shipmentAddress?.address
                                      : ""}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                xs={2}
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                              >
                                <Grid item container justifyContent="center">
                                  <Button
                                    variant="contained"
                                    style={{ width: "80%" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        item.status === "WAITING_FOR_PAYMENT"
                                      ) {
                                        if (
                                          !orderPayment?.success &&
                                          orderPayment?.paymentMethod === "ATM"
                                        ) {
                                          alert(
                                            "",
                                            (() => {
                                              return (
                                                <Typography variant="subtitle2">
                                                  請先連絡客服查詢撥款狀況，如未付款請點選重新付款！
                                                </Typography>
                                              );
                                            })(),
                                            [
                                              {
                                                text: "重新付款",
                                                onClick: () => {
                                                  paymentMethodDialogDispatch({
                                                    type: "open",
                                                    data: {
                                                      orderId: item.id,
                                                    },
                                                  });
                                                },
                                                type: "outlined",
                                              },
                                              {
                                                text: "關閉",
                                              },
                                            ]
                                          );
                                        } else {
                                          paymentMethodDialogDispatch({
                                            type: "open",
                                            data: {
                                              orderId: item.id,
                                            },
                                          });
                                        }
                                      } else {
                                        paymentMethodDialogDispatch({
                                          type: "open",
                                          data: {
                                            orderId: item.id,
                                            payment: orderPayment,
                                          },
                                        });
                                      }
                                    }}
                                    disabled={
                                      item.shipmentAddress.isCollection ||
                                      item.totalPrice === 0
                                    }
                                  >
                                    {item.status === "WAITING_FOR_PAYMENT"
                                      ? "重新付款"
                                      : item.shipmentAddress.isCollection
                                      ? "貨到付款"
                                      : orderPayment?.paymentMethod
                                      ? "付款資訊"
                                      : "全額折抵"}
                                  </Button>
                                </Grid>
                                {item.status === "READY_FOR_SHIPMENT" && (
                                  <Grid item container justifyContent="center">
                                    <Button
                                      variant="outlined"
                                      style={{ width: "80%" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        updateShipmentAddressDialogDispatch({
                                          type: "open",
                                          data: {
                                            orderId: item.id,
                                            shipmentId: item.shipmentAddress.id,
                                            shipmentMethod:
                                              item.shipmentAddress
                                                ?.shipmentMethod,
                                            isCollection:
                                              item.shipmentAddress.isCollection,
                                          },
                                        });
                                      }}
                                      fullWidth
                                    >
                                      修改出貨地址
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </ButtonBase>
                        );
                      })}
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Container>

      {/* ANCHOR 平板出現 */}
      <Grid container style={{ display: isDownTablet ? "block" : "none" }}>
        {/* 上方名稱 */}
        <Grid item container style={{ marginTop: "1em" }} xs={12}>
          {Boolean(orderId) && (
            <Grid item xs={2}>
              <Box ml={2}>
                <ButtonBase
                  style={{ width: "100%" }}
                  onClick={() => setOrderId(null)}
                >
                  <KeyboardBackspace />
                </ButtonBase>
              </Box>
            </Grid>
          )}
          <Grid item xs={Boolean(orderId) ? 6 : 6}>
            <Box ml={2}>
              <Typography variant="body2">
                {Boolean(orderId) ? "商品資訊" : "訂單資訊"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={Boolean(orderId) ? 4 : 6}>
            <Typography variant="body2" style={{ textAlign: "center" }}>
              {Boolean(orderId) ? "數量" : "收件資訊"}
            </Typography>
          </Grid>
        </Grid>

        {/* 下方資訊ANCHOR */}
        {loading || orderLoading ? (
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            {Boolean(data) && (
              <>
                {Boolean(orderId) && Boolean(orderData) ? (
                  <Grid container xs={12} style={{ margin: "1em 0" }}>
                    {orderData.order.items.map((item) => {
                      return (
                        <Grid style={{ width: "100%" }}>
                          <Grid item container xs={12}>
                            <Grid item container xs={8}>
                              {/* map */}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                style={{
                                  padding: "10px 15px",
                                  border: "1px solid rgba(0,0,0,0.4)",
                                  borderLeft: "none",
                                }}
                              >
                                <Grid item xs={5}>
                                  <Box>
                                    <img
                                      src={item.product.images[0].location}
                                      className={classes.cartImgMobile}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="body2">
                                    {item.product.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* 同個商品下的時間 */}
                              <Grid
                                item
                                container
                                xs={12}
                                style={{
                                  padding: isPhone ? "0.5em" : "1em",
                                  borderRight: "1px solid rgba(0,0,0,0.4)",
                                }}
                              >
                                <Grid item xs={5}>
                                  <Typography variant="caption">
                                    單價
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="caption">
                                    {thousandsSeparator(item.unitPrice)}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="caption">
                                    品牌
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="caption">
                                    {item.product.brand}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                  <Typography variant="caption">
                                    容量
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="caption">
                                    {item.product.capacity}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              container
                              alignItems="center"
                              justify="center"
                              style={{ borderTop: "1px solid rgba(0,0,0,0.4)" }}
                            >
                              <Box textAlign="center">
                                <Typography variant="h6">
                                  {item.amount}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid container xs={12} style={{ margin: "1em 0" }}>
                    {data.orders.contents.map((item) => {
                      const orderPayment =
                        emptyArray(item.payments).length > 0
                          ? item.payments[item.payments.length - 1]
                          : undefined;
                      return (
                        <Fragment key={item.id}>
                          <ButtonBase
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            onClick={() => setOrderId(item.id)}
                          >
                            <Grid item container xs={12}>
                              <Grid item container xs={6}>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{
                                    padding: "5px 5px",
                                    border: "1px solid rgba(0,0,0,0.4)",
                                    borderLeft: "none",
                                    borderBottom: "none",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      訂單編號
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {item.serialNumber}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{
                                    padding: "5px 5px",
                                    borderRight: "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      總金額(含運費)
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {thousandsSeparator(item.totalPrice)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{
                                    padding: "5px 5px",
                                    borderRight: "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      訂單狀態
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {orderStatusEnum[item.status]}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      {moment(item.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {item.shipmentAddress.cvsPaymentNo &&
                                  item.shipmentAddress.cvsValidationNo && (
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      style={{
                                        padding: "5px 5px",
                                        borderRight:
                                          "1px solid rgba(0,0,0,0.4)",
                                      }}
                                    >
                                      <Grid item xs={12}>
                                        <Typography variant="caption">
                                          超商單號
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent="center"
                                      >
                                        <Typography variant="caption">
                                          {item.shipmentAddress.cvsPaymentNo +
                                            item.shipmentAddress
                                              .cvsValidationNo}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                container
                                alignItems="center"
                                justify="center"
                                style={{
                                  borderTop: "1px solid rgba(0,0,0,0.4)",
                                }}
                              >
                                <Grid item container xs={12}>
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      收件人
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {item.shipmentAddress?.recipient}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,0.4)",
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      收件人電話
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {item.shipmentAddress?.recipientPhone}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      運送方式
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {item.shipmentAddress.shipmentMethod ===
                                      "CONVENIENCE_STORE"
                                        ? "超商取貨"
                                        : "宅配"}
                                      {item.shipmentAddress?.isCollection
                                        ? "-貨到付款"
                                        : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      地址
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="caption">
                                      {item.shipmentAddress?.shipmentMethod ===
                                      "CONVENIENCE_STORE"
                                        ? `${
                                            item.shipmentAddress
                                              ?.shipmentSubType
                                              ? shipmentSubTypeEnum[
                                                  item.shipmentAddress
                                                    ?.shipmentSubType
                                                ]
                                              : ""
                                          }${
                                            item.shipmentAddress
                                              ?.convenienceStore
                                          }`
                                        : item.shipmentAddress?.district
                                        ? getCounty(
                                            item.shipmentAddress?.district
                                          ) +
                                          getDistrict(
                                            item.shipmentAddress?.district,
                                            item.shipmentAddress?.subdistrict
                                          ) +
                                          item.shipmentAddress?.address
                                        : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Box
                              display="flex"
                              flexDirection="row"
                              width="100%"
                            >
                              <Box display="flex" flex={1}>
                                <ButtonBase
                                  style={{
                                    padding: "5px 5px",
                                    border: "1px solid rgba(0,0,0,0.4)",
                                    borderLeft: "none",
                                    borderBottom: "none",
                                    alignItems: "center",
                                    height: 32,
                                    width: "100%",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.status === "WAITING_FOR_PAYMENT") {
                                      if (
                                        !orderPayment?.success &&
                                        orderPayment?.paymentMethod === "ATM"
                                      ) {
                                        alert(
                                          "",
                                          (() => {
                                            return (
                                              <Typography variant="subtitle2">
                                                請先連絡客服查詢撥款狀況，如未付款請點選重新付款！
                                              </Typography>
                                            );
                                          })(),
                                          [
                                            {
                                              text: "重新付款",
                                              onClick: () => {
                                                paymentMethodDialogDispatch({
                                                  type: "open",
                                                  data: {
                                                    orderId: item.id,
                                                  },
                                                });
                                              },
                                              type: "outlined",
                                            },
                                            {
                                              text: "關閉",
                                            },
                                          ]
                                        );
                                      } else {
                                        paymentMethodDialogDispatch({
                                          type: "open",
                                          data: {
                                            orderId: item.id,
                                          },
                                        });
                                      }
                                    } else {
                                      paymentMethodDialogDispatch({
                                        type: "open",
                                        data: {
                                          orderId: item.id,
                                          payment: orderPayment,
                                        },
                                      });
                                    }
                                  }}
                                  disabled={
                                    item.shipmentAddress.isCollection ||
                                    item.totalPrice === 0
                                  }
                                >
                                  {item.status === "WAITING_FOR_PAYMENT"
                                    ? "重新付款"
                                    : item.shipmentAddress.isCollection
                                    ? "貨到付款"
                                    : orderPayment?.paymentMethod
                                    ? "付款資訊"
                                    : "全額折抵"}
                                </ButtonBase>
                              </Box>
                              {item.status === "READY_FOR_SHIPMENT" && (
                                <Box display="flex" flex={1}>
                                  <ButtonBase
                                    style={{
                                      padding: "5px 5px",
                                      border: "1px solid rgba(0,0,0,0.4)",
                                      borderLeft: "none",
                                      borderBottom: "none",
                                      alignItems: "center",
                                      height: 32,
                                      width: "100%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateShipmentAddressDialogDispatch({
                                        type: "open",
                                        data: {
                                          orderId: item.id,
                                          shipmentId: item.shipmentAddress.id,
                                          shipmentMethod:
                                            item.shipmentAddress
                                              ?.shipmentMethod,
                                          isCollection:
                                            item.shipmentAddress.isCollection,
                                        },
                                      });
                                    }}
                                  >
                                    修改出貨地址
                                  </ButtonBase>
                                </Box>
                              )}
                            </Box>
                          </ButtonBase>
                        </Fragment>
                      );
                    })}
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

const PaymentMethodDialog = memo(function PaymentMethodDialog({
  clickData,
  onClose = () => {},
}) {
  const { notify } = useAlert();

  const [paymentJump, setPaymentJump] = useState(false);
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      paymentMethod: "",
    },
  });

  const [retryPayment, { loading: retryPaymentLoading }] = useMutation(
    RETRY_PAYMENT,
    {
      onCompleted({ retryPayment }) {
        if (retryPayment.success) {
          if (retryPayment.order.paymentUrl) {
            window.location =
              retryPayment.order.paymentUrl +
              "&paymentMethod=" +
              getValues("paymentMethod");
          }
        } else if (retryPayment.message) {
          notify(retryPayment.message);
          setPaymentJump(false);
        }
      },
    }
  );

  function _retryPayment() {
    setPaymentJump(true);
    retryPayment({ variables: { orderId: clickData?.orderId } });
  }

  function _onClose() {
    if (!retryPaymentLoading || !paymentJump) {
      setPaymentJump(false);
      onClose();
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={Boolean(clickData)}
      onClose={_onClose}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        {clickData?.payment ? "付款資訊" : "選擇付款方式"}
        <IconButton
          onClick={_onClose}
          size="small"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {clickData?.payment ? (
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">付款方式：</Typography>
              <Typography variant="subtitle2">
                {clickData?.payment.paymentMethod === "CREDIT_CARD"
                  ? "信用卡"
                  : "ATM"}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">訂單編號：</Typography>
              <Typography variant="subtitle2">
                {clickData?.payment.tradeNumber}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">付款狀態：</Typography>
              <Typography variant="subtitle2">
                {clickData?.payment.success ? "成功" : "失敗或未完成撥款"}
              </Typography>
            </Box>
            {clickData?.payment.paymentMethod === "ATM" && (
              <>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">銀行代碼：</Typography>
                  <Typography variant="subtitle2">
                    {clickData?.payment.bankCode}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">虛擬帳號：</Typography>
                  <Typography variant="subtitle2">
                    {clickData?.payment.virtualAccount}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">過期時間：</Typography>
                  <Typography variant="subtitle2">
                    {clickData?.payment.expireDate}
                  </Typography>
                </Box>
              </>
            )}
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">總金額：</Typography>
              <Typography variant="subtitle2">
                {thousandsSeparator(clickData?.payment.totalPrice)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">付款時間：</Typography>
              <Typography variant="subtitle2">
                {moment(clickData?.payment.updatedAt).format("YYYY-MM-DD")}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Controller
            name="paymentMethod"
            control={control}
            rules={{ required: "請選擇付款方式" }}
            render={({ field, fieldState: { error } }) => (
              <SelectComponent
                {...field}
                label="付款方式"
                listArray={selectPaymentMethodList.filter(
                  (i) => i.value !== "ISCOLLECTION"
                )}
                emptyLabel={"請選擇付款方式"}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        )}
      </DialogContent>
      {!clickData?.payment && (
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(_retryPayment)}
            disabled={paymentJump || retryPaymentLoading}
          >
            {paymentJump ? "付款轉跳中..." : "確定付款"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
});

const UpdateShipmentAddressDialog = memo(function UpdateShipmentAddressDialog({
  clickData,
  onClose = () => {},
}) {
  const { location } = useHistory();
  const { notify } = useAlert();
  const { control, setValue, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      recipient: "",
      recipientPhone: "",
      shipmentSubType: "",
      cvsStoreId: "",
      convenienceStore: "",
      district: "",
      subdistrict: "",
      address: "",
      shipmentMethod: "",
      orderId: "",
      shipmentId: "",
      isCollection: "",
    },
  });

  useEffect(() => {
    if (clickData && clickData?.cvsStoreId && clickData?.cvsStoreName) {
      const twentySevenMemberSelectStoreGetValues = localStorage.getItem(
        "@twenty-sevenMemberSelectStoreGetValues"
      );
      if (twentySevenMemberSelectStoreGetValues) {
        const {
          recipient,
          recipientPhone,
          shipmentSubType,
          shipmentMethod,
          shipmentId,
          isCollection,
        } = JSON.parse(twentySevenMemberSelectStoreGetValues);
        setValue("recipient", recipient);
        setValue("recipientPhone", recipientPhone);
        setValue("shipmentSubType", shipmentSubType);
        setValue("shipmentMethod", shipmentMethod);
        setValue("shipmentId", shipmentId);
        setValue("isCollection", isCollection);
        setValue("cvsStoreId", clickData?.cvsStoreId);
        setValue("convenienceStore", clickData?.cvsStoreName);
      }
      setTimeout(() => {
        localStorage.removeItem(`@twenty-sevenMemberSelectStoreRandomCode`);
        localStorage.removeItem(`@twenty-sevenMemberSelectStoreGetValues`);
      }, 0);
    } else {
      setValue("shipmentMethod", clickData?.shipmentMethod);
      setValue("orderId", clickData?.orderId);
      setValue("shipmentId", clickData?.shipmentId);
      setValue("isCollection", clickData?.isCollection);
    }
  }, [clickData]);

  const [updateShipmentAddress, { loading: updateShipmentAddressLoading }] =
    useMutation(UPDATE_SHIPMENTADDRESS, {
      onCompleted({ updateShipmentAddress }) {
        if (updateShipmentAddress.success) {
          notify("出貨地址更改成功！");
          onClose("mutation");
          reset();
        } else if (updateShipmentAddress.message) {
          notify(updateShipmentAddress.message);
        }
      },
    });

  const [
    createNewEcpayShipmentOrder,
    { loading: createNewEcpayShipmentOrderLoading },
  ] = useMutation(CREATE_NEWECPAYSHIPMENTORDER, {
    onCompleted({ createNewEcpayShipmentOrder }) {
      if (createNewEcpayShipmentOrder.success) {
        notify("出貨地址更改成功！");
        onClose("mutation");
        reset();
      } else if (createNewEcpayShipmentOrder.message) {
        notify(createNewEcpayShipmentOrder.message);
      }
    },
  });

  function _submit(data) {
    if (data.shipmentMethod === "HOME_DELIVERY") {
      const shipmentAddressInput = {
        recipient: data.recipient,
        recipientPhone: data.recipientPhone,
        shipmentMethod: data.shipmentMethod,
        isCollection: data.isCollection,
        district: data.district,
        subdistrict: data.subdistrict,
        address: data.address,
      };
      updateShipmentAddress({
        variables: { orderId: data.orderId, shipmentAddressInput },
      });
    } else {
      const shipmentAddressInput = {
        recipient: data.recipient,
        recipientPhone: data.recipientPhone,
        shipmentMethod: data.shipmentMethod,
        isCollection: data.isCollection,
        cvsStoreId: data.cvsStoreId,
        convenienceStore: data.convenienceStore,
      };
      createNewEcpayShipmentOrder({
        variables: { shipmentId: data.shipmentId, shipmentAddressInput },
      });
    }
  }

  function _onClose(value) {
    if (!updateShipmentAddressLoading || !createNewEcpayShipmentOrderLoading) {
      onClose(value);
      reset();
    }
  }

  const [goToLogisticsMap, { loading: logisticsMapLoading }] = useLazyQuery(
    GO_TO_LOGISTICSMAP,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ logisticsMap }) {
        if (logisticsMap) {
          window.location = logisticsMap;
        }
      },
    }
  );

  const chooseStore = async () => {
    const randomCode = getInvitationCode(7);
    localStorage.setItem(
      `@twenty-sevenMemberSelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@twenty-sevenMemberSelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        isCollection: getValues("isCollection"),
        shipmentSubType: getValues("shipmentSubType"),
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };

  return (
    <Dialog
      open={Boolean(clickData)}
      onClose={_onClose}
      fullWidth
      maxWidth="xs"
    >
      <LoadingModal loading={logisticsMapLoading} />
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        更改出貨地址
        <IconButton
          onClick={_onClose}
          size="small"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="recipient"
          rules={{
            required: "請輸入收件人名字",
            validate: (e) => {
              if (
                e &&
                (checkSpecialSymbolsAndNumber.test(e) ||
                  halfShapeAndFullForm(e) < 4 ||
                  halfShapeAndFullForm(e) > 10)
              ) {
                return "收件人名字請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldComponent
              {...field}
              label="收件人名字"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="recipientPhone"
          rules={{
            required: "請輸入收件人手機",
            validate: (e) => {
              if (e && !checkMobile.test(e)) {
                return "手機格式錯誤";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldComponent
              {...field}
              label="收件人手機"
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                field.onChange(onlyNums);
              }}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        {clickData?.shipmentMethod === "HOME_DELIVERY" ? (
          <>
            <Controller
              name="district"
              control={control}
              rules={{ required: "請選擇縣市" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="縣市"
                  listArray={countiesList("zhTW")}
                  onChange={(e) => {
                    setValue("subdistrict", "");
                    field.onChange(e.target.value);
                  }}
                  emptyLabel={"請選擇縣市"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <FieldCache
              control={control}
              name="district"
              render={(district) => (
                <Controller
                  name="subdistrict"
                  control={control}
                  rules={{ required: "請選擇鄉鎮市區" }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectComponent
                      {...field}
                      label="鄉鎮市區"
                      listArray={districtsList(district)}
                      emptyLabel={"請選擇鄉鎮市區"}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="address"
              rules={{ required: "請輸入地址" }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldComponent
                  {...field}
                  label="地址"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </>
        ) : (
          <>
            <Controller
              name="shipmentSubType"
              control={control}
              rules={{ required: "請選擇超商" }}
              render={({ field, fieldState: { error } }) => (
                <SelectComponent
                  {...field}
                  label="選擇超商"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue("convenienceStore", "");
                  }}
                  listArray={shipmentSubTypedList}
                  emptyLabel={"請選擇超商"}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <FieldCache
              control={control}
              name="shipmentSubType"
              render={(shipmentSubType) => {
                if (shipmentSubType) {
                  return (
                    <Controller
                      control={control}
                      name="convenienceStore"
                      rules={{ required: "請選擇超商門市" }}
                      render={({ field, fieldState: { error } }) => (
                        <TextFieldComponent
                          {...field}
                          id="cvsStoreNameTextField"
                          label="超商門市"
                          error={Boolean(error)}
                          helperText={error?.message}
                          fullWidth
                          onClick={chooseStore}
                          inputProps={{ readOnly: true }}
                        />
                      )}
                    />
                  );
                } else return null;
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit(_submit)}
              disabled={
                updateShipmentAddressLoading ||
                createNewEcpayShipmentOrderLoading
              }
            >
              {updateShipmentAddressLoading ||
              createNewEcpayShipmentOrderLoading
                ? "處理中..."
                : "確定"}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={_onClose}>
              關閉
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});

import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { useAlert } from "../components/Alert";
import TabView from "../components/TabView";
import PictureTable from "./component/PictureTable";

export default function PictureListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["列表"],
    select: 0,
    dataObject: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  return (
    <Grid>
      <TabView
        paper
        tabs={newData.tabViewData}
        index={newData.select}
        onChange={(e) => {
          changeData("select", e, true);
        }}
      >
        <PictureTable />
      </TabView>
    </Grid>
  );
}

import React, { Fragment, useState, useMemo } from "react";
import { Box, Grid, ButtonBase, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import "./SliderImg.css";
import "keen-slider/keen-slider.min.css";
// auto play
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const GET_CAROUSEL = gql`
  query carousel($section: Section) {
    carousel(section: $section) {
      id
      section
      seconds
      contents {
        id
        image {
          location
        }
        link
      }
    }
  }
`;

export default function SliderImg() {
  const [bannerItemArray, setBannerItemArray] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const useStyles = makeStyles({
    hoverLink: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      paddingTop: "43.75%",
      "&:hover": {
        cursor: bannerItemArray[imageIndex]?.link && "pointer",
      },
    },
  });
  const classes = useStyles();

  const { data, loading } = useQuery(GET_CAROUSEL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      section: "HOME_PAGE",
    },
    onCompleted({ carousel }) {
      if (carousel) {
        const newCarousel = [];
        carousel.contents.forEach((item) => {
          newCarousel.push({ ...item });
        });
        setBannerItemArray(() => {
          setImageIndex(0);
          return newCarousel;
        });
      }
    },
    onError() {
      return null;
    },
  });

  const newSeconds = useMemo(() => {
    let value = 2000;
    if (data) {
      value = isNaN(data.carousel.seconds)
        ? 2000
        : data.carousel.seconds * 1000;
    }
    return value;
  }, [data]);

  if (loading) {
    return (
      <Box
        style={{
          width: "100%",
          paddingBottom: "43.75%",
          height: 0,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            transform="none"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Fragment>
        <AutoPlaySwipeableViews
          autoPlay
          index={imageIndex}
          onChangeIndex={(e) => setImageIndex(e)}
          enableMouseEvents
          style={{ width: "100%" }}
          interval={newSeconds}
        >
          {bannerItemArray.length !== 0 &&
            bannerItemArray.map((item, index) => (
              <Box
                key={index}
                className={classes.hoverLink}
                onClick={() => item.link && window.open(item.link)}
              >
                <img
                  src={item.image.location}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt="images"
                />
              </Box>
            ))}
        </AutoPlaySwipeableViews>
        <Box display="flex" width="100%" padding={1}>
          <Grid container spacing={1} justify="center">
            {bannerItemArray.length !== 0 &&
              bannerItemArray.map((_, index) => (
                <Grid key={index} item>
                  <ButtonBase onClick={() => setImageIndex(index)}>
                    <Box
                      height={12}
                      width={12}
                      borderRadius={6}
                      bgcolor={index === imageIndex ? "black" : "gray"}
                    />
                  </ButtonBase>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Fragment>
    );
  }
}

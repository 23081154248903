import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  useTheme,
  Typography,
  Box,
  Button,
  ButtonBase,
  useMediaQuery,
  CircularProgress,
  Card,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// import CloseIcon from "@material-ui/icons/Close";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import SwipeableViews from "react-swipeable-views";

import SquareBox from "../components/SquareBox";
import { useAlert } from "../components/Alert";
import { splitArray } from "../components/utils";

const GET_PRODUCT = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      images {
        filename
        mimetype
        encoding
        location
      }
      categoryId
      name
      brand
      usage
      instruction
      feature
      note
      capacity
      price
      shipmentFee
      stock
      enable
      contents {
        id
        type
        body
      }
    }
  }
`;

const GET_PRODUCTS = gql`
  query products {
    products {
      contents {
        id
        images {
          filename
          mimetype
          encoding
          location
        }
        categoryId
        name
        brand
        capacity
        price
        shipmentFee
        stock
        enable
        contents {
          id
          type
          body
        }
      }
    }
  }
`;

const GET_CART = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          images {
            location
          }
          name
          price
        }
        unitPrice
        amount
      }
      totalPrice
    }
  }
`;

const ADD_ITEMTOCART = gql`
  mutation addItemToCart($id: Int!, $cartItemInput: CartItemInput!) {
    addItemToCart(id: $id, cartItemInput: $cartItemInput) {
      success
      message
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    borderRadius: 0,
    width: "100%",
    border: "1px solid black",
  },
  imageBox: {
    backgroundColor: "lightgray",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  imageSmallBox: {
    marginRight: "20px",
    backgroundColor: "lightgray",
  },
  imageSmall: {
    maxHeight: "120px",
    minWidth: "120px",
    objectFit: "contain",
  },
  itemImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    padding: "10px",
  },
  itemButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
  prodocutInfo: {
    letterSpacing: "1.5px",
    fontFamily: '"微軟正黑體",GENBKBASR',
  },
  prodocutInfoTitle: {
    letterSpacing: "1.5px",
    fontFamily: '"微軟正黑體",GENBKBASR',
    fontSize: "1.1em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
}));

export default function ProductDetailPage() {
  const Alert = useAlert();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const [amount, setamount] = useState(1);
  const [newImages, setNewImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [floatCartPcOpen, setFloatCartPcOpen] = useState(false);
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const buyNowRef = useRef();

  const [getProduct, { data, loading }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ product }) {
      if (product) {
        const newNewImages = [];
        product.images.forEach((item) => {
          delete item.__typename;
          newNewImages.push({ ...item });
        });
        setNewImages(newNewImages);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getProduct({ variables: { id: Number(id) } });
    }
  }, [id]);

  useEffect(() => {
    window.fbq("track", "ViewContent");
  }, []);

  function fisherYatesShuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); //random index
      [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
    }
    return arr;
  }

  const { data: productsData, loading: productsLoading } = useQuery(
    GET_PRODUCTS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    }
  );

  //加入購物車
  function addCartBtn(value) {
    addItemToCart({
      variables: {
        id: JSON.parse(localStorage.getItem("@twenty-seven-webCartId")),
        cartItemInput: {
          productId: value,
          amount,
        },
      },
    });
  }

  const [addItemToCart] = useMutation(ADD_ITEMTOCART, {
    onCompleted({ addItemToCart }) {
      if (addItemToCart.success) {
        window.fbq("track", "AddToCart", {
          content_type: "product",
          content_ids: data.product.id,
          content_name: data.product.name,
          currency: "TWD",
          value: data.product.price,
        });
        if (buyNowRef.current) {
          buyNowRef.current = false;
          history.push("/cart-page");
        } else {
          clearfloat();
          setFloatCartPcOpen(true);
        }
      } else if (addItemToCart.message) {
        return Alert.notify(addItemToCart.message);
      }
    },
  });

  if (loading || productsLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Container container>
        <Grid
          style={{
            display: "none",
            position: "fixed",
            top: "144px",
            left: "0px",
            zIndex: "100",
          }}
          id="floatCart"
        >
          <FloatCartPc
            floatCartPcOpen={floatCartPcOpen}
            onCloseFloatCartPc={(e) => setFloatCartPcOpen(false)}
          />
        </Grid>
        <Grid style={{ padding: "20px", marginTop: "20px" }}>
          <Grid container item xs={12}>
            <Grid container item xs={12} sm={6}>
              <Box padding={1} display="flex" flex={1}>
                <ProductImages
                  index={imageIndex}
                  onChangeIndex={setImageIndex}
                  data={splitArray(
                    newImages,
                    (() => {
                      if (isMobile) {
                        return 2;
                      } else if (isTable) {
                        return 3;
                      } else {
                        return 4;
                      }
                    })()
                  )}
                  sizeValue={[isTable, isMobile]}
                />
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              direction="column"
              justifyContent="space-between"
              style={{ paddingTop: "10px", paddingLeft: "20px" }}
            >
              <Box>
                <Typography variant="h5" className={classes.title}>
                  {data.product.name}
                </Typography>
                <Typography variant="subtitle1">
                  {data.product.brand}
                </Typography>
              </Box>
              <Box>
                {data.product.usage && (
                  <>
                    <Typography variant="h5" className={classes.title}>
                      產品用途
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {data.product.usage}
                    </Typography>
                  </>
                )}
                {data.product.instruction && (
                  <>
                    <Typography variant="h5" className={classes.title}>
                      使用方法
                    </Typography>
                    <Typography variant="subtitle1">
                      {data.product.instruction}
                    </Typography>
                  </>
                )}
                {data.product.note && (
                  <>
                    <Typography variant="h5" className={classes.title}>
                      香調
                    </Typography>
                    <Typography variant="subtitle1">
                      {data.product.note}
                    </Typography>
                  </>
                )}
                {data.product.feature && (
                  <>
                    <Typography variant="h5" className={classes.title}>
                      商品特色
                    </Typography>
                    <Typography variant="subtitle1">
                      {data.product.feature}
                    </Typography>
                  </>
                )}
              </Box>
              <Box>
                <Typography variant="h6">
                  {data.product.capacity}&ensp;/&ensp;NT.{data.product.price}
                </Typography>
                <Typography variant="subtitle1">數量</Typography>
                <AddCartButton
                  amount={amount}
                  setamount={setamount}
                  disabled={data.product.stock === 0 || !data.product.enable}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => addCartBtn(data.product.id)}
                      disabled={
                        data.product.stock === 0 || !data.product.enable
                      }
                    >
                      <Typography variant="h6" style={{ color: "white" }}>
                        加入購物車
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        buyNowRef.current = true;
                        addCartBtn(data.product.id);
                      }}
                      disabled={
                        data.product.stock === 0 || !data.product.enable
                      }
                    >
                      <Typography variant="h6">
                        {data.product.stock === 0
                          ? "已售完"
                          : !data.product.enable
                          ? "已下架"
                          : "立即購買"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
            <Grid container direction="column" spacing={1}>
              <Typography variant="h6" component="h1">
                {data.product.itemName}&ensp;|&ensp;商品介紹
              </Typography>
              {data.product.contents.map((item, index) => (
                <Grid container item key={index}>
                  <DetailArea data={item} />
                </Grid>
              ))}
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(3)}px 0` }}
            />
            <Grid container direction="column" spacing={1}>
              <Grid container item>
                <Typography variant="h6" component="h1" gutterBottom>
                  相關商品
                </Typography>
                <Grid container item xs={12} direction="row" spacing={1}>
                  {fisherYatesShuffle(productsData.products.contents)
                    .slice(0, 4)
                    .map((item, index) => (
                      <Grid key={index} item xs={12} sm={6} md={3}>
                        <ButtonBase
                          className={classes.itemButton}
                          onClick={() =>
                            history.push(`/product-detail/${item.id}`)
                          }
                        >
                          <Card variant="outlined">
                            <SquareBox>
                              <img
                                src={item.images[0].location}
                                className={classes.itemImage}
                              />
                            </SquareBox>
                          </Card>
                          <CardContent>
                            <Box>
                              <Typography
                                align="center"
                                variant="body2"
                                className={classes.prodocutInfo}
                              >
                                {item.brand}
                              </Typography>
                              <Typography
                                align="center"
                                variant="body1"
                                className={classes.prodocutInfoTitle}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                align="center"
                                variant="body2"
                                className={classes.prodocutInfo}
                              >
                                {item.capacity}&ensp;/&ensp;Nt.{item.price}
                              </Typography>
                            </Box>
                          </CardContent>
                        </ButtonBase>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return null;
  }
}

function ProductImages({ data, sizeValue, index, onChangeIndex }) {
  const theme = useTheme();
  const [selectImage, setSelectImage] = useState(null);
  useEffect(() => {
    onChangeIndex(0);
  }, sizeValue);
  useEffect(() => {
    if (Boolean(data[0])) {
      setSelectImage(data[0][0].location);
    }
  }, [data]);
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignContent="space-between">
      <Grid item xs={12}>
        <Card variant="outlined" className={classes.imageBox}>
          <SquareBox>
            <img src={selectImage} className={classes.image} />
          </SquareBox>
        </Card>
      </Grid>
      <Grid container item>
        <SwipeableViews
          style={{ width: "100%" }}
          index={index}
          onChangeIndex={onChangeIndex}
          enableMouseEvents
        >
          {data.map((item, index) => (
            <Box padding={0.5} key={index}>
              <Grid container spacing={1}>
                {item.map((item2) => {
                  const onSelect = item2.location === selectImage;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={item2.id}>
                      <Card
                        variant="outlined"
                        style={{
                          borderColor: onSelect && theme.palette.primary.main,
                          borderWidth: onSelect && 2,
                        }}
                        className={classes.imageBox}
                      >
                        <CardActionArea
                          onClick={() => setSelectImage(item2.location)}
                        >
                          <SquareBox>
                            <img
                              src={item2.location}
                              className={classes.image}
                            />
                          </SquareBox>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ))}
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}

function DetailArea({ data }) {
  switch (data.type) {
    case "HEADING": //"標題"
      return <Typography variant="h6">&ensp;&ensp;{data.body}</Typography>;
    case "PARAGRAPH": //"段落"
      return data.body.split("\n").map((line) => (
        <Typography>
          &ensp;&ensp;&ensp;
          {line}
          <br />
        </Typography>
      ));
    case "IMAGE": //"圖片"
      return (
        <img
          src={data.body}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            alignSelf: "flex-start",
          }}
        />
      );
    case "YOUTUBE_VIDEO": //"影片"
      let src = data.body;
      let embedUrl = "";
      if (src) {
        if (src.startsWith("http://") || src.startsWith("https://")) {
          if (src.search("embed") === -1) {
            if (src[8] === "w") {
              src = src.split("watch?v=")[1].split("&")[0];
            } else {
              src = src.split("youtu.be/")[1];
            }
          } else {
            embedUrl = src;
          }
        } else {
          return null;
        }
      }
      return (
        <iframe
          width="900"
          height="506"
          src={embedUrl || "https://www.youtube.com/embed/" + src}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          frameborder="0"
          allowfullscreen
        />
      );
    default:
      return null;
  }
}

function AddCartButton({ amount, setamount, disabled = false }) {
  const useStyles = makeStyles({
    amount: {
      width: "100%",
      height: "40px",
      marginBottom: "15px",
    },
    amountMiner: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "100%",
      height: "100%",
      borderRight: "none",
      cursor: !disabled && "pointer",
    },
    amountPlus: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "100%",
      height: "100%",
      borderLeft: "none",
      cursor: !disabled && "pointer",
    },
    amountInput: {
      textAlign: "center",
      width: "100%",
      height: "100%",
      border: "0.8px solid gray",
      "&:focus": {
        outline: "none",
      },
    },
  });
  const classes = useStyles();

  function count(prop) {
    if (prop === true) {
      setamount(amount + 1);
    } else if (amount <= 1) {
      setamount(1);
    } else {
      setamount(amount - 1);
    }
  }

  return (
    <Box>
      <Grid container className={classes.amount}>
        <Grid item xs={2} md={1}>
          <button
            className={classes.amountMiner}
            onClick={() => count(false)}
            disabled={disabled}
          >
            <RemoveIcon />
          </button>
        </Grid>
        <Grid xs={8} md={10}>
          <input
            type="text"
            placeholder={amount}
            value={amount === 0 ? null : amount}
            className={classes.amountInput}
            onChange={(e) => setamount(Number(e.target.value))}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={2} md={1}>
          <button
            className={classes.amountPlus}
            onClick={() => count(true)}
            disabled={disabled}
          >
            <AddIcon />
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}

let timer;

function clearfloat() {
  document.getElementById("floatCart").style.display = "block";
  timer = setTimeout(function () {
    if (document.getElementById("floatCart")) {
      document.getElementById("floatCart").style.display = "none";
    }
  }, 3000);
}

function cleartimer() {
  clearTimeout(timer);
}

// floatCart pc PC購物車黑色浮層
function FloatCartPc({ floatCartPcOpen, onCloseFloatCartPc = () => {} }) {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    floatCartText: {
      color: "#fff",
      letterSpacing: "1.3px",
    },
    floatCartDelText: {
      color: "#fff",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      transition: "all 0.3s",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.grey[300],
      },
    },
    floatCartDelButton: {
      position: "absolute",
      top: "10%",
      right: "3%",
      color: "#fff",
      "&:hover": {
        color: theme.palette.grey[300],
      },
    },
    goPay: {
      borderRadius: "0px",
      backgroundColor: [theme.palette.success.main],
      "&:hover": {
        backgroundColor: [theme.palette.success.light],
      },
    },
    goPayText: {
      padding: "10px 20px",
      color: theme.palette.common.white,
    },
  });
  const isSmPhone = useMediaQuery("(max-width:765px)");
  const classes = useStyles();
  const history = useHistory();

  //ANCHOR 刪除商品
  // function deleteProduct(productName) {
  //   setCartArray(function () {
  //     return cartArray.filter((item) => item.itemName !== productName);
  //   });
  // }

  useEffect(() => {
    const documentFloatCart = document.getElementById("floatCart");
    documentFloatCart.addEventListener(
      "mouseover",
      function () {
        cleartimer();
      },
      false
    );
    documentFloatCart.addEventListener(
      "mouseout",
      function () {
        clearfloat();
      },
      false
    );
  });

  const [getCart, { data, loading }] = useLazyQuery(GET_CART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ cart }) {
      if (cart) {
        onCloseFloatCartPc();
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (floatCartPcOpen) {
      const hasCartId = localStorage.getItem("@twenty-seven-webCartId");
      if (Boolean(hasCartId)) {
        getCart({
          variables: { id: JSON.parse(hasCartId) },
        });
      }
    }
  }, [floatCartPcOpen]);

  return (
    <Box>
      {/* 上方黑色浮層外框 */}
      <Box bgcolor="#4f4f4f" style={{ height: "100vh" }}>
        {/* 黑色浮層ul */}
        {loading ? (
          <Box bgcolor="#4f4f4f" style={{ width: "20vw" }}>
            <Grid container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          </Box>
        ) : (
          <>
            {Boolean(data) ? (
              <>
                <Box
                  display="flex"
                  flexWrap="warp"
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    flexDirection: "column",
                  }}
                >
                  {data.cart.items.map((item, index) => {
                    return (
                      <Box
                        py={4}
                        px={2}
                        display="flex"
                        position="relative"
                        style={{
                          border: "1px solid black",
                          minWidth: isSmPhone
                            ? `calc(${document.body.clientWidth}px)`
                            : `calc(${document.body.clientWidth}px/4)`,
                        }}
                      >
                        {/* 商品圖片 */}
                        <Box>
                          <img
                            src={item.product.images[0].location}
                            width="80px"
                            height="80px"
                            style={{
                              objectFit: "contain",
                              backgroundColor: "#fff",
                              padding: "10px",
                            }}
                          />
                        </Box>
                        {/* 商品內容 */}
                        <Box
                          ml={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.floatCartText}>
                            {" "}
                            {item.product.name}{" "}
                          </Typography>
                          <Typography className={classes.floatCartText}>
                            {" "}
                            Nt.{item.product.price}{" "}
                          </Typography>
                          <CountBoxSm amount={item.amount} />
                        </Box>
                        {/* 刪除、數量 */}
                        {/* <Box className={classes.floatCartDelButton}>
                          <button
                            className={classes.floatCartDelText}
                            size="small"
                            // onClick={() => deleteProduct(item.itemName)}
                          >
                            <Typography variant="caption">
                              <CloseIcon style={{ maxWidth: "25px" }} />
                            </Typography>
                          </button>
                        </Box> */}
                      </Box>
                    );
                  })}
                </Box>
                <Grid
                  container
                  justifyContent="center"
                  style={{ paddingTop: "10px" }}
                >
                  <Grid item xs={10}>
                    <Button
                      variant="contained"
                      fullWidth
                      className={classes.goPay}
                      onClick={() => {
                        cleartimer();
                        history.push("/cart-page");
                      }}
                    >
                      <Typography variant="h6" className={classes.goPayText}>
                        前往結帳
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}

//PC黑色浮層購物車的數量設定
function CountBoxSm({ amount }) {
  const useStyles = makeStyles({
    amount: {
      display: "flex",
      alignItems: "center",
    },
    amountMiner: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "20px",
      height: "20px",
      borderRight: "none",
      cursor: "pointer",
      color: "#fff",
    },
    amountPlus: {
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid grey",
      width: "20px",
      height: "20px",
      borderLeft: "none",
      cursor: "pointer",
      color: "#fff",
    },
    amountInput: {
      height: "20px",
      textAlign: "center",
      width: "50px",
      border: "0.8px solid gray",
      color: "#fff",
      "&:focus": {
        outline: "none",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.amount}>
        <Typography variant="body2" style={{ color: "#fff" }}>
          數量
        </Typography>
        &ensp;&ensp;
        {/* <button className={classes.amountMiner} onClick={() => count(false)}>
          {" "}
          -{" "}
        </button> */}
        {/* 數量 */}
        <input
          type="text"
          className={classes.amountInput}
          disabled
          value={amount}
        />
        {/* <button className={classes.amountPlus} onClick={() => count(true)}>
          {" "}
          +{" "}
        </button> */}
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import TabView from "../components/TabView";
import ConsoleProductTable from "./component/ConsoleProductTable";
import ConsoleProductForm from "./component/ConsoleProductForm";

const GET_CATEGORIES = gql`
  query categories($enabled: Boolean) {
    categories(enabled: $enabled) {
      id
      name
      subCategories {
        id
        name
      }
    }
  }
`;

export default function ProductListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["列表", "新增"],
    select: 0,
    dataObject: null,
    subCategories: [],
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { enabled: true },
    onCompleted({ categories }) {
      if (categories) {
        const newSubCategories = [];
        categories.forEach((item) => {
          item.subCategories.forEach((item2) => {
            newSubCategories.push({ value: item2.id, label: item2.name });
          });
        });
        changeData("subCategories", newSubCategories, true);
      }
    },
    onError() {
      return null;
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid>
        <TabView
          paper
          tabs={newData.tabViewData}
          index={newData.select}
          onChange={(e) => {
            if (newData.select === 2 && e !== 2) {
              Alert.alert("", "確定離開編輯？", [
                {
                  text: "確定",
                  onClick: () => {
                    changeData("dataObject", null);
                    newData.tabViewData.splice(2, 1);
                    changeData("tabViewData", newData.tabViewData);
                    changeData("select", e, true);
                  },
                },
                { text: "取消", type: "outlined" },
              ]);
            } else {
              changeData("dataObject", undefined);
              changeData("select", e, true);
            }
          }}
        >
          <ConsoleProductTable
            subCategories={newData.subCategories}
            onChangeSelect={(item, copy) => {
              if (copy) {
                changeData("dataObject", item);
                changeData("tabViewData", newData.tabViewData);
                changeData("select", 1, true);
              } else {
                changeData("dataObject", item);
                newData.tabViewData.push("編輯");
                changeData("tabViewData", newData.tabViewData);
                changeData("select", 2, true);
              }
            }}
          />
          <ConsoleProductForm
            id={newData.dataObject?.id}
            copy
            subCategories={newData.subCategories}
            onChangeSelect={() => {
              changeData("dataObject", undefined);
              changeData("tabViewData", ["列表", "新增"]);
              changeData("select", 0, true);
            }}
          />
          <ConsoleProductForm
            id={newData.dataObject && newData.dataObject.id}
            subCategories={newData.subCategories}
            onChangeSelect={() => {
              changeData("dataObject", undefined);
              changeData("tabViewData", ["列表", "新增"]);
              changeData("select", 0, true);
            }}
          />
        </TabView>
      </Grid>
    );
  }
}

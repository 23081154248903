import { useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";

/**
 * @typedef FieldCacheProps
 * @property {import('react-hook-form').Control} control
 * @property {string} name
 * @property {(callback: string) => void} render
 */
/**
 * @param {FieldCacheProps} param0
 */
export function FieldCache({ control, name, render = () => {} }) {
  const valueRef = useRef("");
  const value = useWatch({ control, name });
  valueRef.current = value;
  return render(valueRef.current);
}
/**
 * @param {Pick<FieldCacheProps, 'render' | 'name'>} param0
 */
export function FieldCacheContext({ name, render = () => {} }) {
  const valueRef = useRef("");
  const { control } = useFormContext();
  const value = useWatch({ control, name });
  valueRef.current = value;
  return render(valueRef.current);
}

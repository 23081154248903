import divisions from "./divisions.json";

/**
 * @typedef {'enUS' | 'zhTW'} languageType
 */
/**
 * @typedef {Array<{label: string, value: string}>} dataType
 */
/**
 * @param {languageType} [language="zhTW"] - 語言
 * @returns {dataType}
 */
export function countiesList(language = "zhTW") {
  const data = [];
  for (const key in divisions) {
    data.push({
      label: divisions[key][language],
      value: key,
    });
  }
  return data;
}
// NOTE 取得縣市名稱
/**
 * @param {keyof divisions} params - 縣市value
 * @param {languageType} [language="zhTW"] - 語言
 * @returns {string}
 */
export function getCounty(params, language = "zhTW") {
  try {
    return divisions[params][language];
  } catch (error) {
    return "";
  }
}
/**
 *
 * @param {keyof divisions} params - 縣市參數
 * @param {languageType} [language="zhTW"] - 語言
 * @returns {dataType}
 */
export function districtsList(params, language = "zhTW") {
  if (params) {
    try {
      const data = [];
      const object = divisions[params].subdivisions;
      for (const key in object) {
        data.push({
          label: object[key][language],
          value: key,
        });
      }
      return data;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
}
// NOTE 取得地區名稱
/**
 * @param {keyof divisions} params - 縣市value
 * @param {string} params2 - 地區value
 * @param {languageType} [language="zhTW"] - 語言
 * @returns {string}
 */
export function getDistrict(params, params2, language = "zhTW") {
  try {
    return divisions[params].subdivisions[params2][language];
  } catch (error) {
    return "";
  }
}

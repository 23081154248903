import React, { useEffect, Fragment, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../../components/Alert";
import Button from "../../components/Button";
import Grid from "../../components/Grid";
import { TextInput, Select } from "../../components/Form";
import LoadingFloating from "../../components/LoadingFloating";
import { checkImageSize } from "../../components/utils";

const GET_CATEGORY = gql`
  query category($id: Int!) {
    category(id: $id) {
      id
      name
      enName
      image
      categoryId
      enable
      content {
        id
        title
        subTitle
        label1
        label2
        label3
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const SAVE_CATEGORY = gql`
  mutation saveCategory($id: Int, $categoryInput: CategoryInput!) {
    saveCategory(id: $id, categoryInput: $categoryInput) {
      success
      message
    }
  }
`;

const enabledSelect = [
  {
    label: "是",
    value: true,
  },
  {
    label: "否",
    value: false,
  },
];

export default function CategoryAddFloatingLayer({
  categoryId,
  id,
  open,
  onFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    dialogPaper: {
      maxHeight: `calc(100% - 16px)`,
      maxWidth: `calc(75% - 16px)`,
      width: `calc(100% - 16px)`,
      margin: 8,
    },
  });
  const classes = useStyles();
  const [newData, setNewData] = useState({
    name: "",
    enName: "",
    image: "",
    contentId: undefined,
    title: "",
    subTitle: "",
    label1: "",
    label2: "",
    label3: "",
    enabledObject: {
      label: "是",
      value: true,
    },
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  function initialvalue(value) {
    changeData("name", "");
    changeData("enName", "");
    changeData("image", "");
    changeData("contentId", undefined);
    changeData("enabledObject", {
      label: "是",
      value: true,
    });
    changeData("title", "");
    changeData("subTitle", "");
    changeData("label1", "");
    changeData("label2", "");
    changeData("label3", "", true);
    onFloatingwindowClose(value);
  }

  const [getCategory, { loading }] = useLazyQuery(GET_CATEGORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ category }) {
      if (category) {
        if (category.enName) {
          changeData("enName", category.enName);
        }
        if (category.image) {
          changeData("image", category.image);
        }
        const hasEnabled = enabledSelect.find(
          (item) => item.value === category.enable
        );
        if (hasEnabled) {
          changeData("enabledObject", hasEnabled);
        }
        if (categoryId && category.content) {
          if (category.content.id) {
            changeData("contentId", category.content.id);
          }
          if (category.content.title) {
            changeData("title", category.content.title);
          }
          if (category.content.subTitle) {
            changeData("subTitle", category.content.subTitle);
          }
          if (category.content.label1) {
            changeData("label1", category.content.label1);
          }
          if (category.content.label2) {
            changeData("label2", category.content.label2);
          }
          if (category.content.label3) {
            changeData("label3", category.content.label3);
          }
        }
        changeData("name", category.name, true);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getCategory({ variables: { id: Number(id) } });
    }
  }, [id]);

  function _saveSubject() {
    const categoryInput = {
      name: newData.name,
      enName: newData.enName,
      image: newData.image,
      content: {
        title: "",
        subTitle: "",
        label1: "",
        label2: "",
        label3: "",
      },
      enable: newData.enabledObject.value,
    };

    if (!Boolean(newData.name)) {
      return Alert.notify("名稱未填寫！");
    }

    if (!Boolean(newData.enName)) {
      return Alert.notify("英文名稱未填寫！");
    }

    if (!Boolean(newData.image)) {
      return Alert.notify("圖片未選擇！");
    }

    if (categoryId) {
      if (newData.contentId) {
        categoryInput.content.id = newData.contentId;
      }

      categoryInput.categoryId = categoryId;

      if (Boolean(newData.title)) {
        categoryInput.content.title = newData.title;
      }

      if (Boolean(newData.subTitle)) {
        categoryInput.content.subTitle = newData.subTitle;
      }

      if (Boolean(newData.label1)) {
        categoryInput.content.label1 = newData.label1;
      }

      if (Boolean(newData.label2)) {
        categoryInput.content.label2 = newData.label2;
      }

      if (Boolean(newData.label3)) {
        categoryInput.content.label3 = newData.label3;
      }
    }

    if (Boolean(id)) {
      saveCategory({
        variables: { id: Number(id), categoryInput },
      });
    } else {
      saveCategory({
        variables: { categoryInput },
      });
    }
  }

  const [saveCategory, { loading: saveCategoryLoading }] = useMutation(
    SAVE_CATEGORY,
    {
      onCompleted({ saveCategory }) {
        if (saveCategory.success) {
          return Alert.notify(
            `${categoryId ? "子分類" : "主分類"}儲存成功！`,
            initialvalue("refetch")
          );
        } else if (saveCategory.message) {
          Alert.notify(saveCategory.message);
        }
      },
    }
  );

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted({ uploadImage }) {
        delete uploadImage.__typename;

        changeData("image", uploadImage.location, true);
      },
      onError() {
        return null;
      },
    }
  );

  return (
    <Dialog
      open={open}
      onClose={initialvalue}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialogPaper }}
    >
      <LoadingFloating loading={saveCategoryLoading || uploadImageLoading} />
      <DialogTitle align="center">
        {Boolean(id) ? "編輯" : "建立"}
        {categoryId ? "子分類" : "主分類"}
      </DialogTitle>
      {loading ? (
        <Grid container item justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Fragment>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="primary">圖片</Typography>
                <ButtonBase
                  style={{ width: "100%", height: 300 }}
                  component="label"
                  htmlFor={`uploadImage`}
                >
                  {Boolean(newData.image) ? (
                    <img
                      src={newData.image}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <CloudUploadIcon />
                  )}
                </ButtonBase>
                <input
                  id={`uploadImage`}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    const {
                      target: { validity, files },
                    } = event;

                    if (validity.valid) {
                      for (let file of files) {
                        if (checkImageSize(file.size))
                          return Alert.notify("圖片限制 4.5 MB 以內");

                        uploadImage({ variables: { image: file } });
                      }
                    }
                  }}
                  style={{
                    display: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography color="primary">名稱</Typography>
                <TextInput
                  value={newData.name}
                  onChange={(e) => changeData("name", e, true)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography color="primary">英文名稱</Typography>
                <TextInput
                  value={newData.enName}
                  onChange={(e) => changeData("enName", e, true)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography color="primary">上架</Typography>
                <Select
                  items={enabledSelect}
                  value={newData.enabledObject}
                  onChange={(e, value) => {
                    if (value) {
                      changeData("enabledObject", value, true);
                    } else {
                      changeData(
                        "enabledObject",
                        {
                          label: "是",
                          value: true,
                        },
                        true
                      );
                    }
                  }}
                  fullWidth
                />
              </Grid>
              {categoryId && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Typography color="primary">內容標題</Typography>
                    <TextInput
                      value={newData.title}
                      onChange={(e) => changeData("title", e, true)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="primary">內容子標題</Typography>
                    <TextInput
                      value={newData.subTitle}
                      onChange={(e) => changeData("subTitle", e, true)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="primary">內容標籤1</Typography>
                    <TextInput
                      value={newData.label1}
                      onChange={(e) => changeData("label1", e, true)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="primary">內容標籤2</Typography>
                    <TextInput
                      value={newData.label2}
                      onChange={(e) => changeData("label2", e, true)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color="primary">內容標籤3</Typography>
                    <TextInput
                      value={newData.label3}
                      onChange={(e) => changeData("label3", e, true)}
                      fullWidth
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={_saveSubject}
                >
                  {Boolean(id) ? "儲存" : "建立"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={initialvalue}
                >
                  取消
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

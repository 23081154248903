import React from "react";
import { Button as MuiButton, colors, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

export default function Button({
  color = "default",
  variant = "text",
  disabled = false,
  onPress = () => {},
  children,
  ...props
}) {
  const statusColor = [
    {
      status: "error",
      light: colors["red"]["300"],
      main: colors["red"]["500"],
      dark: colors["red"]["700"],
      opacity: `rgba(244, 67, 54, 0.04)`,
      contrastText: "#fff",
    },
    {
      status: "warning",
      light: colors["orange"]["300"],
      main: colors["orange"]["500"],
      dark: colors["orange"]["700"],
      opacity: `rgba(255, 152, 0, 0.04)`,
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    {
      status: "info",
      light: colors["blue"]["300"],
      main: colors["blue"]["500"],
      dark: colors["blue"]["700"],
      opacity: `rgba(33, 150, 243, 0.04)`,
      contrastText: "#fff",
    },
    {
      status: "success",
      light: colors["green"]["300"],
      main: colors["green"]["500"],
      dark: colors["green"]["700"],
      opacity: `rgba(76, 175, 80, 0.04)`,
      contrastText: "#fff",
    },
  ];
  function getColor() {
    return statusColor.find((item) => item.status === color) || statusColor[0];
  }
  const useStyles = makeStyles({
    button: {
      "&.MuiButton-contained": {
        backgroundColor: getColor().main,
        color: getColor().contrastText,
        "&:hover": {
          backgroundColor: getColor().dark,
        },
      },
      "&.MuiButton-outlined": {
        backgroundColor: "transparent",
        borderColor: getColor().main,
        color: getColor().main,
        "&:hover": {
          borderColor: getColor().dark,
        },
      },
      "&.MuiButton-text": {
        backgroundColor: "transparent",
        color: getColor().main,
        "&:hover": {
          backgroundColor: getColor().opacity,
        },
      },
    },
  });
  const classes = useStyles();
  if (
    color === "default" ||
    color === "inherit" ||
    color === "primary" ||
    color === "secondary"
  ) {
    return (
      <MuiButton
        {...props}
        color={color}
        variant={variant}
        onClick={onPress}
        disabled={disabled}
      >
        {children}
      </MuiButton>
    );
  } else {
    return (
      <MuiButton
        {...props}
        onClick={onPress}
        color={color}
        variant={variant}
        disabled={disabled}
        className={classes.button}
      >
        {children}
      </MuiButton>
    );
  }
}
Button.prototype = {
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "error",
    "warning",
    "info",
    "success",
  ]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  disabled: PropTypes.bool,
  onPress:PropTypes.func
};

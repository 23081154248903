import React, { useState } from "react";
import { Box, makeStyles, Container, Grid } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { PasswordInput, TextInput } from "../components/Form";
import Button from "../components/Button";
import { CardContent } from "../components/Card";
import LoadingFloating from "../components/LoadingFloating";
import { useHistory } from "react-router-dom";
import Text from "../components/Text";
import { useAlert } from "../components/Alert";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      message
      user {
        id
        admin
        token
      }
    }
  }
`;

const administrators = [1, 2, 3];
export default function LoginPage() {
  const Alert = useAlert();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const useStyles = makeStyles((theme) => ({
    title: {
      color: theme.palette.primary.main,
    },
    imageBackgroung: {
      height: "100%",
      width: "100%",
      position: "absolute",
      zIndex: -1,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if (
        login.success &&
        (login.user.admin || administrators.includes(login.user.id))
      ) {
        localStorage.setItem("@twenty-seven-webConsoleToken", login.user.token);
        history.replace("/console/product");
        return Alert.notify("登入成功");
      } else if (login.message) {
        return Alert.notify(login.message);
      }
    },
  });

  function _login() {
    login({ variables: { username, password } });
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100vh"
      width="100%"
      position="relative"
    >
      <LoadingFloating loading={loading} />
      <Container className={classes.container} maxWidth="xs">
        <Grid container justify="center">
          <Grid container item>
            <CardContent>
              <Grid container spacing={2}>
                <Grid container item direction="column">
                  <Text type="h2" className={classes.title}>
                    貳柒香水
                  </Text>
                  <Text type="h4" className={classes.title}>
                    管理後臺
                  </Text>
                </Grid>
                <Grid container item>
                  <TextInput
                    value={username}
                    onChange={(e) => setUsername(e)}
                    label="帳號"
                    fullWidth
                  />
                </Grid>
                <Grid container item>
                  <PasswordInput
                    value={password}
                    onChange={(e) => setPassword(e)}
                    label="密碼"
                    placeholder="長度至少8碼"
                    fullWidth
                  />
                </Grid>
                <Grid container item>
                  <Button
                    disabled={false}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onPress={_login}
                  >
                    登入
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

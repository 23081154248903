import { createSlice } from "@reduxjs/toolkit";

// ANCHOR 預設值
const initialState = {
  // "選擇新增使用者點數開關"
  chooseAddUserPointSwitch: false,
};
// ANCHOR 主要組件
export const consoleSlice = createSlice({
  name: "console",
  initialState,
  reducers: {
    openChooseAddUserPointSwitch: (state) => {
      state.chooseAddUserPointSwitch = true;
    },
    closeChooseAddUserPointSwitch: (state) => {
      state.chooseAddUserPointSwitch = false;
    },
  },
});

export const { openChooseAddUserPointSwitch, closeChooseAddUserPointSwitch } =
  consoleSlice.actions;

export const consoleSliceState = (state) => state.console;

export default consoleSlice.reducer;

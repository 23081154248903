// SECTION 伺服器資料
// NOTE 選擇付款方式
/**- 選擇付款方式*/
export const selectPaymentMethodEnum = {
  Credit: "信用卡",
  ATM: "ATM",
  ISCOLLECTION: "貨到付款",
};
// NOTE 選擇付款方式列表
/**- 選擇付款方式列表*/
export const selectPaymentMethodList = [
  { label: "信用卡", value: "Credit" },
  { label: "ATM", value: "ATM" },
  { label: "貨到付款", value: "ISCOLLECTION" },
];

// NOTE 付款方式
/**- 付款方式*/
export const paymentMethodEnum = {
  CREDIT_CARD: "信用卡",
  ATM: "ATM",
  OTHER: "其他",
};
// NOTE 付款方式列表
/**- 付款方式列表*/
export const paymentMethodList = [
  { label: "信用卡", value: "CREDIT_CARD" },
  { label: "ATM", value: "ATM" },
  { label: "其他", value: "OTHER" },
];

// NOTE 運送方式
/**- 運送方式*/
export const shipmentMethodEnum = {
  CONVENIENCE_STORE: "超商取貨",
  HOME_DELIVERY: "宅配",
};
// NOTE 運送方式列表
/**- 運送方式列表*/
export const shipmentMethoddList = [
  { label: "超商取貨", value: "CONVENIENCE_STORE" },
  { label: "宅配", value: "HOME_DELIVERY" },
];

// NOTE 運送子方式
/**- 運送子方式*/
export const shipmentSubTypeEnum = {
  SEVEN_ELEVEN: "7-11",
  FAMI_MART: "全家",
};
// NOTE 運送子方式列表
/**- 運送子方式列表*/
export const shipmentSubTypedList = [
  { label: "7-11", value: "SEVEN_ELEVEN" },
  { label: "全家", value: "FAMI_MART" },
];

// NOTE 訂單狀態
/**- 訂單狀態*/
export const orderStatusEnum = {
  WAITING_FOR_PAYMENT: "等待付款",
  READY_FOR_SHIPMENT: "待出貨",
  SHIPPED: "已出貨",
  CANCELLED: "已取消",
  ARRIVED: "已到貨",
};
// NOTE 訂單狀態列表
/**- 訂單狀態列表*/
export const orderStatusList = [
  { label: "等待付款", value: "WAITING_FOR_PAYMENT" },
  { label: "待出貨", value: "READY_FOR_SHIPMENT" },
  { label: "已出貨", value: "SHIPPED" },
  { label: "已取消", value: "CANCELLED" },
  { label: "已到貨", value: "ARRIVED" },
];
// !SECTION

import React from "react";
import { Modal, CircularProgress, makeStyles } from "@material-ui/core";

export default function LoadingFloating({ loading }) {
  const useStyles = makeStyles({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      zIndex: "100"
    },
  });
  const classes = useStyles();
  return (
    <Modal open={loading} className={classes.modal}>
      <CircularProgress color="inherit" />
    </Modal>
  );
}

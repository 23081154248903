import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { useDispatch, useSelector, batch } from "react-redux";

import { useAlert } from "../../components/Alert";
import Table from "../../components/Table";
import Text from "../../components/Text";
import { Card, CardActions, CardContent } from "../../components/Card";
import Button from "../../components/Button";
import { Select, TextInput, NumberInput } from "../../components/Form";
import LoadingFloating from "../../components/LoadingFloating";
import {
  setConsolePriorityId,
  openConsolePriority,
  closeConsolePriorityRefetch,
} from "../../redux/consolePriority";

import PriorityFloatingLayer from "./PriorityFloatingLayer";

const GET_PRODUCTS = gql`
  query products(
    $searchTerm: String
    $categoryId: Int
    $priority: Boolean
    $pageSize: Int
    $page: Int
  ) {
    products(
      searchTerm: $searchTerm
      categoryId: $categoryId
      priority: $priority
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        name
        brand
        capacity
        price
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_SHIPMENTFEERULE = gql`
  query shipmentFeeRule {
    shipmentFeeRule {
      id
      convenienceStore
      homeDelivery
    }
  }
`;

const GET_FREESHIPPINGRULE = gql`
  query freeShippingRule {
    freeShippingRule {
      id
      price
    }
  }
`;

const SAVE_SHIPMENTFEERULE = gql`
  mutation saveShipmentFeeRule($convenienceStore: Int!, $homeDelivery: Int!) {
    saveShipmentFeeRule(
      convenienceStore: $convenienceStore
      homeDelivery: $homeDelivery
    ) {
      success
      message
    }
  }
`;

const SAVE_FREESHIPPINGRULE = gql`
  mutation saveFreeShippingRule($price: Int!) {
    saveFreeShippingRule(price: $price) {
      success
      message
    }
  }
`;

const HIDE_PRODUCT = gql`
  mutation hideProduct($id: Int!) {
    hideProduct(id: $id) {
      success
      message
    }
  }
`;

export default function ConsoleProductTable({
  subCategories,
  onChangeSelect = () => {},
}) {
  const dispatch = useDispatch();
  const consolePriorityRefetch = useSelector(
    (state) => state.consolePriority.consolePriorityRefetch
  );
  const [open, setOpen] = useState(false);
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    searchTerm: Boolean(
      localStorage.getItem("@twenty-seven-webConsole-ProductSearchTerm")
    )
      ? localStorage.getItem("@twenty-seven-webConsole-ProductSearchTerm")
      : "",
    pageSizeObject: Boolean(
      localStorage.getItem("@twenty-seven-webConsole-ProductPageSizeObject")
    )
      ? JSON.parse(
          localStorage.getItem("@twenty-seven-webConsole-ProductPageSizeObject")
        )
      : {
          label: "10",
          value: 10,
        },
    categoryIdObject: Boolean(
      localStorage.getItem("@twenty-seven-webConsole-ProductCategoryIdObject")
    )
      ? JSON.parse(
          localStorage.getItem(
            "@twenty-seven-webConsole-ProductCategoryIdObject"
          )
        )
      : undefined,
    page: Boolean(localStorage.getItem("@twenty-seven-webConsole-ProductPage"))
      ? Number(localStorage.getItem("@twenty-seven-webConsole-ProductPage"))
      : 1,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }
  function thousandsSeparator(number) {
    return number.toLocaleString("en-US");
  }

  const { data, refetch, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      searchTerm: newData.searchTerm,
      categoryId: newData.categoryIdObject
        ? newData.categoryIdObject.value
        : undefined,
      priority: true,
      pageSize: newData.pageSizeObject ? newData.pageSizeObject.value : 10,
      page: newData.page,
    },
    onCompleted() {
      dispatch(closeConsolePriorityRefetch());
    },
    onError() {
      return null;
    },
  });
  const {
    data: shipmentFeeRuleData,
    refetch: shipmentFeeRuleRefetch,
    loading: shipmentFeeRuleLoading,
  } = useQuery(GET_SHIPMENTFEERULE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });
  const {
    data: freeShippingRuleData,
    refetch: freeShippingRuleRefetch,
    loading: freeShippingRuleLoading,
  } = useQuery(GET_FREESHIPPINGRULE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError() {
      return null;
    },
  });

  const [hideProduct, { loading: hideProductLoading }] = useMutation(
    HIDE_PRODUCT,
    {
      onCompleted({ hideProduct }) {
        if (hideProduct.success) {
          refetch();
          return Alert.notify("刪除成功");
        } else if (hideProduct.message) {
          return Alert.notify(hideProduct.message);
        }
      },
    }
  );

  useEffect(() => {
    if (consolePriorityRefetch) {
      refetch();
    }
  }, [consolePriorityRefetch]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <LoadingFloating
          loading={
            hideProductLoading ||
            shipmentFeeRuleLoading ||
            freeShippingRuleLoading
          }
        />
        <PriorityFloatingLayer product />
        <CardContent>
          <Typography>商品數量：{data.products.count}</Typography>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <TextInput
                value={newData.searchTerm}
                onChange={(e) => {
                  localStorage.removeItem(
                    "@twenty-seven-webConsole-ProductPage"
                  );
                  localStorage.setItem(
                    "@twenty-seven-webConsole-ProductSearchTerm",
                    e
                  );
                  changeData("searchTerm", e);
                  changeData("page", 1, true);
                }}
                label="關鍵字搜尋"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"子系列"}
                value={newData.categoryIdObject}
                items={subCategories}
                onChange={(e, value) => {
                  localStorage.removeItem(
                    "@twenty-seven-webConsole-ProductPage"
                  );
                  localStorage.setItem(
                    "@twenty-seven-webConsole-ProductCategoryIdObject",
                    JSON.stringify(value)
                  );
                  changeData("categoryIdObject", value);
                  changeData("page", 1, true);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Select
                label={"每頁顯示"}
                value={newData.pageSizeObject}
                items={[
                  {
                    label: "10",
                    value: 10,
                  },
                  {
                    label: "20",
                    value: 20,
                  },
                  {
                    label: "30",
                    value: 30,
                  },
                  {
                    label: "40",
                    value: 40,
                  },
                  {
                    label: "50",
                    value: 50,
                  },
                ]}
                onChange={(e, value) => {
                  localStorage.removeItem(
                    "@twenty-seven-webConsole-ProductPage"
                  );
                  changeData("page", 1);
                  if (value) {
                    localStorage.setItem(
                      "@twenty-seven-webConsole-ProductPageSizeObject",
                      JSON.stringify(value)
                    );
                    changeData("pageSizeObject", value, true);
                  } else {
                    localStorage.setItem(
                      "@twenty-seven-webConsole-ProductPageSizeObject",
                      JSON.stringify({
                        label: "10",
                        value: 10,
                      })
                    );
                    changeData(
                      "pageSizeObject",
                      {
                        label: "10",
                        value: 10,
                      },
                      true
                    );
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <NumberInput
                value={shipmentFeeRuleData?.shipmentFeeRule?.convenienceStore}
                label="超商運費"
                fullWidth
                disabled
                thousandSeparator
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <NumberInput
                value={shipmentFeeRuleData?.shipmentFeeRule?.homeDelivery}
                label="宅配運費"
                fullWidth
                disabled
                thousandSeparator
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <NumberInput
                value={freeShippingRuleData?.freeShippingRule?.price}
                label="免運金額"
                fullWidth
                disabled
                thousandSeparator
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                onPress={() => {
                  setOpen(true);
                }}
                fullWidth
              >
                設定運費
              </Button>
              <ShipmentFeeRule
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                onRefetch={() => {
                  shipmentFeeRuleRefetch();
                  freeShippingRuleRefetch();
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Table
            data={data.products.contents}
            tableWidth={2}
            header={[
              "名稱",
              "品牌",
              "容量",
              "單價",
              "建立時間",
              "最後更新時間",
              "操作",
            ]}
            columns={[
              "name",
              "brand",
              "capacity",
              (item) => <Text>NT${thousandsSeparator(item.price)}</Text>,
              (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
              (item) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={(event) => {
                          event.stopPropagation();
                          batch(() => {
                            dispatch(setConsolePriorityId(item.id));
                            dispatch(openConsolePriority());
                          });
                        }}
                      >
                        順序
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={(event) => {
                          event.stopPropagation();
                          Alert.alert("", "確定要複製嗎？", [
                            {
                              text: "確認",
                              onClick: () => {
                                onChangeSelect(item, true);
                              },
                            },
                            { text: "取消", type: "outlined" },
                          ]);
                        }}
                      >
                        複製
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={(event) => {
                          event.stopPropagation();
                          Alert.alert("", "確定要刪除嗎？", [
                            {
                              text: "確認",
                              onClick: () =>
                                hideProduct({
                                  variables: { id: Number(item.id) },
                                }),
                            },
                            { text: "取消", type: "outlined" },
                          ]);
                        }}
                      >
                        刪除
                      </Button>
                    </Grid>
                  </Grid>
                );
              },
            ]}
            onPress={(item) => onChangeSelect(item)}
          />
        </CardContent>
        <CardActions>
          <Grid container justifyContent="center">
            <Pagination
              count={data.products.pageCount}
              size="large"
              color="primary"
              page={newData.page}
              onChange={(e, n) => {
                localStorage.setItem(
                  "@twenty-seven-webConsole-ProductPage",
                  String(n)
                );
                changeData("page", n, true);
              }}
            />
          </Grid>
        </CardActions>
      </Card>
    );
  } else {
    return null;
  }
}

function ShipmentFeeRule({ open, onClose = () => {}, onRefetch = () => {} }) {
  const [convenienceStore, setConvenienceStore] = useState();
  const [homeDelivery, setHomeDelivery] = useState();
  const [freeShippingRule, setFreeShippingRule] = useState();
  const Alert = useAlert();
  const [saveShipmentFeeRule, { loading: saveShipmentFeeRuleLoading }] =
    useMutation(SAVE_SHIPMENTFEERULE, {
      onCompleted({ saveShipmentFeeRule }) {
        if (saveShipmentFeeRule.success) {
          Alert.notify("儲存成功", () => {
            onRefetch();
            onClose();
          });
        } else if (saveShipmentFeeRule.message) {
          Alert.notify(saveShipmentFeeRule.message);
        }
      },
    });
  const [saveFreeShippingRule, { loading: saveFreeShippingRuleLoading }] =
    useMutation(SAVE_FREESHIPPINGRULE, {
      onCompleted({ saveFreeShippingRule }) {
        if (saveFreeShippingRule.success) {
        } else if (saveFreeShippingRule.message) {
          Alert.notify(saveFreeShippingRule.message);
        }
      },
    });
  function _save() {
    if (!Boolean(convenienceStore)) {
      return Alert.notify("超商運費未填寫！");
    }

    if (!Boolean(homeDelivery)) {
      return Alert.notify("宅配運費未填寫");
    }

    if (!Boolean(freeShippingRule)) {
      return Alert.notify("免運金額未填寫");
    }
    saveShipmentFeeRule({
      variables: {
        convenienceStore: Number(convenienceStore),
        homeDelivery: Number(homeDelivery),
      },
    });
    saveFreeShippingRule({ variables: { price: Number(freeShippingRule) } });
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <LoadingFloating
        loading={saveShipmentFeeRuleLoading || saveFreeShippingRuleLoading}
      />
      <DialogTitle align="center">設定運費</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <NumberInput
              label="超商運費"
              value={convenienceStore ? convenienceStore : null}
              onChange={(v) => {
                setConvenienceStore(v);
              }}
              fullWidth
              thousandSeparator
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <NumberInput
              label="宅配運費"
              value={homeDelivery ? homeDelivery : null}
              onChange={(v) => {
                setHomeDelivery(v);
              }}
              fullWidth
              thousandSeparator
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <NumberInput
              label="免運金額"
              value={freeShippingRule ? freeShippingRule : null}
              onChange={(v) => {
                setFreeShippingRule(v);
              }}
              fullWidth
              thousandSeparator
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={() => {
                _save();
              }}
            >
              儲存
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onPress={() => {
                onClose();
              }}
            >
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
